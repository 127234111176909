const Ssd = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>SSD</title>
    <g fill="none">
      <path fill="#D00019" d="M32 0H0v32h32V0Z"/>
      <path fill="#fff" d="M11 10.23c0 .42-.18.7-.54.7-.43 0-.65-.4-.62-1.29H8.78v2.3h1.06v-.44c.35.35.65.5 1.09.5.92 0 1.6-.8 1.6-1.9 0-.76-.28-1.35-.98-2.06-.4-.4-.72-.63-.97-.88a1.07 1.07 0 0 1-.36-.78c0-.32.19-.52.47-.52.4 0 .62.3.6 1.14h1.06V4.86H11.3v.45c-.32-.3-.55-.51-1.1-.51-.87 0-1.47.7-1.47 1.7 0 1.93 2.28 2.48 2.28 3.73Zm.33 9.38c1.41 0 2.32-1.37 2.32-3.5 0-.89-.17-1.65-.46-2.23a.43.43 0 0 0 .13-.1.45.45 0 0 0 .13-.33A.45.45 0 0 0 13 13a.46.46 0 0 0-.34.13v.02a1.89 1.89 0 0 0-1.32-.51c-.54 0-1 .2-1.38.57l-.05-.08a.46.46 0 0 0-.34-.13.45.45 0 0 0-.46.46c0 .14.04.25.13.34a.43.43 0 0 0 .23.11 5.04 5.04 0 0 0-.44 2.22c0 2.12.9 3.5 2.31 3.5Zm0-5.85c.43 0 .55.35.55 2.36 0 2-.11 2.35-.55 2.35-.43 0-.55-.35-.55-2.35s.12-2.36.55-2.36Zm3.1-4.51v1.64h-.59v1.05h2.83V10.9h-.56V9.24l1.44-3.33h.5V4.86h-2.3v1.06h.64l-.9 2.12-.87-2.12h.63V4.86h-2.71v1.06h.48l1.4 3.33Zm1.48 9.29c-.41 0-.63-.4-.6-1.24H14.3v2.2h1.01v-.42c.34.34.63.48 1.04.48.88 0 1.53-.77 1.53-1.82 0-.72-.26-1.3-.94-1.97-.38-.39-.68-.6-.92-.84a1.03 1.03 0 0 1-.34-.74c0-.31.17-.5.44-.5.38 0 .6.27.58 1.08h1.01v-2.04h-1v.43c-.32-.3-.54-.49-1.07-.49-.82 0-1.4.68-1.4 1.63 0 1.85 2.18 2.37 2.18 3.57 0 .4-.17.67-.5.67Zm1.4 2.77h.43v-.98h-3.61v.98h.41l-.7 4.6h-.43v.97h2.25v-.98h-.4l.14-1.22h1.08l.16 1.22h-.42v.98h2.24v-.98h-.4l-.75-4.59Zm-1.8 2.56.33-2.72h.18l.35 2.72h-.85Zm7.6-8.64v-2.5h-4.77v2.5h1.02v-1.48h.57v4.74h-.55v1.01h2.7v-1h-.54v-4.74h.57v1.48h1Zm-1.46 5.1v.98h.38v1.28c0 .6.04.89.07 1.66H22a27.09 27.09 0 0 0-1.31-3.92H18.8v.98h.41v4.6h-.4v.97h1.74v-.98h-.36v-1.28c0-.3-.03-1.62-.08-2.22h.08c.54 1.89 1.06 3.1 1.6 4.54H23V21.3h.35v-.98h-1.71Zm1.5-11.92c0-2.24-1.02-3.55-2.49-3.55h-2.45v1.06h.5v4.97h-.5v1.06h2.45c1.7 0 2.5-1.53 2.5-3.54ZM20.4 5.92c.57 0 .97.2.97 2.45 0 2.17-.3 2.52-.97 2.52V5.92Zm-8.01 17.65c.46-.35.65-.71.65-1.32 0-1.01-.67-1.92-1.96-1.92H8.63v.98h.4v4.6h-.4v.97H11v-.98h-.4V24h.51l.62 2.88h1.43v-.98h-.27l-.5-2.33Zm-1.79-.5v-1.8c.57 0 .88.09.88.86 0 .8-.27.94-.88.94Z"/>
    </g>
  </svg>
);

export default Ssd;
