import { getAllConfig } from './channel-util';
import { jallaDate } from '~/lib/utils/jalla-date';

const uuidRegExp = /^[\da-f]{8}(?:-[\da-f]{4}){3}-[\da-f]{12}$/i;
export const isValidUUID = (uuid) => {
  return uuidRegExp.test(uuid);
};

const escapedDomains = getAllConfig().map(([_id, { domain }]) => domain.replace(/\./, '\\.'));
const urlRegExp = new RegExp(`^https:\\/\\/www\\.(${escapedDomains.join('|')})(\\/.*)?$`);
export const isValidUrl = (url) => {
  return urlRegExp.test(url);
};

export const isValidImageUrl = (url, origin) => {
  const imageUrl = new RegExp(`^${origin.replace(/\./g, '\\.')}\\/ba\\/.*`);
  return imageUrl.test(url);
};

export const parseImageData = (data, origin) => {
  try {
    const imageData = JSON.parse(data);
    const id = imageData?.type === 'image' && imageData?.publicName;

    if (id) {
      return `${origin}/ba/${id}`;
    }
  } catch (e) {
  }
  return data;
};

export const DisplayDate = ({ utcString }) => {
  const pushDate = jallaDate(utcString);

  if (pushDate.isToday()) {
    return (
      <div>
        <p>Idag</p>
        <p>{pushDate.format('HH:mm')}</p>
      </div>
    );
  }
  if (pushDate >= jallaDate().subtractDays(1).startOfDay() && pushDate < jallaDate().startOfDay()) {
    return (
      <div>
        <p>Igår</p>
        <p>{pushDate.format('HH:mm')}</p>
      </div>
    );
  }

  return (
    <div>
      <p>{pushDate.format('D MMMM')}</p>
      <p>{pushDate.getFullYear()}</p>
      <p>{pushDate.format('HH:mm')}</p>
    </div>
  );
};
