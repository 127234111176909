[
  {
    "channel": "a2112bca-8509-45b8-adc7-65a58019edd6",
    "uuid": "9A1863E1-CDCD-453B-B0C9-8CE311A3DE9B",
    "channelTitle": "Mora tidning",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "a2112bca-8509-45b8-adc7-65a58019edd6",
    "uuid": "8A801D78-5DDB-482E-B133-0524B2EE3718",
    "channelTitle": "Mora tidning",
    "title": "Kultur & nöje"
  },
  {
    "channel": "a2112bca-8509-45b8-adc7-65a58019edd6",
    "uuid": "64690432-1E37-428B-A612-CF0D7C9162DD",
    "channelTitle": "Mora tidning",
    "title": "Näringsliv"
  },
  {
    "channel": "a2112bca-8509-45b8-adc7-65a58019edd6",
    "uuid": "28EEA73B-9D5F-434D-AE70-637E70E96FAF",
    "channelTitle": "Mora tidning",
    "title": "Opinion"
  },
  {
    "channel": "a2112bca-8509-45b8-adc7-65a58019edd6",
    "uuid": "18CFF59B-E241-4918-8E3E-DAB92F9BE721",
    "channelTitle": "Mora tidning",
    "title": "Trafik"
  },
  {
    "channel": "a2112bca-8509-45b8-adc7-65a58019edd6",
    "uuid": "9A806F2C-567A-4B52-A47D-1892DA75A181",
    "channelTitle": "Mora tidning",
    "title": "Sport"
  },
  {
    "channel": "a2112bca-8509-45b8-adc7-65a58019edd6",
    "uuid": "F6BE0380-32C0-47F2-AD68-9485AD07E54B",
    "channelTitle": "Mora tidning",
    "title": "Innebandy"
  },
  {
    "channel": "a2112bca-8509-45b8-adc7-65a58019edd6",
    "uuid": "B1076BE5-F1D9-462D-9ABE-A7E4007EEB43",
    "channelTitle": "Mora tidning",
    "title": "Motorsport"
  },
  {
    "channel": "a2112bca-8509-45b8-adc7-65a58019edd6",
    "uuid": "26F4344C-CAF9-42F0-9398-46E549BC2CD5",
    "channelTitle": "Mora tidning",
    "title": "Dalafyran"
  },
  {
    "channel": "a2112bca-8509-45b8-adc7-65a58019edd6",
    "uuid": "F94A7373-CCDC-49F4-B03F-1D119F64A0F8",
    "channelTitle": "Mora tidning",
    "title": "Leksands IF Herr"
  },
  {
    "channel": "a2112bca-8509-45b8-adc7-65a58019edd6",
    "uuid": "BC2E9D10-A4FA-43CB-9611-34B66A0A0AE9",
    "channelTitle": "Mora tidning",
    "title": "Leksands IF Dam"
  },
  {
    "channel": "a2112bca-8509-45b8-adc7-65a58019edd6",
    "uuid": "10B1BFCE-D1E1-46CB-93FF-4783D2401B97",
    "channelTitle": "Mora tidning",
    "title": "Mora IK Herr"
  },
  {
    "channel": "a2112bca-8509-45b8-adc7-65a58019edd6",
    "uuid": "36642073-933F-4A59-885E-E52CD6202F98",
    "channelTitle": "Mora tidning",
    "title": "Mora IK Dam"
  },
  {
    "channel": "a2112bca-8509-45b8-adc7-65a58019edd6",
    "uuid": "7FF3A1B1-C1C2-498E-9215-312C26893374",
    "channelTitle": "Mora tidning",
    "title": "Vasaloppet"
  },
  {
    "channel": "a2112bca-8509-45b8-adc7-65a58019edd6",
    "uuid": "F787B190-DD9A-11EC-BA7D-0800200C9A66",
    "channelTitle": "Mora tidning",
    "title": "Malung-Sälen Kommun"
  },
  {
    "channel": "a2112bca-8509-45b8-adc7-65a58019edd6",
    "uuid": "6A22802B-A122-435E-B55B-56C24C4587C2",
    "channelTitle": "Mora tidning",
    "title": "Mora kommun"
  },
  {
    "channel": "a2112bca-8509-45b8-adc7-65a58019edd6",
    "uuid": "0554F34E-821C-4E14-B9B4-57E73716EDC6",
    "channelTitle": "Mora tidning",
    "title": "Orsa kommun"
  },
  {
    "channel": "a2112bca-8509-45b8-adc7-65a58019edd6",
    "uuid": "8256B831-5866-4B4D-88CC-2497AD868C76",
    "channelTitle": "Mora tidning",
    "title": "Älvdalens kommun"
  },
  {
    "channel": "a2112bca-8509-45b8-adc7-65a58019edd6",
    "uuid": "6B2243E3-D7D9-47E7-AD25-C742538C4F41",
    "channelTitle": "Mora tidning",
    "title": "Dalarna"
  },
  {
    "channel": "a2112bca-8509-45b8-adc7-65a58019edd6",
    "uuid": "FF7A3FF6-ED59-425C-AFE5-FEEA0B71C525",
    "channelTitle": "Mora tidning",
    "title": "Kais Mora"
  },
  {
    "channel": "a2112bca-8509-45b8-adc7-65a58019edd6",
    "uuid": "8F44ED65-8FD6-4E73-AFA0-2D3CBAE64952",
    "channelTitle": "Mora tidning",
    "title": "IFK Mora"
  }
]
