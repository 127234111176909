const Th = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>TH</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#851224" />
      <path
        d="M7.309 10.125h5.203l-.532 2.895h2.246l-.473 2.837h-2.128s-.532 1.743-.207 2.245c.325.502.887.443 1.656.443h.709l1.39-8.36h4.995l-.502 3.042h2.837l.503-3.043h5.025l-1.773 11.73h-5.055l.768-4.728h-2.69l-.887 4.727H9.29c-2.04 0-2.927-2.009-3.045-2.186-.118-.177-.296-1.182-.207-2.01.089-.826.148-1.86.148-1.86H3.969l.473-2.807h2.335l.532-2.925"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Th;
