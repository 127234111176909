const Tba = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>TBA</title>
    <g fill="none">
      <path fill="#D00019" d="M32 0H0v32h32V0Z"/>
      <path fill="#fff" d="M5.8 9.14s.9-.05 2.25.1a4.9 4.9 0 0 0-.71.58 3.1 3.1 0 0 0-.87 1.4s.91-.64 1.9-1.08c.6-.28 1.17-.47 1.6-.6l.86.2c-2.48 1.2-2.85 3.06-2.85 3.06s1.82-1.77 4.65-2.48c.17.07.35.14.5.22l-1.15.96c1.6-.37 3.36-.23 3.86-.17-.8-.74-2.09-1.83-3.54-2.46-3.9-1.67-6.32.22-6.5.27Zm6.8-2.45c-.68-.22-1.54-.29-1.54-.29s1.44.98 2.8 2.44a11 11 0 0 1 2.36 1.93c0-.18 0-.34-.03-.47-.52-2.55-2.92-3.4-3.58-3.61Zm12.37 5.11c1.31.27 2.17.57 2.17.57-.16-.1-1.92-2.63-6.13-2.17-1.56.17-3.1.83-4.1 1.3.5.09 2.22.47 3.63 1.3l-.82-1.28.56-.05c2.48 1.5 3.71 3.74 3.71 3.74s.2-1.88-1.8-3.77c.3.01.6.04.87.06.38.25.86.62 1.35 1.06a15.37 15.37 0 0 1 1.5 1.58s.05-.76-.42-1.58c-.2-.34-.36-.58-.52-.76Zm-2.05-3.6s-.83-.18-1.56-.18c-.69 0-3.24.1-4.48 2.38a3.3 3.3 0 0 0-.18.44c.63-.36 1.66-.88 2.83-1.15a19.97 19.97 0 0 1 3.39-1.5Z"/>
      <path fill="#fff" d="M23.65 16.46a12.3 12.3 0 0 0-7.52-4.4c-3.55-.54-6.76.77-8.47 1.95a6.16 6.16 0 0 0-2.54 3.28s.78-.74 2.06-1.6A2.4 2.4 0 0 0 7 16.77c0 .43.25 1.14.25 1.14s.22-.9.53-1.62c.3-.7.79-1.35.88-1.47.23-.14.47-.26.73-.4a4.54 4.54 0 0 0-.34 3.18c.1.34.35.72.35.72s.12-2.4 1.75-4.64c.29-.1.58-.2.88-.28-1.74 3.19-.24 5.23-.24 5.23s.12-3.13 2.29-5.62l.42-.03-.22 1.5-.29.12-.23 2.06-.32.13-.2 2.26-.3.15-.07 2.22-.28.14.04 2.84h-3.3v.9H20.9v-.9h-3.76l.06-2.36-.24-.15.2-2.05-.23-.17.3-2.02-.2-.17.4-1.93-.2-.2.37-1.7.35.13c1.33 3.02.51 6.03.51 6.03s2.03-1.5 1.34-5.06c.27.17.52.35.76.53.87 2.62.28 4.94.28 4.94s.34-.3.54-.58a4.4 4.4 0 0 0 .63-3.1l.58.59c.07.2.31.92.38 1.64.09.78.03 1.71.03 1.71s.44-.6.58-1.01c.1-.36.17-.62.14-1.05.95 1.2 1.5 2.11 1.5 2.11s.13-1.83-1.46-3.87l-.1-.2Zm-9.27 1.34.76.66 1.06-.38-.2 1.32-.95.4-.78-.6.1-1.4Zm-.45 2.31 1.02.74 1.21-.52-.14 1.4-1.04.65-1.08-.85.03-1.42Zm2.4 3.98H13.8l-.03-1.7 1.28.98 1.33-.81-.04 1.53Zm-.23-6.96-.77.28-.55-.5.15-1.36.62.52.83-.2-.28 1.26Zm.23-2.24-.01.05-.54.13-.48-.41.19-1.34.5.46.62-.17-.28 1.28Z"/>
    </g>
  </svg>
);

export default Tba;
