[
  {
    "channel": "ce6ec877-b9c2-40b5-a6f3-02f5036bb7be",
    "uuid": "839268D4-FA54-4223-A7BA-F975718ACF71",
    "channelTitle": "Nynäshamns Posten",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "ce6ec877-b9c2-40b5-a6f3-02f5036bb7be",
    "uuid": "6E27CCF2-111D-4705-BFD8-4CEABBF5DF51",
    "channelTitle": "Nynäshamns Posten",
    "title": "Sport"
  },
  {
    "channel": "ce6ec877-b9c2-40b5-a6f3-02f5036bb7be",
    "uuid": "C978A3A2-05D4-4594-9D0A-847B4F3BDAA6",
    "channelTitle": "Nynäshamns Posten",
    "title": "Näringsliv"
  },
  {
    "channel": "ce6ec877-b9c2-40b5-a6f3-02f5036bb7be",
    "uuid": "ED2CBBE3-2CE6-4254-A5BC-6DAA114DEC3A",
    "channelTitle": "Nynäshamns Posten",
    "title": "Trafik"
  },
  {
    "channel": "ce6ec877-b9c2-40b5-a6f3-02f5036bb7be",
    "uuid": "076B1275-663E-470D-A115-EB8B4AF07360",
    "channelTitle": "Nynäshamns Posten",
    "title": "Kultur & nöje"
  },
  {
    "channel": "ce6ec877-b9c2-40b5-a6f3-02f5036bb7be",
    "uuid": "F6EE2413-E1F2-48D8-BF3D-60991C664193",
    "channelTitle": "Nynäshamns Posten",
    "title": "Opinion"
  },
  {
    "channel": "ce6ec877-b9c2-40b5-a6f3-02f5036bb7be",
    "uuid": "51740203-420C-47E3-AA6D-52B4129993AF",
    "channelTitle": "Nynäshamns Posten",
    "title": "Nynäshamns kommun"
  },
  {
    "channel": "ce6ec877-b9c2-40b5-a6f3-02f5036bb7be",
    "uuid": "0a5d976f-40ed-496c-9ff4-e68aa8b1446b",
    "channelTitle": "Nynäshamns Posten",
    "title": "Nynäshamn"
  },
  {
    "channel": "ce6ec877-b9c2-40b5-a6f3-02f5036bb7be",
    "uuid": "22df6d2e-23e8-42f5-9556-0a558f88d6a2",
    "channelTitle": "Nynäshamns Posten",
    "title": "Ösmo"
  },
  {
    "channel": "ce6ec877-b9c2-40b5-a6f3-02f5036bb7be",
    "uuid": "d93374c4-d26d-42c7-8473-6a1fa12eb6ea",
    "channelTitle": "Nynäshamns Posten",
    "title": "Sorunda"
  },
  {
    "channel": "ce6ec877-b9c2-40b5-a6f3-02f5036bb7be",
    "uuid": "8e8cb664-1e20-46bf-94a3-14eb8123e151",
    "channelTitle": "Nynäshamns Posten",
    "title": "Torö"
  },
  {
    "channel": "ce6ec877-b9c2-40b5-a6f3-02f5036bb7be",
    "uuid": "0f24fcb5-bb9c-4b7e-b929-c02fef55a5a8",
    "channelTitle": "Nynäshamns Posten",
    "title": "Muskö"
  }
]
