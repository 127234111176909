const Vlt = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>VLT</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#004090" />
      <path
        d="M23.479 10.88v2.413h2.993v.972h-2.993v5.8c0 1.304.397 1.694 1.769 1.694.218 0 .418 0 .596-.04l.537-.078.159-.02.179.76-.218.058-1.035.253c-.715.176-1.462.272-2.038.272-1.61 0-2.346-.74-2.346-2.335v-6.364h-1.49v-.661l.218-.078c1.63-.545 1.75-.623 2.385-1.615.279-.428.397-.623.577-1.032h.707ZM9.904 13.098v.544l-1.412.526 1.53 4.106c.14.39.38 1.149.498 1.576l2.167-5.565-1.293-.643V13.1h3.857v.544l-1.153.643-3.757 8.504H9.268l-3.38-8.62-1.232-.527V13.1h5.248Zm8.31-4.068v12.533l1.77.68v.545h-5.688v-.544l1.67-.681V10.79l-1.405-.436v-.546l3.654-.778Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Vlt;
