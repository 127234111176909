[
  {
    "channel": "98f288a4-b44f-401a-b038-9d3a8694869f",
    "uuid": "81FC43AE-B606-4234-8482-45B459086B02",
    "channelTitle": "ÖP",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "98f288a4-b44f-401a-b038-9d3a8694869f",
    "uuid": "B7066DD1-7D78-4EFE-9350-3EE011587686",
    "channelTitle": "ÖP",
    "title": "Kultur & nöje"
  },
  {
    "channel": "98f288a4-b44f-401a-b038-9d3a8694869f",
    "uuid": "42E791A5-13DF-4F29-A356-E725BA4F08A1",
    "channelTitle": "ÖP",
    "title": "Näringsliv"
  },
  {
    "channel": "98f288a4-b44f-401a-b038-9d3a8694869f",
    "uuid": "F8C3AED0-5FFE-432D-8049-BD7E50509D1A",
    "channelTitle": "ÖP",
    "title": "Opinion"
  },
  {
    "channel": "98f288a4-b44f-401a-b038-9d3a8694869f",
    "uuid": "511AF36F-E324-4CE2-8FE6-4DFD7D11B33E",
    "channelTitle": "ÖP",
    "title": "Sport"
  },
  {
    "channel": "98f288a4-b44f-401a-b038-9d3a8694869f",
    "uuid": "4649ECA2-28F4-4609-84F6-710B93DCEBDA",
    "channelTitle": "ÖP",
    "title": "ÖFK"
  },
  {
    "channel": "98f288a4-b44f-401a-b038-9d3a8694869f",
    "uuid": "5231D9A9-5760-4404-9D1E-17BF80518272",
    "channelTitle": "ÖP",
    "title": "Trafik"
  },
  {
    "channel": "98f288a4-b44f-401a-b038-9d3a8694869f",
    "uuid": "5177F651-EBC1-43CD-AF4C-5BB4F85FAB6F",
    "channelTitle": "ÖP",
    "title": "Bergs kommun"
  },
  {
    "channel": "98f288a4-b44f-401a-b038-9d3a8694869f",
    "uuid": "9AE91CF2-960C-4D9E-8E8D-78F23672041C",
    "channelTitle": "ÖP",
    "title": "Bräcke kommun"
  },
  {
    "channel": "98f288a4-b44f-401a-b038-9d3a8694869f",
    "uuid": "AB02C8DF-F4BE-43B7-B126-84580C978096",
    "channelTitle": "ÖP",
    "title": "Härjedalens kommun"
  },
  {
    "channel": "98f288a4-b44f-401a-b038-9d3a8694869f",
    "uuid": "CE08CF42-4808-4E55-80B7-06F94CD5CC44",
    "channelTitle": "ÖP",
    "title": "Krokoms kommun"
  },
  {
    "channel": "98f288a4-b44f-401a-b038-9d3a8694869f",
    "uuid": "7C04D056-0BC8-4DE1-8B83-CD777DA1FFEF",
    "channelTitle": "ÖP",
    "title": "Ragunda kommun"
  },
  {
    "channel": "98f288a4-b44f-401a-b038-9d3a8694869f",
    "uuid": "6D8D7FF7-EF71-4C78-919C-0DA981DAC62D",
    "channelTitle": "ÖP",
    "title": "Strömsunds kommun"
  },
  {
    "channel": "98f288a4-b44f-401a-b038-9d3a8694869f",
    "uuid": "1A554D54-8420-4117-8331-34D0977A2D24",
    "channelTitle": "ÖP",
    "title": "Åre kommun"
  },
  {
    "channel": "98f288a4-b44f-401a-b038-9d3a8694869f",
    "uuid": "1AF61638-51C1-4205-9142-691DF9F5E83E",
    "channelTitle": "ÖP",
    "title": "Östersunds kommun"
  }
]
