const Vn = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>VN</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#FFF" />
      <path
        d="M26.456 21.146v2.59H5.75v-2.59h20.706ZM19.316 8.22l5.714 6.174V9.189l-2.208-.597v-.373h4.522v.354l-1.549.504v10.165H24.78l-6.836-8.132v7.013l2.403.596v.374h-4.716v-.354l1.548-.504V9.207l-1.548-.615v-.373h3.684Zm-9.573 0v.354l-.944.168 2.19 7.126h.125l2.35-6.79-1.656-.485v-.373h3.596v.354l-1.032.391-3.703 10.129H9.155L5.49 8.76l-.802-.168v-.373h5.056Z"
        fill="#E52621"
      />
    </g>
  </svg>
);

export default Vn;
