[
  {
    "channel": "17e7c5f3-cf7c-4f89-a7ad-89154d8ea060",
    "uuid": "024CC419-89DC-4DBA-B919-3CA3AC08AA83",
    "channelTitle": "Tidningen Härjedalen",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "17e7c5f3-cf7c-4f89-a7ad-89154d8ea060",
    "uuid": "8816DAD2-7749-4F20-AE6B-1F315819C48D",
    "channelTitle": "Tidningen Härjedalen",
    "title": "Kultur & nöje"
  },
  {
    "channel": "17e7c5f3-cf7c-4f89-a7ad-89154d8ea060",
    "uuid": "0BF0B47C-23AB-49F4-99DB-6D674C493E6C",
    "channelTitle": "Tidningen Härjedalen",
    "title": "Näringsliv"
  },
  {
    "channel": "17e7c5f3-cf7c-4f89-a7ad-89154d8ea060",
    "uuid": "F13F0779-A7D4-4C2B-9D70-A72A047ED035",
    "channelTitle": "Tidningen Härjedalen",
    "title": "Opinion"
  },
  {
    "channel": "17e7c5f3-cf7c-4f89-a7ad-89154d8ea060",
    "uuid": "B97AC902-0C43-45DF-9C61-823D64559BA9",
    "channelTitle": "Tidningen Härjedalen",
    "title": "Sport"
  },
  {
    "channel": "17e7c5f3-cf7c-4f89-a7ad-89154d8ea060",
    "uuid": "09399E93-D7B2-44AC-B9CD-5C73D8E33A79",
    "channelTitle": "Tidningen Härjedalen",
    "title": "Trafik"
  },
  {
    "channel": "17e7c5f3-cf7c-4f89-a7ad-89154d8ea060",
    "uuid": "6B4CF625-F296-4828-94D8-3690E44B754B",
    "channelTitle": "Tidningen Härjedalen",
    "title": "Härjedalens kommun"
  }
]
