[
  {
    "channel": "f8424ca0-c794-4dab-9943-8fb646d5c649",
    "uuid": "08723088-2E06-46CE-AC6F-219EA30C899A",
    "channelTitle": "Trelleborgsallehanda",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "f8424ca0-c794-4dab-9943-8fb646d5c649",
    "uuid": "0FB233C4-5C62-473B-8E19-A75A54FA64FC",
    "channelTitle": "Trelleborgsallehanda",
    "title": "Sport"
  }
]
