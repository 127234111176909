[
  {
    "channel": "d6b3dadc-45c2-4a6e-9fb4-e805a609ebe6",
    "uuid": "BE04F46F-9120-4EA9-BAE9-5DD28FF04D31",
    "channelTitle": "Jnytt",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "d6b3dadc-45c2-4a6e-9fb4-e805a609ebe6",
    "uuid": "4C7D4290-647E-408E-8D3D-F40298CA50FB",
    "channelTitle": "Jnytt",
    "title": "Kultur & nöje"
  },
  {
    "channel": "d6b3dadc-45c2-4a6e-9fb4-e805a609ebe6",
    "uuid": "0E7805B4-E2AD-4331-9D95-977DFE0D72E7",
    "channelTitle": "Jnytt",
    "title": "Näringsliv"
  },
  {
    "channel": "d6b3dadc-45c2-4a6e-9fb4-e805a609ebe6",
    "uuid": "FF357917-5D8B-4934-9E04-8AEFBEA17276",
    "channelTitle": "Jnytt",
    "title": "Opinion"
  },
  {
    "channel": "d6b3dadc-45c2-4a6e-9fb4-e805a609ebe6",
    "uuid": "F4EEC64D-B9AC-46B9-BA6C-CD36E4683692",
    "channelTitle": "Jnytt",
    "title": "Sport"
  },
  {
    "channel": "d6b3dadc-45c2-4a6e-9fb4-e805a609ebe6",
    "uuid": "EDFEC715-184C-4140-AECF-8B90495C3556",
    "channelTitle": "Jnytt",
    "title": "HV71"
  },
  {
    "channel": "d6b3dadc-45c2-4a6e-9fb4-e805a609ebe6",
    "uuid": "240627BE-BA46-4804-B9B3-AB68C285CECA",
    "channelTitle": "Jnytt",
    "title": "J-södra"
  },
  {
    "channel": "d6b3dadc-45c2-4a6e-9fb4-e805a609ebe6",
    "uuid": "70CA6276-A4C0-496A-8332-2DB20F52C27A",
    "channelTitle": "Jnytt",
    "title": "Trafik"
  },
  {
    "channel": "d6b3dadc-45c2-4a6e-9fb4-e805a609ebe6",
    "uuid": "DF018161-8588-41A2-8CC1-85605866DFBE",
    "channelTitle": "Jnytt",
    "title": "Jönköpings kommun"
  },
  {
    "channel": "d6b3dadc-45c2-4a6e-9fb4-e805a609ebe6",
    "uuid": "602245C9-9450-4B37-A940-E1C68004DC74",
    "channelTitle": "Jnytt",
    "title": "Huskvarna"
  },
  {
    "channel": "d6b3dadc-45c2-4a6e-9fb4-e805a609ebe6",
    "uuid": "054405EE-058F-4706-91D9-BD81A27B6EAA",
    "channelTitle": "Jnytt",
    "title": "Habo Kommun"
  },
  {
    "channel": "d6b3dadc-45c2-4a6e-9fb4-e805a609ebe6",
    "uuid": "F2B80A29-3DBE-4AED-8E76-3514468DAFF4",
    "channelTitle": "Jnytt",
    "title": "Mullsjö Kommun"
  },
  {
    "channel": "d6b3dadc-45c2-4a6e-9fb4-e805a609ebe6",
    "uuid": "2161C6F0-68EB-43AE-8978-21786B93672C",
    "channelTitle": "Jnytt",
    "title": "Vaggeryds Kommun"
  },
  {
    "channel": "d6b3dadc-45c2-4a6e-9fb4-e805a609ebe6",
    "uuid": "BA7921CD-48A4-44F2-88FE-4285780B5539",
    "channelTitle": "Jnytt",
    "title": "Tabergsdalen"
  }
]
