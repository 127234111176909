const Vk = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>VK</title>
    <g fill="none">
      <path fill="#006290" d="M32 0H0v32h32V0Z"/>
      <path fill="#fff" d="M5.32 11.06c-.2-.54-.47-1.25-1.12-1.25h-.36v-.53h4.48v.53H7.7c-.31 0-.7.11-.7.52 0 .27.31 1.3.52 1.87l2.86 7.87 3.2-8.73c.1-.26.2-.73.2-1.01 0-.32-.26-.51-.77-.51h-.68v-.53h3.56v.53h-.36c-.58 0-1.1 1.4-1.36 2.05L10.26 22.6h-.68L5.32 11.05Zm11.27 10.71h.68c.63 0 .78-.3.78-1.34v-8.64c0-1.8-.01-1.99-1-1.99h-.44v-.52h4.48v.53h-.6c-.82 0-.84.38-.84 1.73v3.56h.22l4-3.72c.26-.24.94-.88.94-1.24 0-.27-.32-.34-.63-.34h-.42v-.52h3.59v.53H27c-.43 0-.92.43-1.84 1.3l-3.92 3.65 2.83 3.22c.58.67 2.1 2.42 2.36 2.76.46.53.92 1.03 1.46 1.03h.3v.53h-4.64v-.54h.27c.38 0 .84-.15.84-.47 0-.16-.38-.67-.72-1.04l-4.05-4.6h-.25v4.52c0 1.32.05 1.6.9 1.6h.54v.53H16.6v-.53h-.02Z"/>
    </g>
  </svg>
);

export default Vk;
