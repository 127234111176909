const Fk = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>FK</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#2376AF" />
      <path
        d="m20.377 10.406-1.353 4.985.031.031 4.277-5.016h4.635l-5.241 5.622 2.317 5.56h-4.121l-1.945-5.948h-.03l-1.587 5.948h-3.857l2.389-8.853h-5.64l-.653 2.377h3.24l-.576 2.065H9.039l-1.182 4.41H4l3.033-11.18h13.344Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Fk;
