const Blt = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>BLT</title>
    <g fill="none">
      <path fill="#005CA9" d="M32 0H0v32h32V0Z"/>
      <path fill="#fff" d="M14.42 20.47h6.76V17.4h-3.39v-6.2h-3.37v9.27Z"/>
      <path fill="#fff" d="M19.7 14.26h2.25v6.22h3.37v-6.22h2.25v-3.05H19.7v3.05ZM4.48 20.48v-9.27h5.27c1.8 0 3.63.43 3.63 2.57 0 .81-.4 1.61-1.22 1.87v.07c1.01.36 1.65 1.1 1.65 2.2 0 1.9-1.77 2.56-3.4 2.56H4.49Zm3.28-3.75v1.54h1.59c.55 0 1.08-.01 1.08-.78s-.6-.76-1.15-.76H7.76Zm0-1.95H9c.5 0 1.05-.05 1.05-.7 0-.68-.63-.63-1.12-.63H7.76v1.33Z"/>
    </g>
  </svg>
);

export default Blt;
