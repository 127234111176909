const Sklt = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>SKLT</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#F7DD15" />
      <path
        d="M13.604 8.185v7.001h.035s.156-.78.25-1.13l.679-2.574 2.721.007-1.476 4.584c-.15.406-.304.882-.38 1.12l-.035.113-.013.04.11.33.074.22.083.25.089.268.044.136 1.775 5.231-2.758.06-1.044-3.64a6.409 6.409 0 0 1-.13-.681h-.024v4.261h-2.596V8.185h2.596Zm7.145-.029V21.3h1.91v2.481h-4.535V8.156h2.625Zm6.814.029v2.669h-1.424v12.928h-2.767V10.854h-1.526v-2.67h5.716ZM8.273 8.17c1.618 0 1.656 1.696 1.656 1.696l.066 3.842H7.649v-2.845s.051-.482-.398-.482c-.465 0-.417.482-.417.482v2.654c0 .199.093.384.247.493l2.392 1.828c.633.497.633 1.22.633 1.22v5.005c-.005.114-.113 1.717-1.894 1.717H6.317c-1.827 0-1.855-1.948-1.855-1.948v-4.155h2.382v3.744l.01.045c.026.094.109.306.327.306.274 0 .297-.35.297-.35v-3.789c0-.15-.07-.289-.187-.369l-2.334-1.822c-.52-.415-.52-1.138-.52-1.138l.001-4.37c.009-.236.124-1.764 1.617-1.764Z"
        fill="#000"
      />
    </g>
  </svg>
);

export default Sklt;
