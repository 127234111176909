[
  {
    "channel": "5f827d27-768b-49b8-9b00-5614af6d60da",
    "uuid": "B263B995-DAB5-45F3-A1F2-3456792C3B8F",
    "channelTitle": "LTZ",
    "title": "Nyheter"
  },
  {
    "channel": "5f827d27-768b-49b8-9b00-5614af6d60da",
    "uuid": "488485DC-2C58-4FE4-B7CD-2A227E82DED4",
    "channelTitle": "LTZ",
    "title": "Kultur & nöje"
  },
  {
    "channel": "5f827d27-768b-49b8-9b00-5614af6d60da",
    "uuid": "B33A9BA5-4B17-47D3-92C5-60FCFD69A5B9",
    "channelTitle": "LTZ",
    "title": "Opinion"
  }
]
