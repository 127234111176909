[
  {
    "uuid": "97096F9B-414E-4735-AB14-0F585ED54F5B",
    "channel": "86bbf49d-c5c3-43d5-9874-aa2d91300f20",
    "channelTitle": "Skånskan",
    "title": "Stora nyheter"
  },
  {
    "uuid": "9B35C074-2A09-4D60-8462-4D750D784317",
    "channel": "86bbf49d-c5c3-43d5-9874-aa2d91300f20",
    "channelTitle": "Skånskan",
    "title": "Sport"
  },
  {
    "uuid": "2D8C916C-0A2C-405F-818F-979B983560AD",
    "channel": "86bbf49d-c5c3-43d5-9874-aa2d91300f20",
    "channelTitle": "Skånskan",
    "title": "Kultur & Nöje"
  },
  {
    "uuid": "4468D9A3-136F-40B2-9341-C8929C147612",
    "channel": "86bbf49d-c5c3-43d5-9874-aa2d91300f20",
    "channelTitle": "Skånskan",
    "title": "Eslöv"
  },
  {
    "uuid": "19B02951-E978-48CF-A2D0-9CAF890FEE26",
    "channel": "86bbf49d-c5c3-43d5-9874-aa2d91300f20",
    "channelTitle": "Skånskan",
    "title": "Höör"
  },
  {
    "uuid": "3DE9A72C-468D-4FA1-9EAA-4C0CCEF0013C",
    "channel": "86bbf49d-c5c3-43d5-9874-aa2d91300f20",
    "channelTitle": "Skånskan",
    "title": "Hörby"
  },
  {
    "uuid": "6D30781B-B0F3-4B3F-9001-4FCBE7B9937C",
    "channel": "86bbf49d-c5c3-43d5-9874-aa2d91300f20",
    "channelTitle": "Skånskan",
    "title": "Malmö"
  },
  {
    "uuid": "C105381D-207B-4FDA-B2C4-3FF527C09A44",
    "channel": "86bbf49d-c5c3-43d5-9874-aa2d91300f20",
    "channelTitle": "Skånskan",
    "title": "Lund"
  },
  {
    "uuid": "DE9F7BF5-8271-40E0-B5E2-76B3107FDA50",
    "channel": "86bbf49d-c5c3-43d5-9874-aa2d91300f20",
    "channelTitle": "Skånskan",
    "title": "Sjöbo"
  },
  {
    "uuid": "0A1BCF7A-EAB1-4B09-BC6F-B6DE52DDA047",
    "channel": "86bbf49d-c5c3-43d5-9874-aa2d91300f20",
    "channelTitle": "Skånskan",
    "title": "Skurup"
  },
  {
    "uuid": "2F9C3CBC-C6CE-41EC-A0B3-286038552F41",
    "channel": "86bbf49d-c5c3-43d5-9874-aa2d91300f20",
    "channelTitle": "Skånskan",
    "title": "Kävlinge"
  },
  {
    "uuid": "776832D2-B831-48B4-866A-D77B92470139",
    "channel": "86bbf49d-c5c3-43d5-9874-aa2d91300f20",
    "channelTitle": "Skånskan",
    "title": "Svalöv"
  },
  {
    "uuid": "62553CD3-A3C8-48ED-828C-9958A400CE3C",
    "channel": "86bbf49d-c5c3-43d5-9874-aa2d91300f20",
    "channelTitle": "Skånskan",
    "title": "Söderslätt"
  },
  {
    "uuid": "50198136-3D93-48A0-9784-9749361EB104",
    "channel": "86bbf49d-c5c3-43d5-9874-aa2d91300f20",
    "channelTitle": "Skånskan",
    "title": "Svedala"
  }
]
