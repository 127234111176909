const Sdt = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>SDT</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#000" />
      <path
        d="M28.499 13.194c1.55 6.903-2.79 13.755-9.693 15.305-6.903 1.55-13.755-2.79-15.305-9.693-1.55-6.903 2.79-13.755 9.694-15.305 6.902-1.55 13.754 2.79 15.304 9.693Zm-15.16-9.051a12.07 12.07 0 0 0-7.605 5.355 12.075 12.075 0 0 0-1.591 9.164 12.073 12.073 0 0 0 5.355 7.605 12.074 12.074 0 0 0 9.165 1.59 12.076 12.076 0 0 0 7.605-5.354 12.077 12.077 0 0 0 1.59-9.164c-1.468-6.538-7.98-10.66-14.52-9.196ZM16 4.336c5.336 0 10.164 3.691 11.38 9.11.681 3.04.14 6.163-1.528 8.795a11.584 11.584 0 0 1-7.298 5.139 11.581 11.581 0 0 1-8.795-1.527 11.579 11.579 0 0 1-5.139-7.299A11.585 11.585 0 0 1 6.147 9.76a11.588 11.588 0 0 1 7.298-5.14 11.634 11.634 0 0 1 2.556-.284ZM10.89 10.98c-.107-.632-1.014-.5-1.014-.5l-2.315.534c-.98.226-.805 1.075-.805 1.075l1.195 5.177s.172 1.32 1.784.949c.203-.047.902-.31 1.071.184l.808 3.522s.052.205.051.247c-.005.46-.78.901-1.276.237-.016-.022-.043-.109-.06-.134l-.319-1.425-1.592.358.166.214s.408 1.794.418 1.83c.322 1.248 1.38.992 1.38.992l2.182-.512s1.094-.253.836-1.39l-1.277-5.533c-.258-.882-1.083-.673-1.083-.673l-1.416.327c-.416.104-.49-.431-.49-.431l-.707-3.06s-.134-.523.54-.679c.674-.156.773.334.773.334l.255 1.105 1.452-.335Zm2.564-1.248-1.954.45.316 1.37c.495-.086.527.369.527.369l2.17 9.397s.149.45-.3.625l.284 1.283 1.969-.448c1.125-.26 2.711-.457 2.843-1.446a1.974 1.974 0 0 0-.058-.474l-2.516-10.785a2.283 2.283 0 0 0-.147-.309c-.592-.714-1.321-.45-3.134-.032Zm9.385-2.156-5.394 1.245.556 3.253.861-.2c-.453-1.964.364-2.14.63-2.147h.05l.03.003 2.074 8.985s.322 1.131-.297 1.454l.318 1.38 2.655-.613-.318-1.38c-.587-.003-.82-1.168-.82-1.168L21.098 9.35s1.072-.663 1.607 1.652l.872-.202-.738-3.224Zm-7.295 3.877c.162.702 2.135 8.944 2.138 9.259.004.581-.915.694-1.258.773l-.248.037L13.78 11.15l.21-.048c.353-.082 1.365-.463 1.553.352Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Sdt;
