[
  {
    "channel": "2b5532fd-dad7-48e0-a4a8-be673aaafc78",
    "uuid": "BA2A711D-4240-4BE8-A910-BB3B9D531A63",
    "channelTitle": "JP",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "2b5532fd-dad7-48e0-a4a8-be673aaafc78",
    "uuid": "E262094C-707D-47CE-8A63-0851BE1BE681",
    "channelTitle": "JP",
    "title": "Kultur & nöje"
  },
  {
    "channel": "2b5532fd-dad7-48e0-a4a8-be673aaafc78",
    "uuid": "CD4FF4E7-268E-4BAD-A11C-34F307F2E526",
    "channelTitle": "JP",
    "title": "Näringsliv"
  },
  {
    "channel": "2b5532fd-dad7-48e0-a4a8-be673aaafc78",
    "uuid": "E12B5830-7ABE-478F-8D3F-B51BF64FD885",
    "channelTitle": "JP",
    "title": "Opinion"
  },
  {
    "channel": "2b5532fd-dad7-48e0-a4a8-be673aaafc78",
    "uuid": "E93C4CD2-F503-440C-BB7E-25FD8600C776",
    "channelTitle": "JP",
    "title": "Sport"
  },
  {
    "channel": "2b5532fd-dad7-48e0-a4a8-be673aaafc78",
    "uuid": "D3A40505-A6F8-4003-A050-3870D6197B41",
    "channelTitle": "JP",
    "title": "HV71"
  },
  {
    "channel": "2b5532fd-dad7-48e0-a4a8-be673aaafc78",
    "uuid": "DDE28D41-CC40-4633-A401-00CCFBD46EAC",
    "channelTitle": "JP",
    "title": "J-södra"
  },
  {
    "channel": "2b5532fd-dad7-48e0-a4a8-be673aaafc78",
    "uuid": "45BEFE5F-93A3-42B8-9817-DBA0F37E96F4",
    "channelTitle": "JP",
    "title": "Hallby"
  },
  {
    "channel": "2b5532fd-dad7-48e0-a4a8-be673aaafc78",
    "uuid": "258B844A-3B0F-49B6-8B28-DE03EB4483DE",
    "channelTitle": "JP",
    "title": "Trafik"
  },
  {
    "channel": "2b5532fd-dad7-48e0-a4a8-be673aaafc78",
    "uuid": "9C3869B4-24BF-4908-A904-BB4802F19E7E",
    "channelTitle": "JP",
    "title": "Habo kommun"
  },
  {
    "channel": "2b5532fd-dad7-48e0-a4a8-be673aaafc78",
    "uuid": "058F89A8-CFB8-484B-BC39-0E5C1668693B",
    "channelTitle": "JP",
    "title": "Jönköpings kommun"
  },
  {
    "channel": "2b5532fd-dad7-48e0-a4a8-be673aaafc78",
    "uuid": "D52461A8-CE29-449B-9352-673F1EEA0E66",
    "channelTitle": "JP",
    "title": "Mullsjö kommun"
  },
  {
    "channel": "2b5532fd-dad7-48e0-a4a8-be673aaafc78",
    "uuid": "E979276D-29B2-4D2F-B3DA-89920D380016",
    "channelTitle": "JP",
    "title": "Vaggeryds kommun"
  },
  {
    "channel": "2b5532fd-dad7-48e0-a4a8-be673aaafc78",
    "uuid": "5C8B46B1-2CFE-45BE-8659-1084CABFA5FB",
    "channelTitle": "JP",
    "title": "Huskvarna"
  },
  {
    "channel": "2b5532fd-dad7-48e0-a4a8-be673aaafc78",
    "uuid": "A8956065-7AF6-4356-B408-871CA78F354E",
    "channelTitle": "JP",
    "title": "Tabergsdalen"
  }
]
