const Allehanda = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>Allehanda</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#003B62" />
      <path
        d="M10.528 9.96c4.111 0 5.74 2.807 5.74 6.464 0 .258-.01.513-.028.765l-.019.223c-.29 3.11-2.008 5.65-5.731 5.65-3.62 0-5.326-2.377-5.672-5.39a10.57 10.57 0 0 1-.068-1.209c0-3.658 1.706-6.502 5.778-6.502Zm12.74.233 1.611 5.057 2.402 7.541h-3.974l-.427-1.587h-3.665l-.465 1.587h-3.238l4.207-12.598h3.549ZM10.49 12.805c-1.28 0-1.746.465-1.746 2.961v1.258c0 .28.006.537.017.775l.012.226c.125 1.975.677 2.386 1.755 2.386 1.137 0 1.655-.488 1.734-2.77.008-.21.012-.433.012-.675v-1.277c0-2.477-.505-2.884-1.784-2.884Zm10.626.774-.408 2.245-.873 3.058h2.463l-.727-2.732-.087-.326-.368-2.245Zm-7.858-6.485c.797 0 1.498.662 1.498 1.514s-.701 1.514-1.498 1.514c-.778 0-1.517-.662-1.517-1.514s.74-1.514 1.517-1.514Zm-5.37 0c.796 0 1.498.662 1.498 1.514s-.702 1.514-1.498 1.514c-.778 0-1.517-.662-1.517-1.514s.739-1.514 1.517-1.514Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Allehanda;
