[
  {
    "channel": "c73749ce-6bce-44d8-8128-eb981fbc544e",
    "uuid": "304FC956-91AB-444B-A143-D08A33B62841",
    "channelTitle": "Smålandsposten",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "c73749ce-6bce-44d8-8128-eb981fbc544e",
    "uuid": "92F7154C-676D-4DF2-AC08-E53BE7F9D169",
    "channelTitle": "Smålandsposten",
    "title": "Sport"
  }
]
