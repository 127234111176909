[
  {
    "channel": "ca972392-b1ac-4f6e-9230-a6c2c1c6729b",
    "uuid": "610FCBD1-60C0-420D-A850-35A335EDC753",
    "channelTitle": "Ljusnan",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "ca972392-b1ac-4f6e-9230-a6c2c1c6729b",
    "uuid": "1595B064-EA0E-4297-AF2A-76BC3D7C5878",
    "channelTitle": "Ljusnan",
    "title": "Näringsliv"
  },
  {
    "channel": "ca972392-b1ac-4f6e-9230-a6c2c1c6729b",
    "uuid": "272B29D7-9011-4599-B243-D9876552035B",
    "channelTitle": "Ljusnan",
    "title": "Opinion"
  },
  {
    "channel": "ca972392-b1ac-4f6e-9230-a6c2c1c6729b",
    "uuid": "66995C52-B230-4F47-B90D-849722E76306",
    "channelTitle": "Ljusnan",
    "title": "Sport"
  },
  {
    "channel": "ca972392-b1ac-4f6e-9230-a6c2c1c6729b",
    "uuid": "FA2FF78B-8FEB-4688-816E-82CE878D077E",
    "channelTitle": "Ljusnan",
    "title": "Trafik"
  },
  {
    "channel": "ca972392-b1ac-4f6e-9230-a6c2c1c6729b",
    "uuid": "17CAC91B-CFE4-4A2E-9C5C-04BCA22D2BA8",
    "channelTitle": "Ljusnan",
    "title": "Bollnäs kommun"
  },
  {
    "channel": "ca972392-b1ac-4f6e-9230-a6c2c1c6729b",
    "uuid": "0ABBAF87-C3C8-4414-95F3-47145D846646",
    "channelTitle": "Ljusnan",
    "title": "Ovanåkers kommun"
  },
  {
    "channel": "ca972392-b1ac-4f6e-9230-a6c2c1c6729b",
    "uuid": "B8E3397E-F04A-4E64-827F-317C5F753D0F",
    "channelTitle": "Ljusnan",
    "title": "Bollnäs GIF bandy"
  },
  {
    "channel": "ca972392-b1ac-4f6e-9230-a6c2c1c6729b",
    "uuid": "A17EA231-2D82-4713-A76F-A6C238240338",
    "channelTitle": "Ljusnan",
    "title": "Edsbyns IF bandy"
  }
]
