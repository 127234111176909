const Boras = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>BORAS</title>
    <g fill="none">
      <path fill="#41AD49" d="M32 0H0v32h32V0Z"/>
      <path fill="#fff" d="m13.8 14.37 2.08-1.05-.07-.12c-.1.05-.28.1-.4.1-1.05 0-.89-2.74-3.14-2.74-1.02 0-1.73.8-2.2 1.83-.41-1.05-1.34-1.83-2.85-1.83a3.41 3.41 0 0 0-3.38 3.48c0 1.3.63 2.32 2 2.32.5 0 .78-.17 1.06-.42l-.1-.1c-.2.13-.56.28-.96.28-.9 0-1.5-.78-1.5-1.7 0-1.05.86-1.95 1.94-1.95 1.56 0 2.22 1.35 2.22 2.8 0 2.01-1.72 3.6-3.23 4.62l.09.09c.44-.25.83-.5 1.31-.5.96 0 2.28 1.94 4.57 1.94 2.42 0 4.65-2.04 4.65-4.36 0-1.31-1.19-2.69-2.08-2.69Zm-1 6.25C11 20.62 9.96 18 7.96 18c1.42-1.1 2.34-3.18 2.34-4.2 0-1.6.35-2.12 1.05-2.12 1.18 0 1.09 2.78 1.94 2.78l-2.38 1.19.07.13c.3-.13.6-.2.91-.2 1.66 0 2.53 1.5 2.53 3.03 0 1.56-.61 2.01-1.62 2.01Zm15.1-8.49a1.3 1.3 0 0 1-1.2.73c-1.3 0-2.79-2.3-5.65-2.3-2.07 0-4.46 1.22-4.46 3.56 0 1.32.99 2.34 2.32 2.34.3 0 .5-.05.7-.16l-.04-.1a1.5 1.5 0 0 1-.48.06c-1.1 0-1.83-.84-1.83-1.82 0-1.48 1.53-2.09 2.78-2.09 2.3 0 4 2.2 6.07 2.2.53 0 1.38-1.06 1.94-2.38l-.15-.04Z"/>
      <path fill="#fff" d="M22.17 13.99c0-.22.08-.38.28-.54l-.06-.06c-.77.54-1.3 1.1-1.3 1.9 0 1.87 2.86 2.38 2.86 3.36a.9.9 0 0 1-.32.59l.03.06c.67-.28 1.44-1.04 1.44-1.65 0-1.56-2.93-1.91-2.93-3.66Z"/>
      <path fill="#fff" d="M27.47 19.4a2.2 2.2 0 0 1-1.94 1c-1.8 0-3.24-2.05-4.25-2.05-.52 0-1.73 1.08-2.32 1.78l.11.1c.32-.36.61-.42.92-.42.74 0 2.26 1.73 4.1 1.73 1.43 0 2.8-.7 3.5-2.05l-.12-.09Z"/>
    </g>
  </svg>

);

export default Boras;
