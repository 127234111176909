const Bt = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>BT</title>
    <g fill="none" fillRule="evenodd">
      <path
        d="M31.608 12.496c1.935 8.62-3.484 17.177-12.104 19.112-8.62 1.935-17.177-3.484-19.112-12.104C-1.543 10.884 3.876 2.327 12.497.392c8.62-1.935 17.176 3.484 19.111 12.104ZM12.676 1.193A15.073 15.073 0 0 0 3.18 7.88a15.079 15.079 0 0 0-1.987 11.444 15.076 15.076 0 0 0 6.688 9.496 15.078 15.078 0 0 0 11.444 1.987 15.08 15.08 0 0 0 9.497-6.687 15.081 15.081 0 0 0 1.986-11.445C28.975 4.512 20.843-.635 12.676 1.193Zm3.326.241c6.662 0 12.691 4.61 14.21 11.376a14.468 14.468 0 0 1-1.907 10.984 14.466 14.466 0 0 1-9.115 6.417 14.462 14.462 0 0 1-10.982-1.907A14.46 14.46 0 0 1 1.79 19.19 14.467 14.467 0 0 1 3.696 8.208 14.47 14.47 0 0 1 12.81 1.79c1.07-.24 2.139-.356 3.192-.356Z"
        fill="#007CBF"
      />
      <path
        d="M26.523 22.423c-.733.17-1.333-.196-1.496-.902-.175-.758.205-1.348.984-1.527.733-.17 1.332.194 1.496.902.174.755-.204 1.347-.984 1.527M24.93 7.405l-.49-2.13-1.644.379v-.006l-7.35 1.697-.484.111-1.642.38.491 2.128 2.083-.48 2.114-.487 3.366 14.58.006-.001v.001l2.708-.623L20.733 8.37l4.197-.965M9.72 18.035c-.53.123-.973.275-.973.275l1.345 5.825s.203.025 1.094-.18c.7-.163 1.136-.437 1.406-.649.503-.416.958-1.293.553-3.044-.514-2.225-1.854-2.59-3.426-2.227Zm-1.867-7.223c-.454.104-.804.235-.804.235l1.246 5.398s.27-.038.99-.205c.263-.06.602-.163.867-.3.786-.407 1.248-1.434.881-3.02-.53-2.297-1.798-2.427-3.18-2.108Zm6.814 13.71c-.945.743-2.094 1.035-3.856 1.442-.548.126-2.288.452-2.932.525L4.06 9.94c.167-.063 1.375-.441 2.853-.782 1.06-.245 2.264-.472 3.238-.399 1.438.093 2.862.81 3.386 3.084.732 3.17-1.503 4.46-1.893 4.675.327-.05 3.355-.5 4.24 3.335.575 2.486-.315 3.988-1.216 4.668Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Bt;
