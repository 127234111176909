[
  {
    "channel": "0519f7ad-0d77-4e34-9b4d-1e0eaea80c14",
    "uuid": "AF0A78B9-D41E-4EF9-8C1F-14761F8CC791",
    "channelTitle": "HD",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "0519f7ad-0d77-4e34-9b4d-1e0eaea80c14",
    "uuid": "465A74B9-90F5-4862-B6C2-73454458E428",
    "channelTitle": "HD",
    "title": "Sport"
  },
  {
    "channel": "0519f7ad-0d77-4e34-9b4d-1e0eaea80c14",
    "uuid": "056286D4-63A0-46AD-A5EF-1D457A322319",
    "channelTitle": "HD",
    "title": "Kultur"
  },
  {
    "channel": "0519f7ad-0d77-4e34-9b4d-1e0eaea80c14",
    "uuid": "05AC5E92-862E-4426-8CCC-A92B2556CA4E",
    "channelTitle": "HD",
    "title": "HIF"
  },
  {
    "channel": "0519f7ad-0d77-4e34-9b4d-1e0eaea80c14",
    "uuid": "0c4cda52-3e17-11ec-9bbc-0242ac130002",
    "channelTitle": "HD",
    "title": "BoIS"
  },
  {
    "channel": "0519f7ad-0d77-4e34-9b4d-1e0eaea80c14",
    "uuid": "E0CFB411-920B-4E63-9B97-F7C1283979A9",
    "channelTitle": "HD",
    "title": "Rögle"
  },
  {
    "channel": "0519f7ad-0d77-4e34-9b4d-1e0eaea80c14",
    "uuid": "8AF7BF49-A6D8-4B27-BB99-530EB1F592F9",
    "channelTitle": "HD",
    "title": "Trafik"
  },
  {
    "channel": "0519f7ad-0d77-4e34-9b4d-1e0eaea80c14",
    "uuid": "6D803C91-553B-40AD-BD74-5CC08D8BB064",
    "channelTitle": "HD",
    "title": "Skåne"
  },
  {
    "channel": "0519f7ad-0d77-4e34-9b4d-1e0eaea80c14",
    "uuid": "EE45076F-0499-499B-BE2B-C4C62621D68B",
    "channelTitle": "HD",
    "title": "Helsingborg"
  },
  {
    "channel": "0519f7ad-0d77-4e34-9b4d-1e0eaea80c14",
    "uuid": "9D92CEC0-DD20-444B-8374-CF7985161D7B",
    "channelTitle": "HD",
    "title": "Ängelholm"
  },
  {
    "channel": "0519f7ad-0d77-4e34-9b4d-1e0eaea80c14",
    "uuid": "51D81D67-A13C-48C8-8F1D-C6BE377A0E09",
    "channelTitle": "HD",
    "title": "Landskrona"
  },
  {
    "channel": "0519f7ad-0d77-4e34-9b4d-1e0eaea80c14",
    "uuid": "0842D1D4-8EE2-4B94-A15E-0EE0490555A6",
    "channelTitle": "HD",
    "title": "Näringsliv"
  },
  {
    "channel": "0519f7ad-0d77-4e34-9b4d-1e0eaea80c14",
    "uuid": "2A79439E-80C6-4DD8-8E22-35ED2382A956",
    "channelTitle": "HD",
    "title": "Dygnet runt"
  }
]
