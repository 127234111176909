[
  {
    "channel": "b27a7f7a-5675-4f51-9fd6-d96a4b8b731a",
    "uuid": "A9E05E51-5140-4B40-B37C-D9184B1E8A17",
    "channelTitle": "Smålands-Tidningen",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "b27a7f7a-5675-4f51-9fd6-d96a4b8b731a",
    "uuid": "0318DB6D-9049-428B-A462-DEE3470C4749",
    "channelTitle": "Smålands-Tidningen",
    "title": "Kultur & nöje"
  },
  {
    "channel": "b27a7f7a-5675-4f51-9fd6-d96a4b8b731a",
    "uuid": "41D3405B-E8E2-4294-923F-CBDE3AA5E3F5",
    "channelTitle": "Smålands-Tidningen",
    "title": "Näringsliv"
  },
  {
    "channel": "b27a7f7a-5675-4f51-9fd6-d96a4b8b731a",
    "uuid": "CFAA9A51-F6A4-4DA1-8AA3-6D4743F34EC7",
    "channelTitle": "Smålands-Tidningen",
    "title": "Opinion"
  },
  {
    "channel": "b27a7f7a-5675-4f51-9fd6-d96a4b8b731a",
    "uuid": "ABA56E22-C52A-45B0-B170-A88787FCD651",
    "channelTitle": "Smålands-Tidningen",
    "title": "Sport"
  },
  {
    "channel": "b27a7f7a-5675-4f51-9fd6-d96a4b8b731a",
    "uuid": "89CAB21F-968D-4C9A-BA72-0ECE0A0110FD",
    "channelTitle": "Smålands-Tidningen",
    "title": "Trafik"
  },
  {
    "channel": "b27a7f7a-5675-4f51-9fd6-d96a4b8b731a",
    "uuid": "6A9FF4A4-83DD-4D8C-BFA9-154605750936",
    "channelTitle": "Smålands-Tidningen",
    "title": "Aneby kommun"
  },
  {
    "channel": "b27a7f7a-5675-4f51-9fd6-d96a4b8b731a",
    "uuid": "D797CCE3-AAC6-4EF2-8F5A-CA55442B1ED5",
    "channelTitle": "Smålands-Tidningen",
    "title": "Eksjö kommun"
  },
  {
    "channel": "b27a7f7a-5675-4f51-9fd6-d96a4b8b731a",
    "uuid": "A55E6527-9D3B-4C4D-969A-1A24C0E21D82",
    "channelTitle": "Smålands-Tidningen",
    "title": "Nässjö kommun"
  },
  {
    "channel": "b27a7f7a-5675-4f51-9fd6-d96a4b8b731a",
    "uuid": "FCCA3D82-ED04-42D9-BFCE-E87FFB6E80C4",
    "channelTitle": "Smålands-Tidningen",
    "title": "Ydre kommun"
  }
]
