[
  {
    "channel": "653bcbae-f5c2-4cf5-b301-c396084dcbd9",
    "uuid": "E4CBE14B-5A6F-4334-BF53-F844F75E9D3E",
    "channelTitle": "Falu-kuriren",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "653bcbae-f5c2-4cf5-b301-c396084dcbd9",
    "uuid": "2EE713C3-D333-4240-963C-39CDA2B5126E",
    "channelTitle": "Falu-kuriren",
    "title": "Kultur & nöje"
  },
  {
    "channel": "653bcbae-f5c2-4cf5-b301-c396084dcbd9",
    "uuid": "50E34131-28B2-4069-B4D8-3C67596668D0",
    "channelTitle": "Falu-kuriren",
    "title": "Näringsliv"
  },
  {
    "channel": "653bcbae-f5c2-4cf5-b301-c396084dcbd9",
    "uuid": "C887CD62-85B6-4BF8-AA99-30E5CA999A7B",
    "channelTitle": "Falu-kuriren",
    "title": "Trafik"
  },
  {
    "channel": "653bcbae-f5c2-4cf5-b301-c396084dcbd9",
    "uuid": "B609C789-AD66-4432-9B3B-B098EA6635A9",
    "channelTitle": "Falu-kuriren",
    "title": "Sport"
  },
  {
    "channel": "653bcbae-f5c2-4cf5-b301-c396084dcbd9",
    "uuid": "383EB8EA-8438-4A89-848C-50900A661677",
    "channelTitle": "Falu-kuriren",
    "title": "Dalafyran"
  },
  {
    "channel": "653bcbae-f5c2-4cf5-b301-c396084dcbd9",
    "uuid": "DDDE505F-6D2C-46E2-B1F4-EA71A5BE35AA",
    "channelTitle": "Falu-kuriren",
    "title": "IK Brage"
  },
  {
    "channel": "653bcbae-f5c2-4cf5-b301-c396084dcbd9",
    "uuid": "E9A4370E-0BFE-4B38-823A-2C9820C88DE4",
    "channelTitle": "Falu-kuriren",
    "title": "IFK Rättvik Bandy"
  },
  {
    "channel": "653bcbae-f5c2-4cf5-b301-c396084dcbd9",
    "uuid": "F84630A5-2924-4BB6-BFE0-19136544D9B3",
    "channelTitle": "Falu-kuriren",
    "title": "Leksands IF Herr"
  },
  {
    "channel": "653bcbae-f5c2-4cf5-b301-c396084dcbd9",
    "uuid": "06DE92BA-D377-4DDC-B376-C02B96658631",
    "channelTitle": "Falu-kuriren",
    "title": "Leksands IF Dam"
  },
  {
    "channel": "653bcbae-f5c2-4cf5-b301-c396084dcbd9",
    "uuid": "7A5531DA-E13F-4BC3-9EBC-C3D036775EA6",
    "channelTitle": "Falu-kuriren",
    "title": "Vasaloppet"
  },
  {
    "channel": "653bcbae-f5c2-4cf5-b301-c396084dcbd9",
    "uuid": "AFF6870D-61B6-42B5-AC26-592316F4B0CB",
    "channelTitle": "Falu-kuriren",
    "title": "Falu kommun"
  },
  {
    "channel": "653bcbae-f5c2-4cf5-b301-c396084dcbd9",
    "uuid": "BE567641-DA1C-4A3F-ABBC-342D40970681",
    "channelTitle": "Falu-kuriren",
    "title": "Gagnefs kommun"
  },
  {
    "channel": "653bcbae-f5c2-4cf5-b301-c396084dcbd9",
    "uuid": "C4F65F6E-5E36-4CE2-9805-C10F5E0DA344",
    "channelTitle": "Falu-kuriren",
    "title": "Leksands kommun"
  },
  {
    "channel": "653bcbae-f5c2-4cf5-b301-c396084dcbd9",
    "uuid": "D11FDF01-22D3-48CD-8CFD-BE5BDE59045F",
    "channelTitle": "Falu-kuriren",
    "title": "Malung-Sälens kommun"
  },
  {
    "channel": "653bcbae-f5c2-4cf5-b301-c396084dcbd9",
    "uuid": "F95641FD-9B0D-4699-A160-E7986D4B3897",
    "channelTitle": "Falu-kuriren",
    "title": "Rättviks kommun"
  },
  {
    "channel": "653bcbae-f5c2-4cf5-b301-c396084dcbd9",
    "uuid": "5B9A7B4B-D112-4522-BC70-B26B4F0CA9B3",
    "channelTitle": "Falu-kuriren",
    "title": "Vansbro kommun"
  },
  {
    "channel": "653bcbae-f5c2-4cf5-b301-c396084dcbd9",
    "uuid": "CE20AB49-4280-4578-8BE1-E4B0393B835C",
    "channelTitle": "Falu-kuriren",
    "title": "Dalarna"
  },
  {
    "channel": "653bcbae-f5c2-4cf5-b301-c396084dcbd9",
    "uuid": "E8E0422E-3ED2-48C3-B0BD-845C4ECACC9C",
    "channelTitle": "Falu-kuriren",
    "title": "Ledare & insändare"
  },
  {
    "channel": "653bcbae-f5c2-4cf5-b301-c396084dcbd9",
    "uuid": "119490FF-0A86-40EE-8978-99C1BBB85536",
    "channelTitle": "Falu-kuriren",
    "title": "Väder"
  },
  {
    "channel": "653bcbae-f5c2-4cf5-b301-c396084dcbd9",
    "uuid": "E97DC226-D2CB-4A2E-A754-69107CC6AF42",
    "channelTitle": "Falu-kuriren",
    "title": "IBF Falun"
  },
  {
    "channel": "653bcbae-f5c2-4cf5-b301-c396084dcbd9",
    "uuid": "2DF6286D-2806-4B64-96EB-D3712A737792",
    "channelTitle": "Falu-kuriren",
    "title": "Falu BS"
  },
  {
    "channel": "653bcbae-f5c2-4cf5-b301-c396084dcbd9",
    "uuid": "B06FE5ED-3E93-42F2-B987-27E2ADDD3FBD",
    "channelTitle": "Falu-kuriren",
    "title": "Blåljus"
  },
  {
    "channel": "653bcbae-f5c2-4cf5-b301-c396084dcbd9",
    "uuid": "D93DB4E5-6A5D-48D9-8B74-B0CFF879B6CB",
    "channelTitle": "Falu-kuriren",
    "title": "Falu IF"
  }
]
