[
  {
    "channel": "50b45365-8ee0-43f3-b89c-e29a626cd02a",
    "uuid": "5D5BDDD5-6488-4000-9A55-5EB1F7A0AA5C",
    "channelTitle": "Nya Ludvika Tidning",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "50b45365-8ee0-43f3-b89c-e29a626cd02a",
    "uuid": "2F342522-85C5-4129-887D-2725774F200F",
    "channelTitle": "Nya Ludvika Tidning",
    "title": "Kultur & nöje"
  },
  {
    "channel": "50b45365-8ee0-43f3-b89c-e29a626cd02a",
    "uuid": "A4623310-B104-408E-AFF3-1E0FFEBD5C8E",
    "channelTitle": "Nya Ludvika Tidning",
    "title": "Näringsliv"
  },
  {
    "channel": "50b45365-8ee0-43f3-b89c-e29a626cd02a",
    "uuid": "9EAB902D-6A81-49F5-8CC6-7A4ADFE8A053",
    "channelTitle": "Nya Ludvika Tidning",
    "title": "Opinion"
  },
  {
    "channel": "50b45365-8ee0-43f3-b89c-e29a626cd02a",
    "uuid": "F7DAB118-1900-4D56-9BC8-8627A7E771E2",
    "channelTitle": "Nya Ludvika Tidning",
    "title": "Trafik"
  },
  {
    "channel": "50b45365-8ee0-43f3-b89c-e29a626cd02a",
    "uuid": "52E91632-01CB-41B7-B701-4DD5CD066DE7",
    "channelTitle": "Nya Ludvika Tidning",
    "title": "Krim & blåljus"
  },
  {
    "channel": "50b45365-8ee0-43f3-b89c-e29a626cd02a",
    "uuid": "20B5AB41-94B2-4A7F-9753-3BF38E529143",
    "channelTitle": "Nya Ludvika Tidning",
    "title": "Sport"
  },
  {
    "channel": "50b45365-8ee0-43f3-b89c-e29a626cd02a",
    "uuid": "D35CF557-BDD7-4CE1-BBDB-601E3743C654",
    "channelTitle": "Nya Ludvika Tidning",
    "title": "Innebandy"
  },
  {
    "channel": "50b45365-8ee0-43f3-b89c-e29a626cd02a",
    "uuid": "120DF933-B7B8-4442-A392-1F9C9306A26D",
    "channelTitle": "Nya Ludvika Tidning",
    "title": "Motorsport"
  },
  {
    "channel": "50b45365-8ee0-43f3-b89c-e29a626cd02a",
    "uuid": "ED97A4FD-5909-4AC3-A799-D3B689870477",
    "channelTitle": "Nya Ludvika Tidning",
    "title": "Fotboll"
  },
  {
    "channel": "50b45365-8ee0-43f3-b89c-e29a626cd02a",
    "uuid": "F4DD1D9A-FF68-4E1B-8248-205CB82FBB6F",
    "channelTitle": "Nya Ludvika Tidning",
    "title": "Handboll"
  },
  {
    "channel": "50b45365-8ee0-43f3-b89c-e29a626cd02a",
    "uuid": "6D428A9F-ED7C-44D0-A0D9-3F07005AA21B",
    "channelTitle": "Nya Ludvika Tidning",
    "title": "Ishockey"
  },
  {
    "channel": "50b45365-8ee0-43f3-b89c-e29a626cd02a",
    "uuid": "629FAE09-74C0-4212-BF16-4275353CBADC",
    "channelTitle": "Nya Ludvika Tidning",
    "title": "IK Brage"
  },
  {
    "channel": "50b45365-8ee0-43f3-b89c-e29a626cd02a",
    "uuid": "715A1898-1AD8-4C4C-9812-03AE874E1E73",
    "channelTitle": "Nya Ludvika Tidning",
    "title": "Leksands IF"
  },
  {
    "channel": "50b45365-8ee0-43f3-b89c-e29a626cd02a",
    "uuid": "045D2A75-CBE2-4BFC-A67B-988A0323A66B",
    "channelTitle": "Nya Ludvika Tidning",
    "title": "Vasaloppet"
  },
  {
    "channel": "50b45365-8ee0-43f3-b89c-e29a626cd02a",
    "uuid": "FFCCC463-050F-4394-AE8C-897652EFF7A9",
    "channelTitle": "Nya Ludvika Tidning",
    "title": "Ludvika kommun"
  },
  {
    "channel": "50b45365-8ee0-43f3-b89c-e29a626cd02a",
    "uuid": "F1FF7614-A9FA-4846-9C77-F25CF6B489D3",
    "channelTitle": "Nya Ludvika Tidning",
    "title": "Smedjebackens kommun"
  },
  {
    "channel": "50b45365-8ee0-43f3-b89c-e29a626cd02a",
    "uuid": "48C3F2D1-06BB-4CB3-8935-1F0FC20F9411",
    "channelTitle": "Nya Ludvika Tidning",
    "title": "Dalarna"
  }
]
