const Ta = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
  <title>TA</title>
  <defs>
    <rect id="path-11" x="0" y="0" width="32" height="32"></rect>
    <path d="M18.4397324,8.57816615 C22.7447284,8.57816615 26.234067,11.3441304 26.234067,14.7582117 C26.234067,18.1707974 22.7447284,20.9372602 18.4397324,20.9372602 C14.1327435,20.9372602 10.64191,18.1707974 10.64191,14.7582117 C10.64191,11.3441304 14.1327435,8.57816615 18.4397324,8.57816615 Z" id="path-31"></path>
  </defs>
  <g id="TA" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g id="TA">
      <mask id="mask-21" fill="white">
        <use xlinkHref="#path-11"></use>
      </mask>
      <use id="Mask" fill="#D8D8D8" xlinkHref="#path-11"></use>
      <g id="Group" mask="url(#mask-21)">
        <g transform="translate(-2.718750, 0.000000)" id="TA">
          <path d="M3.8784684,9.53987218 C33.7952144,6.32271874 26.1433831,4.61218822 17.4471917,5.57389424 C9.45106045,6.45982043 2.81170045,9.35690374 0.000996526829,12.784446 L0.000996526829,19.5622549 C3.08225748,22.7799069 10.732594,24.4904374 19.4317749,23.5287314 C27.4264114,22.6443008 34.0642766,19.7457219 36.8784684,16.3216695 L36.8784684,9.53987218 Z" id="Fill-1" fill="#FFFFFF"></path>
          <path d="M36.8774718,16.3280633 L36.8774718,31.9850761 L3.23154836e-14,31.9850761 L3.23154836e-14,19.5686486 C3.08126096,22.7863006 10.7315974,24.4968312 19.4307784,23.5351251 C27.4254149,22.6506946 34.0632801,19.7521156 36.8774718,16.3280633 L36.8774718,16.3280633 Z M36.8774718,2.87627699e-14 L36.8774718,9.54626594 C33.7942178,6.3291125 26.1423866,4.61858197 17.4461952,5.580288 C9.45006392,6.46621418 2.81070392,9.3632975 3.23154836e-14,12.7908397 L3.23154836e-14,12.7908397 L3.23154836e-14,2.87627699e-14 L36.8774718,2.87627699e-14 Z" id="Combined-Shape" fill="#DB4739"></path>
          <mask id="mask-41" fill="white">
            <use xlinkHref="#path-31"></use>
          </mask>
          <use id="mask-41" fill="#0A4D84" xlinkHref="#path-31"></use>
        </g>
      </g>
    </g>
  </g>
</svg>
);

export default Ta;
