const Hbl = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>HBL</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#E86100"/>
      <path d="M20.97 19.94c0 3.35-3.41 3.07-3.41 3.07h-4.83V9.3h4.35s3.63-.32 3.63 3v1.03s.08 1.8-1.8 2.36l-.07.08.07.04s2.06.15 2.06 2.85Zm-3.16-7.22c-.18-.76-1.04-.77-1.04-.77s-.48-.02-.94 0v2.69A7.7 7.7 0 0 0 17 14.6s.67 0 .81-.68c.1-.5 0-1.21 0-1.21Zm.2 5.26c-.12-.96-1.17-.98-1.17-.98s-.52-.02-1.01 0v3.26c.8.03 1.28 0 1.28 0s.76.01.88-.78c.12-.7.01-1.5.01-1.5Zm-9.19 5.04v-5.58H6.57v5.58H3.52V9.28h3.05v5.3h2.25v-5.3h3.06v13.74Zm12.82 0V9.28h3.09v11h3.75v2.74Zm0 0" fill="#FFF"/>
    </g>
  </svg>
);

export default Hbl;
