const Frp = (props) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    {...props}
  >
    <title>FRP</title>
    <g clipPath="url(#a)">
      <path d="M32 0H0v32h32V0Z" fill="#FF7300" />
      <path
        d="M24.062 12.352a7.8 7.8 0 0 1-.224.544c-.448 1.008-.896 2-1.344 3.008-.032.08-.08.112-.176.112h-3.264c-.096 0-.144.016-.192.112-.496 1.12-.992 2.224-1.488 3.344-.064.128-.128.176-.272.176h-3.168c-.128 0-.192.032-.24.144-.496 1.12-.992 2.224-1.488 3.344-.048.096-.096.144-.208.144H8.606c.544-1.216 1.088-2.416 1.632-3.632h-3.52c.544-1.216 1.088-2.416 1.632-3.632H5.918c-.08 0-.144-.016-.192-.08-.96-1.152-1.92-2.304-2.88-3.472a.507.507 0 0 1-.08-.096h7.232c-.544 1.2-1.072 2.4-1.616 3.616H11.742c.112 0 .16-.032.208-.128.496-1.136.992-2.256 1.504-3.376.032-.08.064-.128.16-.128h3.44c-.544 1.216-1.072 2.4-1.616 3.632H18.798c.112 0 .16-.032.208-.128.496-1.136 1.008-2.256 1.52-3.392.032-.064.048-.112.144-.112h3.392Zm-8.672 3.664h-3.392c-.08 0-.112.032-.128.096-.512 1.136-1.024 2.272-1.52 3.408-.016.032-.032.064-.032.112h3.392c.08 0 .112-.032.128-.096.352-.768.688-1.552 1.04-2.32.144-.4.32-.784.512-1.2ZM24.077 12.368c.128-.288.24-.576.384-.864.4-.896.8-1.776 1.2-2.672.032-.08.064-.112.16-.112h3.296c.032 0 .08 0 .128.016a1.103 1.103 0 0 0-.064.16c-.496 1.12-1.008 2.24-1.504 3.376-.032.08-.064.128-.16.128h-3.312c-.064-.032-.096-.032-.128-.032 0 0-.016-.016 0 0Z"
        fill="#232323"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default Frp;
