[
  {
    "channel": "d28290ba-c842-437f-93ac-631d0b9d1b28",
    "uuid": "ED320779-82A3-4DA3-BF81-18A3E5B224C1",
    "channelTitle": "LT",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "d28290ba-c842-437f-93ac-631d0b9d1b28",
    "uuid": "32389155-155A-42C3-85B5-3D5CBA27DD41",
    "channelTitle": "LT",
    "title": "Sport"
  },
  {
    "channel": "d28290ba-c842-437f-93ac-631d0b9d1b28",
    "uuid": "DA6971A3-F216-4723-A3A1-84C9C79372B4",
    "channelTitle": "LT",
    "title": "Näringsliv"
  },
  {
    "channel": "d28290ba-c842-437f-93ac-631d0b9d1b28",
    "uuid": "26366FC2-FEF5-4016-B442-8DCBE57AC7D3",
    "channelTitle": "LT",
    "title": "Kultur & nöje"
  },
  {
    "channel": "d28290ba-c842-437f-93ac-631d0b9d1b28",
    "uuid": "9EE65C92-8E65-468E-B557-154DC745DF33",
    "channelTitle": "LT",
    "title": "Opinion"
  },
  {
    "channel": "d28290ba-c842-437f-93ac-631d0b9d1b28",
    "uuid": "936961CC-7AFC-4B42-9EC8-AA96AD878F32",
    "channelTitle": "LT",
    "title": "Assyriska FF"
  },
  {
    "channel": "d28290ba-c842-437f-93ac-631d0b9d1b28",
    "uuid": "62353505-9928-4DAF-A15B-FAC32008E386",
    "channelTitle": "LT",
    "title": "Södertälje BBK"
  },
  {
    "channel": "d28290ba-c842-437f-93ac-631d0b9d1b28",
    "uuid": "5FD89AE9-09A5-4386-989C-ED0567D315EB",
    "channelTitle": "LT",
    "title": "Södertälje SK Herr"
  },
  {
    "channel": "d28290ba-c842-437f-93ac-631d0b9d1b28",
    "uuid": "3B539FA2-B54B-4453-85D8-D7030807671E",
    "channelTitle": "LT",
    "title": "Syrianska FC"
  },
  {
    "channel": "d28290ba-c842-437f-93ac-631d0b9d1b28",
    "uuid": "DB59AA9B-561F-4CF4-BC47-48C1E1DF8062",
    "channelTitle": "LT",
    "title": "Nordic United"
  },
  {
    "channel": "d28290ba-c842-437f-93ac-631d0b9d1b28",
    "uuid": "11170E79-E429-46CC-9CE5-502FFFDF13C4",
    "channelTitle": "LT",
    "title": "Trafik"
  },
  {
    "channel": "d28290ba-c842-437f-93ac-631d0b9d1b28",
    "uuid": "80FE904C-74A2-448A-BE17-A7EE750623EF",
    "channelTitle": "LT",
    "title": "Nykvarns kommun"
  },
  {
    "channel": "d28290ba-c842-437f-93ac-631d0b9d1b28",
    "uuid": "2E649F5C-660E-47B5-BB47-B236EE821FDE",
    "channelTitle": "LT",
    "title": "Södertälje kommun"
  },
  {
    "channel": "d28290ba-c842-437f-93ac-631d0b9d1b28",
    "uuid": "7D461730-888B-4D82-95B6-A1ED0D5AFBE7",
    "channelTitle": "LT",
    "title": "Järna"
  },
  {
    "channel": "d28290ba-c842-437f-93ac-631d0b9d1b28",
    "uuid": "8FEBCBF2-930F-490B-B813-9326C3BBBEB0",
    "channelTitle": "LT",
    "title": "Rönninge/Salem"
  },
  {
    "channel": "d28290ba-c842-437f-93ac-631d0b9d1b28",
    "uuid": "09F907A9-DDC5-4302-8027-5EE5DB6035E0",
    "channelTitle": "LT",
    "title": "Nykvarns IF"
  },
  {
    "channel": "d28290ba-c842-437f-93ac-631d0b9d1b28",
    "uuid": "3CFE3871-E81E-408B-B15B-44D4407A6248",
    "channelTitle": "LT",
    "title": "Telge SIBK"
  }
]
