const Ljp = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>LJP</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#009378" />
      <path
        d="M22.72 9.633H18.96v.559c1.266 0 1.433 1.024 1.433 1.602v7.677c0 1.584-1.285 1.398-1.285 1.398v.54h4.73v-.54c-1.135 0-1.358-.82-1.358-1.137v-1.36c.95 0 5.084-.243 5.084-3.876 0-3.727-2.812-4.863-4.842-4.863Zm-.363 1.285s3.194.298 3.194 3.634c0 2.85-3.194 2.776-3.194 2.776v-6.41ZM18.313 9.64v.674h-.053c-.22.006-1.066.11-1.066 1.301v9.663l-.003.045s.352 3.362-3.155 3.362c0 0-.94.05-1.812-.285v-2.358h.537s0 1.745 1.309 1.745c1.326 0 1.158-2.03 1.158-2.58v-9.813c0-.29-.073-1.092-1.192-1.092V9.64h4.277Zm-8.798-.234v.592s-1.676.099-1.676 1.875v8.14h3.845s1.48.296 1.48-2.22h.443v3.503h-9.17v-.74s1.405.197 1.405-1.628v-6.956c0-.84-.27-1.924-1.404-1.924v-.642h5.077Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Ljp;
