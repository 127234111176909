export const IconCheck = ({
  width = 24,
  height = 24,
  role = 'img',
  className = 'w-6 h-6'
}) => (
  <svg className={`${className} self-center`} width={width} height={height} role={role} aria-labelledby="check" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
    <title id="check">check</title>
    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
  </svg>
);
