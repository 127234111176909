const Ony = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>BBL</title>
    <path fill="#2A6284" d="M0 0h32v32H0z"/>
    <g fill="#fff">
      <path d="M12.14 17.09a2.56 2.56 0 0 0-.85-1 3.97 3.97 0 0 0-1.44-.58v-.16c.52-.1 1-.36 1.45-.76.45-.4.6-.69.6-1.37 0-1.13-.48-1.89-1.59-2.32a6.07 6.07 0 0 0-2.2-.34H3.53s.61.51.77.62c.28.17.41.5.41.98v8c0 .5-.15.86-.45 1.04-.07.04-.73.56-.73.56H8.3a5 5 0 0 0 3.03-.85 2.66 2.66 0 0 0 1.14-2.23c0-.61-.1-1.15-.33-1.6Zm-5.15-5.17c0-.26.07-.44.2-.53.14-.1.38-.16.72-.16.64 0 1.07.19 1.3.55.23.38.34.83.34 1.37 0 .73-.17 1.24-.52 1.53-.35.28-1.02.43-2.04.43v-3.19Zm2.63 8.22c-.32.4-.88.59-1.69.59-.4 0-.66-.1-.8-.33a1.08 1.08 0 0 1-.13-.58v-3.76a4.7 4.7 0 0 1 2.04.3c.33.16.59.41.77.73.2.36.3.83.3 1.4 0 .71-.16 1.26-.49 1.65Z"/>
      <path d="M20.53 17.09a2.56 2.56 0 0 0-.85-1c-.4-.28-.88-.47-1.44-.6v-.15c.52-.1 1-.36 1.45-.76.45-.4.6-.69.6-1.37 0-1.13-.48-1.89-1.59-2.32a6.28 6.28 0 0 0-2.2-.33h-4.6s.61.51.77.62c.28.17.42.5.42.98v8c0 .5-.16.86-.46 1.04-.07.04-.73.56-.73.56h4.78a5 5 0 0 0 3.03-.85 2.66 2.66 0 0 0 1.14-2.23 3.5 3.5 0 0 0-.32-1.6Zm-5.15-5.17c0-.26.07-.44.2-.53.14-.1.38-.16.72-.16.64 0 1.07.19 1.3.55.23.38.34.83.34 1.37 0 .73-.17 1.24-.52 1.53-.35.28-1.03.43-2.04.43v-3.19Zm2.63 8.22c-.32.4-.88.59-1.69.59-.4 0-.66-.1-.8-.33a1.07 1.07 0 0 1-.13-.58v-3.76a4.7 4.7 0 0 1 2.04.3c.33.16.6.41.77.73.2.36.3.83.3 1.4 0 .71-.16 1.26-.49 1.65Z"/>
      <path d="M28.2 18.63c-.5.9-.45.92-1.01 1.46-.52.5-1.42.62-2.24.62-.46 0-.77-.27-.93-.39-.16-.12-.24-.36-.24-.74v-7.16c0-.67.1-1.17.3-1.34l.49-.48h-4s.38.38.53.48c.24.2.32.5.32.99v8.08c0 .33.06.43-.09.74a4.8 4.8 0 0 1-.76.86l7.23-.02v-.01l.67-3.1h-.27Z"/>
    </g>
  </svg>
);

export default Ony;
