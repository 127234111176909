const Na = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>NA</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#00497F" />
      <path
        d="m9.02 9.875 4.42 8.548v-5.881c0-.895-.12-1.06-1.065-1.06h-.654V9.875h5.432v1.607h-.703c-.945 0-1.066.165-1.066 1.06v9.59h-3.543l-4.73-9.115v6.447c0 .895.121 1.06 1.067 1.06h.654v1.607H3.5v-1.607h.602c.946 0 1.066-.165 1.066-1.06v-6.922c0-.895-.12-1.06-1.066-1.06H3.5V9.875h5.52Zm14.87 0 2.839 9.516c.258.84.464 1.133 1.186 1.133h.585v1.607h-6.363v-1.607h.481c.757 0 1.05-.073 1.05-.439 0-.109-.052-.237-.087-.383l-.447-1.516h-3.32l-.43 1.406c-.068.22-.085.402-.085.493 0 .384.258.44.929.44h.567v1.606h-4.854v-1.607h.21c.93 0 1.067-.329 1.342-1.187l.017-.053c.194-.623 2.04-6.547 2.068-6.67.11-.48-.011-.728-.06-.819-.132-.251-.41-.312-.988-.312h-.816V9.875h6.177Zm-2.39 2.85-1.186 3.872h2.339L21.5 12.725Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Na;
