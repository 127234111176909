[
  {
    "channel": "671b0ca6-4a2d-49b1-a2b9-52ea93fe12da",
    "uuid": "B794573B-C9A0-48C2-BD20-49D21AC469A4",
    "channelTitle": "Hudiksvalls Tidning",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "671b0ca6-4a2d-49b1-a2b9-52ea93fe12da",
    "uuid": "237D4045-75BE-4CD8-8593-D3578AE67C9E",
    "channelTitle": "Hudiksvalls Tidning",
    "title": "Näringsliv"
  },
  {
    "channel": "671b0ca6-4a2d-49b1-a2b9-52ea93fe12da",
    "uuid": "6760C4E1-FE43-4CA0-A5E4-64B25D8A4238",
    "channelTitle": "Hudiksvalls Tidning",
    "title": "Opinion"
  },
  {
    "channel": "671b0ca6-4a2d-49b1-a2b9-52ea93fe12da",
    "uuid": "5C1A4D9A-C4DF-4F79-B3A9-2A2DD0EAB365",
    "channelTitle": "Hudiksvalls Tidning",
    "title": "Sport"
  },
  {
    "channel": "671b0ca6-4a2d-49b1-a2b9-52ea93fe12da",
    "uuid": "9B84B7B0-F75A-4646-A391-FBBB4B13D7F6",
    "channelTitle": "Hudiksvalls Tidning",
    "title": "Hudiksvalls FF"
  },
  {
    "channel": "671b0ca6-4a2d-49b1-a2b9-52ea93fe12da",
    "uuid": "4031C2EB-F50B-4851-AFED-02E7D6024C1B",
    "channelTitle": "Hudiksvalls Tidning",
    "title": "Hudiksvalls hockeyclub"
  },
  {
    "channel": "671b0ca6-4a2d-49b1-a2b9-52ea93fe12da",
    "uuid": "32AC06B3-C835-4951-A819-A8265479EDEF",
    "channelTitle": "Hudiksvalls Tidning",
    "title": "Trafik"
  },
  {
    "channel": "671b0ca6-4a2d-49b1-a2b9-52ea93fe12da",
    "uuid": "90EE3386-1E35-48EE-B75A-BD9F7AB46E07",
    "channelTitle": "Hudiksvalls Tidning",
    "title": "Hudiksvalls kommun"
  },
  {
    "channel": "671b0ca6-4a2d-49b1-a2b9-52ea93fe12da",
    "uuid": "232F58ED-52F1-4CB0-ACBE-7D0B6E28E640",
    "channelTitle": "Hudiksvalls Tidning",
    "title": "Nordanstigs kommun"
  }
]
