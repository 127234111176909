const Jnytt = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>JNYTT</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#020C26" />
      <path
        d="m18.6 12.133-.876 8.506c-.076.707-.264 1.219-.562 1.536-.298.318-.76.52-1.383.608l-.677.095-.184-1.308.389-.055c.394-.055.656-.194.786-.415.13-.221.166-.545.106-.972-.035-.247-.14-.575-.315-.985l-2.598-6.263 1.641-.23 1.725 4.746.029-.004.291-5.03 1.628-.229Zm-12.313-.938 1.155 8.22c.1.711-.009 1.28-.326 1.707-.317.426-.94.705-1.872.836l-.59.083-.216-1.536.374-.053c.336-.047.566-.15.691-.307.125-.158.166-.384.125-.678l-1.127-8.02 1.786-.252Zm6.173 2.091c.28.25.455.627.526 1.13l.869 6.186-1.613.227-.833-5.93c-.052-.37-.27-.528-.655-.474a.994.994 0 0 0-.443.186c-.143.102-.318.25-.525.443l.843 6.002-1.613.226-1.075-7.65 1.526-.215.108.768.101-.116c.313-.353.579-.606.797-.757.219-.152.53-.256.933-.313.422-.06.774.036 1.054.287Zm8.65-3.608.29 2.062 1.08-.152.184 1.308-1.08.152.62 4.409c.058.417.27.6.634.549l.533-.075.192 1.365a2.977 2.977 0 0 1-.794.199c-.615.086-1.103.01-1.464-.23-.362-.239-.583-.648-.665-1.226l-.67-4.764-.892.125-.184-1.308.893-.126-.29-2.062 1.613-.226Zm4.034-.553.29 2.062 1.08-.152.183 1.309-1.08.152.62 4.408c.059.417.27.6.635.55l.533-.076.192 1.365a2.977 2.977 0 0 1-.795.2c-.614.085-1.102.01-1.464-.23-.361-.24-.583-.648-.664-1.227l-.67-4.764-.893.126-.183-1.309.892-.125-.29-2.062 1.614-.227Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Jnytt;
