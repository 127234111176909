const Smp = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>SMP</title>
    <path fill="#2A6284" d="M0 0h32v32H0z"/>
    <g fill="none">
      <path fill="#D00019" d="M32 0H0v32h32V0Z"/>
      <path fill="#fff" d="M6.8 14.03c.04-.73 1.17-.75 1.75-.75.68 0 1.65.2 1.65.2l.22-1.84a21.23 21.23 0 0 0-2.27-.12c-1.78 0-3.87.64-3.87 2.65 0 2.8 3.78 2.44 3.75 3.84-.01.5-.7.68-1.88.64-.56-.02-1.76-.18-1.76-.18l-.23 1.9s1.31.18 2.92.18c1.66 0 3.48-.67 3.48-2.66 0-2.94-3.82-2.58-3.76-3.86Zm9.07 2.16-1.35-4.55h-3.11v8.8h2.46l-.08-5.98 1.74 5.83h.67l1.73-5.83-.07 5.98h2.47v-8.8H17.2l-1.34 4.55Zm8.62-.42-.61.02v-2.63h.6c.68 0 .94.29.94 1.33 0 .89-.27 1.27-.93 1.28Zm.1-4.13h-3.2v8.8h2.51l-.04-3.03 1.05-.01c1.87-.07 2.91-.67 2.91-2.83 0-2.28-1.29-2.93-3.24-2.93Z"/>
    </g>
  </svg>
);

export default Smp;
