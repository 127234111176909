const Ab = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>AB</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#9B1C2D" />
      <path
        d="M13.26 16.2h3.734l-1.866-5.493-1.868 5.492Zm-.636 1.797c-.403 1.498-1.188 3.42-1.188 5.068 0 .774.785 1.224 1.485 1.224.233 0 .297.124.297.324s-.19.275-.34.45c-.212.274-.275.325-.466.325-.53 0-1.76-.15-2.206-.15-.785 0-1.655.15-2.461.15-.17 0-.297-.1-.297-.25s.339-.75.573-.85c1.06-.499 1.506-2.221 3.67-8.288 1.188-3.32 2.185-6.492 2.185-7.04 0-.4-.128-.5-.128-.7 0-.15.234-.55.298-.574.17-.05.7-.05 1.145-.225 1.358-.524 1.506-1.149 1.718-1.149.319 0 .467.45.467 1.499 0 .649 4.391 13.582 5.6 15.554.446.724.728.8 1.226.924.114.025.153.124.153.249 0 .175-.383.85-.612.85-.498 0-1.53-.15-3.545-.15-1.718 0-2.143.15-2.588.15-.15 0-.212-.076-.212-.25 0-.125.381-.774.53-.85.721-.349.933-.998.933-1.497 0-1.124-.89-3.296-1.273-4.794h-4.964Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Ab;
