[
  {
    "channel": "4ce1fe0e-7120-4aea-a5ee-6bcc5243efc0",
    "uuid": "AAEFF0D2-5F77-42F6-8DC3-AFC8FEA6F21F",
    "channelTitle": "Ölandsbladet",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "4ce1fe0e-7120-4aea-a5ee-6bcc5243efc0",
    "uuid": "B7CA8032-BDA2-4FDE-82D9-32B5867BABDF",
    "channelTitle": "Ölandsbladet",
    "title": "Sport"
  }
]
