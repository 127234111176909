[
  {
    "channel": "e39aac2d-6f27-48c3-b887-ad25ae9ca23d",
    "uuid": "A7B1ACE7-DA02-458F-85A0-5CC75C4FBC4C",
    "channelTitle": "VP",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "e39aac2d-6f27-48c3-b887-ad25ae9ca23d",
    "uuid": "17283FF9-E82A-47DB-B0A5-AF065221021F",
    "channelTitle": "VP",
    "title": "Kultur & nöje"
  },
  {
    "channel": "e39aac2d-6f27-48c3-b887-ad25ae9ca23d",
    "uuid": "4D862176-3FE4-47F9-AFFB-BC18418DB101",
    "channelTitle": "VP",
    "title": "Näringsliv"
  },
  {
    "channel": "e39aac2d-6f27-48c3-b887-ad25ae9ca23d",
    "uuid": "0E2C5699-CE55-47A7-A830-80AA479E6E26",
    "channelTitle": "VP",
    "title": "Opinion"
  },
  {
    "channel": "e39aac2d-6f27-48c3-b887-ad25ae9ca23d",
    "uuid": "BA733CC4-14B9-4F70-A68C-DCBD53047C54",
    "channelTitle": "VP",
    "title": "Sport"
  },
  {
    "channel": "e39aac2d-6f27-48c3-b887-ad25ae9ca23d",
    "uuid": "58EBE956-7790-43C2-ABCD-71D85814A737",
    "channelTitle": "VP",
    "title": "Trafik"
  },
  {
    "channel": "e39aac2d-6f27-48c3-b887-ad25ae9ca23d",
    "uuid": "F9E6E633-1FB5-4D51-9690-1DE597ECEAB3",
    "channelTitle": "VP",
    "title": "Sävsjö kommun"
  },
  {
    "channel": "e39aac2d-6f27-48c3-b887-ad25ae9ca23d",
    "uuid": "6799ED84-FD67-4551-AD87-060678CCF361",
    "channelTitle": "VP",
    "title": "Vetlanda kommun"
  }
]
