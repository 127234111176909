[
  {
    "channel": "dd60b489-36f9-41d5-930d-0d9be47a715b",
    "uuid": "EA40CA56-E63A-4224-9CE8-C503E62BB1EF",
    "channelTitle": "Söderhamns-Kuriren",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "dd60b489-36f9-41d5-930d-0d9be47a715b",
    "uuid": "2F3FAB5E-ED75-45A6-A1BB-B575CE0E6E84",
    "channelTitle": "Söderhamns-Kuriren",
    "title": "Näringsliv"
  },
  {
    "channel": "dd60b489-36f9-41d5-930d-0d9be47a715b",
    "uuid": "10071089-308E-4D1A-B536-2E88D853EBD3",
    "channelTitle": "Söderhamns-Kuriren",
    "title": "Opinion"
  },
  {
    "channel": "dd60b489-36f9-41d5-930d-0d9be47a715b",
    "uuid": "F5CFE6DB-959F-49DB-B7E1-E878EE085594",
    "channelTitle": "Söderhamns-Kuriren",
    "title": "Sport"
  },
  {
    "channel": "dd60b489-36f9-41d5-930d-0d9be47a715b",
    "uuid": "3447E4DD-BEE4-422A-A146-889D3E32BB33",
    "channelTitle": "Söderhamns-Kuriren",
    "title": "Trafik"
  },
  {
    "channel": "dd60b489-36f9-41d5-930d-0d9be47a715b",
    "uuid": "FEEC823D-0A49-434E-A049-B69AF1B28E0B",
    "channelTitle": "Söderhamns-Kuriren",
    "title": "Söderhamns kommun"
  }
]
