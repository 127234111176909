const Nvp = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>NVP</title>
    <g fill="none" fillRule="evenodd">
      <path d="M32 0H0v32h32V0Z" fill="#286F33" />
      <g fill="#FFF">
        <path d="M5.969 19.677v-1.799h-.665V15.23l3.167 4.545h2.475V13.96h.655v-1.8H8.569v1.8h.61v2.13l-2.734-3.93H2.883v1.8h.66v3.918h-.66v1.8h3.086ZM17.367 19.743l2.26-5.783h.556v-1.8H17.15v1.8h.616l-1.173 3.205-1.118-3.205h.637v-1.8H12.07v1.8h.557l2.4 5.783h2.34ZM25.151 19.677v-1.799h-.756v-1.054h1.173c.924 0 1.61-.076 2.053-.232a2.33 2.33 0 0 0 1.087-.768c.276-.351.411-.789.411-1.318 0-.427-.113-.822-.346-1.184-.232-.362-.59-.643-1.08-.854-.493-.205-1.266-.308-2.325-.308h-4.61v1.8h.73v3.918h-.73v1.8h4.393Zm-.756-5.869h.454c.346 0 .589.017.735.05.15.032.27.107.362.226.092.125.14.27.14.449 0 .27-.086.454-.26.545-.178.092-.47.14-.864.14h-.567v-1.41Z" />
      </g>
    </g>
  </svg>
);

export default Nvp;
