[
  {
    "channel": "502764de-54d3-4107-b14e-bcfa0771246f",
    "uuid": "C30D109C-FAE9-49DC-931C-84C5A1206439",
    "channelTitle": "Borås Tidning",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "502764de-54d3-4107-b14e-bcfa0771246f",
    "uuid": "99470FE4-195D-4CB5-BF22-A5E4E31EDAE1",
    "channelTitle": "Borås Tidning",
    "title": "Sport"
  }
]
