const Sk = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>SK</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#C52038" />
      <path
        d="M20.43 10.719h-5.775v1.491h.535c.803 0 .866.142.866.895v5.747c0 .754-.063.911-.866.911h-.535v1.492h6.027v-1.492h-.346c-.77 0-.85-.173-.85-.91v-.88l1.59-1.225 1.542 2.34a.636.636 0 0 1 .126.377c0 .22-.22.298-.74.298h-.503v1.492h6.53v-1.492h-.645c-.488 0-.802-.204-1.133-.706L23.405 14.8l2.124-1.821c.614-.518 1.133-.77 1.495-.77h.535V10.72h-5.885v1.491h.881c.41 0 .614.079.614.267 0 .126-.079.283-.252.424l-3.415 2.81v-2.606c0-.69 0-.895.834-.895h.095V10.72ZM4.222 17.298v3.705h1.366l.371-.549a7.19 7.19 0 0 0 3.327.8c2.629 0 4.218-1.318 4.218-3.289 0-1.97-1.367-2.816-4.159-3.453-1.44-.325-2.287-.355-2.287-1.14 0-.563.595-1.052 1.53-1.052 1.514 0 2.42.68 2.748 2.089h1.677v-3.453h-1.425l-.267.504c-1.04-.518-2.095-.741-3.297-.741-2.48 0-4.054 1.378-4.054 3.334 0 .934.37 1.645 1.172 2.237.386.297 1.219.594 2.466.964 1.648.504 2.658.563 2.658 1.452 0 .607-.564 1.037-1.485 1.037-1.47 0-2.643-.889-3.119-2.445h-1.44Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Sk;
