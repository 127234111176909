const Vb = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>VB</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#DA2037" />
      <path
        d="M22.446 8.657c1.083.017 3.624.31 3.624 3.332 0 2.053-1.82 3.355-1.82 3.355s2.469 1.48 2.469 4.314c0 2.385-1.73 3.68-3.486 3.68H15.67v-8.98l-2.464 8.98H9.05L5.281 8.662h3.77l2.053 8.681 2.128-8.681h8.818l.163-.004Zm-1.185 3.14h-5.59v.753h3.458v7.527h1.855c.66 0 2.01-.193 2.01-1.303 0-1.25-2.785-3.304-3.568-3.407 1.03-.122 2.964-1.734 2.964-2.754 0-.855-1.13-.816-1.13-.816Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Vb;
