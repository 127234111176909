const Ft = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>FT</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#01589C" />
      <path
        d="M16.024 7.563v6.13h.655l1.828-5.09h1.335v14.924l-1.383.347v.55H24.9v-.579l-1.265-.348V8.602h1.312l1.826 5.091h.633v-6.13H16.024Zm-11.43 0v.577l1.382.375v15.012l-1.382.347v.55h6.885v-.579l-1.71-.405v-6.595l3.84.174v-1.446l-3.84.116V8.602h2.506l1.546 3.645h.678V7.563H4.594Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Ft;
