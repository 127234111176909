const Sn = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>SN</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#FFF" />
      <path
        d="M9.4 9.813c.85 0 1.59.205 2.16.495l.066-.3h2.468v4.062h-2.882l-.14-1.302c-.375-.202-.858-.303-1.423-.303-.385 0-.725.087-.955.238-.231.153-.358.353-.36.63.005.254.114.384.365.525.25.134.63.233 1.073.348l1.766.475c.934.26 1.694.672 2.222 1.246.53.575.818 1.313.817 2.187.003 1.408-.595 2.44-1.494 3.1-.898.661-2.085.96-3.284.96a5.61 5.61 0 0 1-2.535-.589l-.069.394H4.687v-3.963h2.881l.139 1.124a4.5 4.5 0 0 0 1.61.284c.35 0 .728-.057 1-.186.274-.135.428-.306.43-.584-.003-.271-.096-.42-.27-.553-.175-.13-.447-.223-.767-.303l-2.08-.556c-.828-.219-1.554-.59-2.076-1.149-.524-.557-.834-1.304-.833-2.236 0-1.113.38-2.13 1.162-2.866.782-.735 1.957-1.18 3.516-1.178Zm9.798.196 4.016 5.76v-3.061l-1.048-.147v-2.552h5.178v2.551l-1.032.148v9.27h-3.027l-4.182-6.114v3.416l1.048.147v2.552H14.94v-2.552l1.066-.148v-6.57l-1.066-.148v-2.552h4.258Z"
        fill="#D71A20"
      />
    </g>
  </svg>
);

export default Sn;
