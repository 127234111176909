const Ya = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>YA</title>
    <g fill="none">
      <path fill="#005CA9" d="M32 0H0v32h32V0Z"/>
      <path fill="#fff" d="M18.19 24.34a.65.65 0 0 0 .66-.64c0-.37-.3-.64-.66-.64a.64.64 0 0 0-.64.64c-.02.36.3.64.64.64Zm-2.84-5.57c.37 0 .64-.29.64-.64a.64.64 0 0 0-.64-.64.65.65 0 0 0-.64.64c0 .35.3.64.64.64Z"/>
      <path fill="#fff" d="m26.5 13.98 1.44-1.95s.05-.06.05-.23c0-.11-.11-.34-.11-.34-.14-.3-.53-.95-1.59-1.85a7.16 7.16 0 0 0-1.82-1.21c-.25-.1-.37-.12-.43-.07L22 10.25c-1.4-.97-2.07-1.29-2.07-1.29l-.55.76c.3.14.9.5 1.93 1.24l-.6.6a2.1 2.1 0 0 0-1.57.83c-.2-.09-.95-.32-2.28-.78a2.49 2.49 0 0 0-1.9-1.4c-.6-.06-.92.13-1.09.36a27.77 27.77 0 0 1-7.9-4.04c-.23-.17-.38-.26-.6-.44-.5.37-.98 1.2-1.22 1.85a5.5 5.5 0 0 0-.23 2.1c4.08.06 6.3.55 9.69 1.52.09.05.17.06.28.09-.07.64-.32 1.4-.46 2.03h-.82l.02 1.59c.03.99.1 1.7.28 2.68a6.18 6.18 0 0 0 2.55 3.73v3.4h1.22V22.2s.6.23 1.53.34c.95.14 1.54.1 1.54.1l-.4 1.6h1.04l.64-2.48c-.96-.02-2.5.12-3.96-.34a5.14 5.14 0 0 1-3.51-4.28c-.06-.37-.06-.61-.06-.61h3s.03.28.15.62c.28.66.84 1.07 1.46 1.28 1.2.35 2.58.2 3.84.22l.26-.92h-3.12v-.7c1.7 0 3.6-.02 5.3 0 0 0-.74 1.4-1.41 3.54a6.12 6.12 0 0 0 .05 4.48h1.11a4.71 4.71 0 0 1-.5-3.19c.41-2.25 2.22-5.7 2.22-5.7s.05-.06-.06-.06h-.78s.44-.6.84-1.06a34.26 34.26 0 0 1 1.66 1.68l.64-.74c-.17-.18-.72-.8-1.66-1.7v-.31ZM5.08 9.19s-.05-.23.18-.83c.23-.63.4-.7.4-.7 1.57 1.21 4.47 2.39 4.47 2.39s-3-.78-5.05-.86Zm9.8 1.92c.43.14.96.41 1.2.97.16.41.06 1.04-.1 1.59h-1.55c.21-.7.58-1.87.44-2.56Zm1.39 4.6h-2.92l-.05-.82h2.97v.82Zm2.03 0v1.71a1.7 1.7 0 0 1-.63-.65c-.24-.48-.15-1.95-.18-3.1h-.43c.09-.3.15-.65.15-1.09l1.7.5a2.18 2.18 0 0 0-.1.59c0 .72.36 1.36.93 1.74.09.09.14.18.14.3H18.3Zm5.34-.98c.3.17.58.2.97.12 0 0 .12.02.05.06-.06.07-.75.97-.75 1.2h-2.96c.2-.3.41-.4.55-.5 0 0-.14-.44-.18-.31h-.09c-.06-.15-.14-.12-.2-.12-.74 0-1.33-.63-1.33-1.36a1.33 1.33 0 0 1 1.33-1.33c.3 0 .55.1.75.26a.9.9 0 0 1 .35.25c.06.1.37-.28.4-.34.06-.07-.25-.4-.4-.53l-.3-.25.26-.26 1.01.78a37.85 37.85 0 0 1 1.64 1.41c-.2.4-.6.53-1.01.49-.1.1-.13.4-.1.43Zm2.12-1.34c-1-.9-1.95-1.7-2.97-2.45l1.45-1.46.03.03c.23.11.66.4 1.4.99a5.68 5.68 0 0 1 1.25 1.34l.02.02c-.37.5-1.18 1.53-1.18 1.53Z"/>
      <path fill="#fff" d="M13.94 10.02a7.38 7.38 0 0 1 5.26-2.16c1.27 0 2.51.32 3.6.95.51-.49.72-.67.78-.75a8.29 8.29 0 0 0-4.38-1.24 8.4 8.4 0 0 0-6.33 2.88c.32.14.66.26 1.03.4.03-.03.03-.05.04-.08Zm12.62 6.6c-.18-.24-.14.04-.14.16v7.87H11.77v-9.38c0-1.18.28-2.28.76-3.3l-1.01-.26c-.49 1.09-.57 3.55-.57 3.55v10.6h16.68v-8.27l-.9-.99"/>
    </g>
  </svg>
);

export default Ya;
