[
  {
    "channel": "92a00880-5bf9-4dc8-8202-44e6c8c35bde",
    "uuid": "41BE93C4-A2C1-4570-ADE3-CC95F88E3D37",
    "channelTitle": "Fagersta-Posten",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "92a00880-5bf9-4dc8-8202-44e6c8c35bde",
    "uuid": "35D8DB6E-F2FA-4A31-BD8D-F2B108A3383E",
    "channelTitle": "Fagersta-Posten",
    "title": "Näringsliv"
  },
  {
    "channel": "92a00880-5bf9-4dc8-8202-44e6c8c35bde",
    "uuid": "4E11D227-8BA1-41C0-B801-5E96F4F01662",
    "channelTitle": "Fagersta-Posten",
    "title": "Trafik"
  },
  {
    "channel": "92a00880-5bf9-4dc8-8202-44e6c8c35bde",
    "uuid": "B6A5B158-AB9D-45E3-993A-1DC8CE9D8FCA",
    "channelTitle": "Fagersta-Posten",
    "title": "Krim"
  },
  {
    "channel": "92a00880-5bf9-4dc8-8202-44e6c8c35bde",
    "uuid": "5D14F2D4-5B8B-422B-A961-9423C0E22218",
    "channelTitle": "Fagersta-Posten",
    "title": "Kultur & nöje"
  },
  {
    "channel": "92a00880-5bf9-4dc8-8202-44e6c8c35bde",
    "uuid": "2F22ECD1-9A1C-4D63-9E33-FE755837CD36",
    "channelTitle": "Fagersta-Posten",
    "title": "Opinion"
  },
  {
    "channel": "92a00880-5bf9-4dc8-8202-44e6c8c35bde",
    "uuid": "DA2C44F0-3C87-4326-910F-33207F0A6B2E",
    "channelTitle": "Fagersta-Posten",
    "title": "Sport"
  },
  {
    "channel": "92a00880-5bf9-4dc8-8202-44e6c8c35bde",
    "uuid": "9014DBB1-ED62-4C0D-8597-6B7161B044F0",
    "channelTitle": "Fagersta-Posten",
    "title": "Fagersta AIK"
  },
  {
    "channel": "92a00880-5bf9-4dc8-8202-44e6c8c35bde",
    "uuid": "35D6C4E2-23B6-483E-B22B-6624F0846BC5",
    "channelTitle": "Fagersta-Posten",
    "title": "Västanfors IF BK"
  },
  {
    "channel": "92a00880-5bf9-4dc8-8202-44e6c8c35bde",
    "uuid": "D5B65FE4-2D78-463E-AEB8-D8B99D438637",
    "channelTitle": "Fagersta-Posten",
    "title": "Västanfors IF FK"
  },
  {
    "channel": "92a00880-5bf9-4dc8-8202-44e6c8c35bde",
    "uuid": "82B08CD0-9F46-4F7A-937C-FB225C6C7614",
    "channelTitle": "Fagersta-Posten",
    "title": "Fagersta Södra IK"
  },
  {
    "channel": "92a00880-5bf9-4dc8-8202-44e6c8c35bde",
    "uuid": "563C97A5-B20D-48B9-A00D-80CBD4368E94",
    "channelTitle": "Fagersta-Posten",
    "title": "Per-Ols IBF"
  },
  {
    "channel": "92a00880-5bf9-4dc8-8202-44e6c8c35bde",
    "uuid": "81A94BA6-80F3-4D0D-A0D8-EBD23D4BE6EB",
    "channelTitle": "Fagersta-Posten",
    "title": "Fagersta kommun"
  },
  {
    "channel": "92a00880-5bf9-4dc8-8202-44e6c8c35bde",
    "uuid": "B0F170BA-E5CA-475C-AAFC-567D4430D891",
    "channelTitle": "Fagersta-Posten",
    "title": "Norbergs kommun"
  },
  {
    "channel": "92a00880-5bf9-4dc8-8202-44e6c8c35bde",
    "uuid": "155B4F9B-9B3F-4BB8-9C19-A7A17D026559",
    "channelTitle": "Fagersta-Posten",
    "title": "Skinnskattebergs kommun"
  }
]
