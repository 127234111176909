[
  {
    "channel": "7b087c6c-3ef5-4a0e-b2d5-5c1f2612d715",
    "uuid": "156A4617-F4C7-4010-B821-43701BAC0816",
    "channelTitle": "Dalademokraten",
    "title": "Nyheter"
  },
  {
    "channel": "7b087c6c-3ef5-4a0e-b2d5-5c1f2612d715",
    "uuid": "C5F95C5F-414D-43F9-BDFD-85A6B1F27160",
    "channelTitle": "Dalademokraten",
    "title": "Kultur"
  },
  {
    "channel": "7b087c6c-3ef5-4a0e-b2d5-5c1f2612d715",
    "uuid": "3E7D7B08-D5A6-4B34-BB1E-930EA4EDEB6F",
    "channelTitle": "Dalademokraten",
    "title": "Opinion"
  },
  {
    "channel": "7b087c6c-3ef5-4a0e-b2d5-5c1f2612d715",
    "uuid": "B35B6ADB-A7FA-4543-BF47-4A67A3831103",
    "channelTitle": "Dalademokraten",
    "title": "Debatt"
  }
]
