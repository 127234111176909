import { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
function Accordion ({ title, children, className }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className={`${className} accordion text-app-gray-text pl-4`}>
      <div className="accordion-title underline decoration-solid " onClick={toggle}>
          <span>{title}</span>
          <ChevronDownIcon className={`accordion-icon accordion-icon__${isOpen ? 'down' : 'up'} max-h-[20px]`}/>
      </div>
      <div className="accordion-content" aria-expanded={!isOpen}>
          {children}
      </div>
    </div>
  );
}

export default Accordion;
