const Ht = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>HT</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#BA3738" />
      <path
        d="M16.6 12.376h-1.217v7.2h1.218v2.845h-6.355v-2.844h.783v-2.188h-2.25v2.188h.782v2.844H3.188v-2.844h1.236v-7.201H3.187V9.53h6.374v2.845H8.78v2.207h2.25v-2.207h-.783V9.53h6.355v2.845Zm12.212 2.375h-2.145l-.522-2.375h-.85v7.2h1.372v2.845h-6.964v-2.844h1.218v-7.201h-.831l-.6 2.375h-2.086V9.53h11.409v5.22Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Ht;
