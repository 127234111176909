[
  {
    "channel": "a0a2b200-c21b-46db-b9b4-34b32f7b53be",
    "uuid": "1F2A9484-6658-4263-9422-E27A6149D9CD",
    "channelTitle": "Avesta Tidning",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "a0a2b200-c21b-46db-b9b4-34b32f7b53be",
    "uuid": "D67DFE81-DAEE-4E99-A51D-0FA28A647DBE",
    "channelTitle": "Avesta Tidning",
    "title": "Sport"
  },
  {
    "channel": "a0a2b200-c21b-46db-b9b4-34b32f7b53be",
    "uuid": "90A54291-1BC8-482B-998B-4B4665C049D2",
    "channelTitle": "Avesta Tidning",
    "title": "Näringsliv"
  },
  {
    "channel": "a0a2b200-c21b-46db-b9b4-34b32f7b53be",
    "uuid": "F203F7F3-B43E-4158-86A7-E06DAFAADC0F",
    "channelTitle": "Avesta Tidning",
    "title": "Trafik"
  },
  {
    "channel": "a0a2b200-c21b-46db-b9b4-34b32f7b53be",
    "uuid": "BD7703F1-F01B-4CCC-B3C7-CED80C5E01FA",
    "channelTitle": "Avesta Tidning",
    "title": "Kultur & nöje"
  },
  {
    "channel": "a0a2b200-c21b-46db-b9b4-34b32f7b53be",
    "uuid": "B57A52C8-5B54-4ABE-B434-551143BF152A",
    "channelTitle": "Avesta Tidning",
    "title": "Opinion"
  },
  {
    "channel": "a0a2b200-c21b-46db-b9b4-34b32f7b53be",
    "uuid": "BD6F7FED-BE34-4031-B4FF-86C799E22B17",
    "channelTitle": "Avesta Tidning",
    "title": "Avesta AIK"
  },
  {
    "channel": "a0a2b200-c21b-46db-b9b4-34b32f7b53be",
    "uuid": "1C585AFF-7013-4B88-87B5-DB4B04D0BE01",
    "channelTitle": "Avesta Tidning",
    "title": "Leksands IF Dam"
  },
  {
    "channel": "a0a2b200-c21b-46db-b9b4-34b32f7b53be",
    "uuid": "135E1D2F-DFCF-49F6-950D-299539DCC214",
    "channelTitle": "Avesta Tidning",
    "title": "Leksands IF Herr"
  },
  {
    "channel": "a0a2b200-c21b-46db-b9b4-34b32f7b53be",
    "uuid": "5DC0E34C-6C36-4C4A-9B8A-43D636F65F5A",
    "channelTitle": "Avesta Tidning",
    "title": "Masarna"
  },
  {
    "channel": "a0a2b200-c21b-46db-b9b4-34b32f7b53be",
    "uuid": "A2356C37-AFAE-469E-9E06-CFE66BE9D46A",
    "channelTitle": "Avesta Tidning",
    "title": "Avesta kommun"
  }
]
