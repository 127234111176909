[
  {
    "channel": "72bac164-d40d-4cd1-8f3a-9913410784fe",
    "uuid": "085617E9-620E-4310-80B6-CECB4C2AF09A",
    "channelTitle": "Sydsvenskan",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "72bac164-d40d-4cd1-8f3a-9913410784fe",
    "uuid": "CF0CE4E0-457E-48DC-ACDE-EE3D21C273E6",
    "channelTitle": "Sydsvenskan",
    "title": "Sport"
  },
  {
    "channel": "72bac164-d40d-4cd1-8f3a-9913410784fe",
    "uuid": "DFCF6CA3-D2C4-4DE6-8E31-B5A7CF76A500",
    "channelTitle": "Sydsvenskan",
    "title": "Kultur"
  },
  {
    "channel": "72bac164-d40d-4cd1-8f3a-9913410784fe",
    "uuid": "1BFCC0FB-C789-41E7-BF8D-814BB0A11CA9",
    "channelTitle": "Sydsvenskan",
    "title": "MFF"
  },
  {
    "channel": "72bac164-d40d-4cd1-8f3a-9913410784fe",
    "uuid": "0F56D3BA-2BB6-4F3E-8DB4-69DDE4EEA365",
    "channelTitle": "Sydsvenskan",
    "title": "Redhawks"
  },
  {
    "channel": "72bac164-d40d-4cd1-8f3a-9913410784fe",
    "uuid": "0A82B64B-A813-4BB9-BD81-B679E6179072",
    "channelTitle": "Sydsvenskan",
    "title": "Trafik"
  },
  {
    "channel": "72bac164-d40d-4cd1-8f3a-9913410784fe",
    "uuid": "4CDD6F5C-5EAC-4942-ADFA-E4B6B224207B",
    "channelTitle": "Sydsvenskan",
    "title": "Skåne"
  },
  {
    "channel": "72bac164-d40d-4cd1-8f3a-9913410784fe",
    "uuid": "A484D8DB-1169-4272-9A05-65F22BF65D02",
    "channelTitle": "Sydsvenskan",
    "title": "Malmö"
  },
  {
    "channel": "72bac164-d40d-4cd1-8f3a-9913410784fe",
    "uuid": "DFB041E1-5435-4115-8781-1FFB290E5068",
    "channelTitle": "Sydsvenskan",
    "title": "Lund"
  },
  {
    "channel": "72bac164-d40d-4cd1-8f3a-9913410784fe",
    "uuid": "062E05DD-19E6-4320-A0A4-88115E151F82",
    "channelTitle": "Sydsvenskan",
    "title": "Näringsliv"
  },
  {
    "channel": "72bac164-d40d-4cd1-8f3a-9913410784fe",
    "uuid": "369AA70D-222D-4D97-8865-320951D0862D",
    "channelTitle": "Sydsvenskan",
    "title": "Dygnet runt"
  }
]
