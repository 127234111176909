[
  {
    "channel": "8b21920e-038d-4b79-93cc-2ef632c6d360",
    "uuid": "FA1969FB-E9DD-401B-8E37-1B33AAF11324",
    "channelTitle": "Arbetarbladet",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "8b21920e-038d-4b79-93cc-2ef632c6d360",
    "uuid": "5A6CE10D-3499-4E28-9CEC-F3F903A4F22B",
    "channelTitle": "Arbetarbladet",
    "title": "Kultur & nöje"
  },
  {
    "channel": "8b21920e-038d-4b79-93cc-2ef632c6d360",
    "uuid": "E4F5D8CF-356F-40AA-887C-167AC13AC2C4",
    "channelTitle": "Arbetarbladet",
    "title": "Näringsliv"
  },
  {
    "channel": "8b21920e-038d-4b79-93cc-2ef632c6d360",
    "uuid": "A2B91F4F-E4F3-4B44-9051-4FA338201625",
    "channelTitle": "Arbetarbladet",
    "title": "Trafik"
  },
  {
    "channel": "8b21920e-038d-4b79-93cc-2ef632c6d360",
    "uuid": "3C7B8CB7-2573-454E-84FF-C2E13C00C075",
    "channelTitle": "Arbetarbladet",
    "title": "Opinion"
  },
  {
    "channel": "8b21920e-038d-4b79-93cc-2ef632c6d360",
    "uuid": "43DA94C6-22D7-4A22-AF1E-1131FAB74685",
    "channelTitle": "Arbetarbladet",
    "title": "Sport"
  },
  {
    "channel": "8b21920e-038d-4b79-93cc-2ef632c6d360",
    "uuid": "3E8536D1-CC36-4F58-B64C-DA7CF272E06D",
    "channelTitle": "Arbetarbladet",
    "title": "Brynäs IF Dam"
  },
  {
    "channel": "8b21920e-038d-4b79-93cc-2ef632c6d360",
    "uuid": "1BBE0CC6-31D5-4DF9-9D57-A0BC307B1569",
    "channelTitle": "Arbetarbladet",
    "title": "Brynäs IF Herr"
  },
  {
    "channel": "8b21920e-038d-4b79-93cc-2ef632c6d360",
    "uuid": "92271DAF-D504-4743-8478-DB3666827696",
    "channelTitle": "Arbetarbladet",
    "title": "Gefle IF"
  },
  {
    "channel": "8b21920e-038d-4b79-93cc-2ef632c6d360",
    "uuid": "C4C773E8-C5D3-4ABB-8001-ADC763CC26FA",
    "channelTitle": "Arbetarbladet",
    "title": "SAIK Bandy"
  },
  {
    "channel": "8b21920e-038d-4b79-93cc-2ef632c6d360",
    "uuid": "43320BDD-A3C8-4EC1-BFE2-E5262A449089",
    "channelTitle": "Arbetarbladet",
    "title": "Sandvikens IF"
  },
  {
    "channel": "8b21920e-038d-4b79-93cc-2ef632c6d360",
    "uuid": "CCC60EA9-F614-4DFB-89E6-CE613AFEE74A",
    "channelTitle": "Arbetarbladet",
    "title": "Strömsbergs IF"
  },
  {
    "channel": "8b21920e-038d-4b79-93cc-2ef632c6d360",
    "uuid": "1C08ADE3-575B-42BE-A730-5B1A10B4CFC8",
    "channelTitle": "Arbetarbladet",
    "title": "Strömsbro IF Hockey"
  },
  {
    "channel": "8b21920e-038d-4b79-93cc-2ef632c6d360",
    "uuid": "43E4D56F-8F6D-460E-A737-D64F25B9F2EB",
    "channelTitle": "Arbetarbladet",
    "title": "Gävle kommun"
  },
  {
    "channel": "8b21920e-038d-4b79-93cc-2ef632c6d360",
    "uuid": "2031916F-ACDF-40B6-A183-CE9A4C31349B",
    "channelTitle": "Arbetarbladet",
    "title": "Hofors kommun"
  },
  {
    "channel": "8b21920e-038d-4b79-93cc-2ef632c6d360",
    "uuid": "B981AE53-35BD-4E16-9609-AF785E621E4E",
    "channelTitle": "Arbetarbladet",
    "title": "Ockelbo kommun"
  },
  {
    "channel": "8b21920e-038d-4b79-93cc-2ef632c6d360",
    "uuid": "FF705F30-DD39-41E5-8451-ACA3DDACB72F",
    "channelTitle": "Arbetarbladet",
    "title": "Sandvikens kommun"
  },
  {
    "channel": "8b21920e-038d-4b79-93cc-2ef632c6d360",
    "uuid": "A083C507-4226-4AB3-9AFA-230C0C7E4FDF",
    "channelTitle": "Arbetarbladet",
    "title": "Tierps kommun"
  },
  {
    "channel": "8b21920e-038d-4b79-93cc-2ef632c6d360",
    "uuid": "70112A33-628E-412D-8F8A-591F804ACDFE",
    "channelTitle": "Arbetarbladet",
    "title": "Älvkarleby kommun"
  },
  {
    "channel": "8b21920e-038d-4b79-93cc-2ef632c6d360",
    "uuid": "FDE98145-3F7B-4552-876B-961636B16631",
    "channelTitle": "Arbetarbladet",
    "title": "GGIK innebandy"
  },
  {
    "channel": "8b21920e-038d-4b79-93cc-2ef632c6d360",
    "uuid": "5DD25915-6C82-4927-B0FE-1396425020FC",
    "channelTitle": "Arbetarbladet",
    "title": "Blåljus & krim"
  }
]
