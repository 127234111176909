[
  {
    "channel": "86142e97-ac68-48e9-925b-21f26186d988",
    "uuid": "9DF7C325-20A2-4295-B562-5D0CE9F2D5EF",
    "channelTitle": "Ljusdals-Posten",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "86142e97-ac68-48e9-925b-21f26186d988",
    "uuid": "FFFEF650-591A-4B86-AC69-ADB800250EF7",
    "channelTitle": "Ljusdals-Posten",
    "title": "Näringsliv"
  },
  {
    "channel": "86142e97-ac68-48e9-925b-21f26186d988",
    "uuid": "C8142319-D0F8-4AB8-9BD7-7560837D2A84",
    "channelTitle": "Ljusdals-Posten",
    "title": "Opinion"
  },
  {
    "channel": "86142e97-ac68-48e9-925b-21f26186d988",
    "uuid": "A0F0A68C-CFC3-4C60-9C1B-903364D4D24C",
    "channelTitle": "Ljusdals-Posten",
    "title": "Sport"
  },
  {
    "channel": "86142e97-ac68-48e9-925b-21f26186d988",
    "uuid": "FCA8918B-2321-4C2A-A020-E80B279158AB",
    "channelTitle": "Ljusdals-Posten",
    "title": "Trafik"
  },
  {
    "channel": "86142e97-ac68-48e9-925b-21f26186d988",
    "uuid": "F3D25BD0-8167-4AEC-9747-C674A4948459",
    "channelTitle": "Ljusdals-Posten",
    "title": "Ljusdals kommun"
  }
]
