[
  {
    "channel": "d61de145-70b2-475f-859c-62612593cfbc",
    "uuid": "B76626E5-2B2C-41CE-A76E-66EF90AD9313",
    "channelTitle": "Barometern-OT",
    "title": "Stora nyhetshändelser Barometern"
  },
  {
    "channel": "d61de145-70b2-475f-859c-62612593cfbc",
    "uuid": "F31C072C-3255-41C7-9849-D3E5A07FA125",
    "channelTitle": "Barometern-OT",
    "title": "Stora nyhetshändelser OT"
  },
  {
    "channel": "d61de145-70b2-475f-859c-62612593cfbc",
    "uuid": "FE9200C9-F558-41EB-B116-67FA4F291C71",
    "channelTitle": "Barometern-OT",
    "title": "Sport Barometern"
  },
  {
    "channel": "d61de145-70b2-475f-859c-62612593cfbc",
    "uuid": "95AC3B67-728E-43B8-A850-1650459A9629",
    "channelTitle": "Barometern-OT",
    "title": "Sport OT"
  }
]
