[
  {
    "channel": "01b2789d-453d-4f47-9ed2-6b0edd6925fa",
    "uuid": "546478CA-EAD7-4A23-B385-1D9B2BFF236E",
    "channelTitle": "Blekinge läns tidning",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "01b2789d-453d-4f47-9ed2-6b0edd6925fa",
    "uuid": "61CFEF17-A7C5-482E-809B-F702A71AEA52",
    "channelTitle": "Blekinge läns tidning",
    "title": "Sport"
  }
]
