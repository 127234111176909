[
  {
    "channel": "250f1b71-0763-435b-867c-6ee9028dbe3c",
    "uuid": "B4765CA9-EF7F-4121-AE76-C0E52DB38943",
    "channelTitle": "VLT",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "250f1b71-0763-435b-867c-6ee9028dbe3c",
    "uuid": "54771472-E645-432A-B3A2-A1C53C78CA8F",
    "channelTitle": "VLT",
    "title": "Näringsliv"
  },
  {
    "channel": "250f1b71-0763-435b-867c-6ee9028dbe3c",
    "uuid": "F0201062-1C0A-4AFE-8182-01401DF93ED9",
    "channelTitle": "VLT",
    "title": "Trafik"
  },
  {
    "channel": "250f1b71-0763-435b-867c-6ee9028dbe3c",
    "uuid": "9F2DC914-6976-4650-8D25-EE61C2FF20B8",
    "channelTitle": "VLT",
    "title": "Kultur & nöje"
  },
  {
    "channel": "250f1b71-0763-435b-867c-6ee9028dbe3c",
    "uuid": "C124D968-FBE5-4D63-8323-93E84B29EB8A",
    "channelTitle": "VLT",
    "title": "Opinion"
  },
  {
    "channel": "250f1b71-0763-435b-867c-6ee9028dbe3c",
    "uuid": "B19AD529-0207-4931-9508-83836D0A2830",
    "channelTitle": "VLT",
    "title": "Sport"
  },
  {
    "channel": "250f1b71-0763-435b-867c-6ee9028dbe3c",
    "uuid": "EEE01939-8D65-40A0-BE4F-7C0750D4F079",
    "channelTitle": "VLT",
    "title": "Västerås SK Bandy"
  },
  {
    "channel": "250f1b71-0763-435b-867c-6ee9028dbe3c",
    "uuid": "C5B2C908-FDCD-4E79-8050-891557FC64A9",
    "channelTitle": "VLT",
    "title": "Västerås SK Fotboll"
  },
  {
    "channel": "250f1b71-0763-435b-867c-6ee9028dbe3c",
    "uuid": "0F860F19-808C-4A2D-A61E-98CB33D0B6ED",
    "channelTitle": "VLT",
    "title": "VästeråsIrsta"
  },
  {
    "channel": "250f1b71-0763-435b-867c-6ee9028dbe3c",
    "uuid": "EE7A314A-FF85-4604-B8EE-F822D5EE1533",
    "channelTitle": "VLT",
    "title": "Västerås Rönnby"
  },
  {
    "channel": "250f1b71-0763-435b-867c-6ee9028dbe3c",
    "uuid": "801F44F0-3F1A-488B-9EF5-83DED62407F1",
    "channelTitle": "VLT",
    "title": "VIK Hockey Herr"
  },
  {
    "channel": "250f1b71-0763-435b-867c-6ee9028dbe3c",
    "uuid": "7E81197C-3672-44EB-8B8C-42B677730295",
    "channelTitle": "VLT",
    "title": "Arboga kommun"
  },
  {
    "channel": "250f1b71-0763-435b-867c-6ee9028dbe3c",
    "uuid": "27F75857-D490-4C78-88C1-E6B86ECB714F",
    "channelTitle": "VLT",
    "title": "Fagersta kommun"
  },
  {
    "channel": "250f1b71-0763-435b-867c-6ee9028dbe3c",
    "uuid": "63921076-5305-42AA-BF8F-88BC4EA340AA",
    "channelTitle": "VLT",
    "title": "Hallstahammars kommun"
  },
  {
    "channel": "250f1b71-0763-435b-867c-6ee9028dbe3c",
    "uuid": "C6208737-9DA4-4151-8A6F-35C6DC6EECFC",
    "channelTitle": "VLT",
    "title": "Kungsörs kommun"
  },
  {
    "channel": "250f1b71-0763-435b-867c-6ee9028dbe3c",
    "uuid": "E248E668-FADC-4061-BF00-A65E905BEBE4",
    "channelTitle": "VLT",
    "title": "Köpings kommun"
  },
  {
    "channel": "250f1b71-0763-435b-867c-6ee9028dbe3c",
    "uuid": "D94E8CB4-97DB-4BD6-83F1-52CEDA4DD99B",
    "channelTitle": "VLT",
    "title": "Norbergs kommun"
  },
  {
    "channel": "250f1b71-0763-435b-867c-6ee9028dbe3c",
    "uuid": "09C79702-0DB0-4B88-925C-74E95ABAD9ED",
    "channelTitle": "VLT",
    "title": "Sala kommun"
  },
  {
    "channel": "250f1b71-0763-435b-867c-6ee9028dbe3c",
    "uuid": "75B0FEC5-1A63-4F55-AF6F-09E8E4462408",
    "channelTitle": "VLT",
    "title": "Skinnskattebergs kommun"
  },
  {
    "channel": "250f1b71-0763-435b-867c-6ee9028dbe3c",
    "uuid": "4ED3B571-0936-410D-B304-E5F05E2BC8F9",
    "channelTitle": "VLT",
    "title": "Surahammars kommun"
  },
  {
    "channel": "250f1b71-0763-435b-867c-6ee9028dbe3c",
    "uuid": "DEEC43B6-3336-48CE-9A23-97905EE062E9",
    "channelTitle": "VLT",
    "title": "Västerås kommun"
  },
  {
    "channel": "250f1b71-0763-435b-867c-6ee9028dbe3c",
    "uuid": "8ea2cba2-0b2b-4df2-9266-6cc53de75fbc",
    "channelTitle": "VLT",
    "title": "Västmanland"
  },
  {
    "channel": "250f1b71-0763-435b-867c-6ee9028dbe3c",
    "uuid": "a57ecccc-a4d5-49a2-8742-3b555109ebab",
    "channelTitle": "VLT",
    "title": "Blåljus"
  },
  {
    "channel": "250f1b71-0763-435b-867c-6ee9028dbe3c",
    "uuid": "557f3d37-6cec-44e1-b797-b7d13b5286ca",
    "channelTitle": "VLT",
    "title": "Politik"
  }
]
