const Nlt = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>NLT</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#2376AF" />
      <path
        d="M18.972 11.13v.42c-.94.086-1.067.2-1.076 1.639v5.395c0 .868.073 1.287.281 1.466.208.18.611.21 1.003.21.562 0 1.15-.06 1.406-.434.208-.285.403-.793.6-1.512l.39.09a81.937 81.937 0 0 1-.357 2.036l1.553-.001.08-.003-.043.004c1.08-.09 1.179-.21 1.179-1.825v-6.878h-.418c-1.056 0-1.375.18-1.572.448-.147.21-.295.599-.442 1.316h-.368c.049-.986.122-1.973.147-2.751h.221c.172.359.295.389.639.389h5.06c.318 0 .392-.09.589-.389h.196c.025.628.074 1.765.148 2.721l-.369.03c-.123-.777-.27-1.166-.454-1.39-.21-.284-.602-.374-1.364-.374h-.638v6.878c0 1.615.098 1.735 1.228 1.825v.418h-11.19v-.419c.986-.086 1.116-.2 1.125-1.639v-5.424c0-1.256-.059-1.609-.544-1.746l.116-.007c-.71.03-1.143.246-1.328.785-.154.477-.355 1.169-.694 2.938l-1.142 5.893h-.88l-4.63-7.523h-.031l-.602 3.2c-.34 1.77-.386 2.462-.417 2.862-.046.63.355.815 1.374.861l-.093.493H3.812l.093-.493c.71-.03 1.142-.246 1.327-.784.155-.477.355-1.17.71-2.94l.602-3.046c.355-1.8.062-2.154-1.204-2.246l.093-.492h2.562l4.584 7.339h.03l.602-3.124c.34-1.77.386-2.461.417-2.861.046-.631-.355-.816-1.373-.862l.092-.492h6.625Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Nlt;
