const Nt = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>NT</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#00497F" />
      <g fill="#FFF">
        <path d="M22.7 22.27v-3.85h-5.586v3.85h.144c.029-2.099.76-3.671 1.723-3.671.058 0 .13 0 .202.014v8.833h-1.006v.179h3.475v-.18h-1.005v-8.832c.072-.014.143-.014.2-.014.963 0 1.681 1.531 1.71 3.67h.143Zm-6.78-3.661v-.19h-2.64v.23c.562.054.9.312 1.068.84.112.352.14.622.14 1.259v3.492l-2.724-5.82H9.28v.175h.113c.267 0 .547.04.744.135.309.122.393.339.393.989V23.875l-.014 1.273c0 .839-.084 1.272-.31 1.61-.167.244-.42.38-.926.515v.176h2.696v-.19a1.277 1.277 0 0 1-.955-.541c-.14-.204-.196-.433-.238-.894a7.285 7.285 0 0 1-.056-.717V19.557l3.72 8.068h.252V20.761c0-.379.057-.934.127-1.232a.933.933 0 0 1 .169-.379c.21-.298.603-.541.842-.541h.084ZM15.96 4.375c.498 0 .902.394.902.88a.88.88 0 0 1-.592.824l.01.59h1.155c.106-.244.12-.43.128-.546.016-.22-.054-.678-.37-.728.373.036.621.477.59.921a1.627 1.627 0 0 1-.06.354h1.129a.34.34 0 0 1 .598.21c0 .183-.152.33-.34.33a.34.34 0 0 1-.258-.119H17.54c-.196.341-.558.765-1.229 1.246l.079 4.383c.013.933.7.989 1.158.909 1.497-.261 2.35-.901 2.793-1.643l.188.052a4.143 4.143 0 0 1-.897 1.71 3.99 3.99 0 0 1-1.96.494c-.616-.001-1.302-.123-2.063-.405a9.479 9.479 0 0 0-.662-.218c.309-.08.574-.322.582-.899l.062-3.454.008-.455c-1.948 1.294-2.236 1.901-2.237 2.626 0 1.01.615 1.614 1.166 1.973l-.006.023c-.69-.383-1.383-1.074-1.382-2.23.001-.72.336-1.387 2.467-2.774l.024-1.338h-2.586a.34.34 0 0 1-.597-.21c0-.182.151-.33.339-.33.104 0 .196.046.259.119h2.592l.011-.59a.88.88 0 0 1-.591-.826c0-.606.589-.879.902-.879Zm-4.383 7.63c.334.557.899 1.058 1.797 1.383l-.048-.002c-.467 0-.86.074-1.192.198a4.215 4.215 0 0 1-.744-1.528Zm5.61-4.914h-.899c.006.264.01.555.016.859.421-.32.7-.609.883-.859Zm-1.227-2.24a.408.408 0 0 0-.413.403c0 .222.185.402.413.402.228 0 .412-.18.412-.402a.407.407 0 0 0-.412-.403Zm-.261 10.993c3.07 1.257 4.749-.288 5.52-1.342l-.024-.013c-.784.83-2.33 1.363-4.918.303-3.07-1.256-4.75.288-5.521 1.343l.023.012c.784-.83 2.33-1.363 4.92-.303" />
      </g>
    </g>
  </svg>
);

export default Nt;
