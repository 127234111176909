const months = [
  'januari',
  'februari',
  'mars',
  'april',
  'maj',
  'juni',
  'juli',
  'augusti',
  'september',
  'oktober',
  'november',
  'december',
];

class JallaDate extends Date {
  /** @param {ConstructorParameters<DateConstructor> } [args] */
  constructor (...args) {
    super(...args);

    /**
     * Extending built-ins seems to cause problems for our webpack transpilation when using storybook, hence we set the prototype explicitly.
     * {@link https://github.com/Microsoft/TypeScript-wiki/blob/main/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work}
     */
    Object.setPrototypeOf(this, JallaDate.prototype);
  }

  startOfDay () {
    const d = new Date(this.getTime());
    d.setHours(0, 0, 0, 0);
    return d;
  }

  endOfDay () {
    const d = new Date(this.getTime());
    d.setHours(23, 59, 59, 999);
    return d;
  }

  isToday () {
    const now = new Date();
    return (now.getTime() >= this.startOfDay().getTime() && now.getTime() <= this.endOfDay().getTime());
  }

  isThisWeek () {
    return this.nowStartOfWeek().getTime() <= this.getTime() && this.nowEndOfWeek().getTime() >= this.getTime();
  }

  isThisMonth () {
    return this.nowStartOfMonth().getTime() <= this.getTime() && this.nowEndOfMonth().getTime() >= this.getTime();
  }

  nowStartOfWeek (useThis = false) {
    const date = useThis ? new Date(this.getTime()) : new Date();
    const startOfWeek = date.getDate() - [6, 0, 1, 2, 3, 4, 5][date.getDay()];
    date.setDate(startOfWeek);
    date.setHours(0, 0, 0, 0);
    return date;
  }

  nowEndOfWeek (useThis = false) {
    const date = useThis ? new Date(this.getTime()) : new Date();
    const endOfWeek = date.getDate() + [0, 6, 5, 4, 3, 2, 1][date.getDay()];
    date.setDate(endOfWeek);
    date.setHours(23, 59, 59, 999);
    return date;
  }

  nowStartOfMonth (useThis = false) {
    const date = useThis ? new Date(this.getTime()) : new Date();
    date.setDate(1);
    date.setHours(0, 0, 0, 0);
    return date;
  }

  nowEndOfMonth (useThis = false) {
    const date = useThis ? new Date(this.getTime()) : new Date();
    date.setMonth(date.getMonth() + 1);
    date.setDate(0);
    date.setHours(23, 59, 59, 999);
    return date;
  }

  format (stringFormat, localeString = 'sv-SE') {
    if (stringFormat === 'YYYY-MM-DD') {
      return `${this.getFullYear()}-${String(this.getMonth() + 1).padStart(2, '0')}-${String(this.getDate()).padStart(2, '0')}`;
    }

    if (stringFormat === 'D MMMM YYYY HH:mm') {
      return `${this.getDate()} ${months[this.getMonth()]} ${this.getFullYear()} ${String(this.getHours()).padStart(2, '0')}:${String(this.getMinutes()).padStart(2, '0')}`;
    }

    if (stringFormat === 'D MMMM') {
      return `${this.getDate()} ${months[this.getMonth()]}`;
    }

    if (stringFormat === 'D MMMM YYYY') {
      return `${this.getDate()} ${months[this.getMonth()]} ${this.getFullYear()}`;
    }

    if (stringFormat === 'MMMM YYYY') {
      return `${months[this.getMonth()]} ${this.getFullYear()}`;
    }

    if (stringFormat === 'HH:mm') {
      return `${String(this.getHours()).padStart(2, '0')}:${String(this.getMinutes()).padStart(2, '0')}`;
    }

    if (stringFormat === 'HH:00') {
      return `${String(this.getHours()).padStart(2, '0')}:00`;
    }

    if (stringFormat === 'MMMM') {
      const formatter = new Intl.DateTimeFormat(localeString, {
        weekday: 'long',
        day: 'numeric',
        year: 'numeric',
        month: 'long'
      });
      const dateParts = formatter.formatToParts(this);
      return dateParts.filter(element => element.type === 'month')[0].value.toLowerCase();
    }

    if (stringFormat === 'DD') {
      const formatter = new Intl.DateTimeFormat(localeString, {
        weekday: 'long',
        day: 'numeric',
        year: 'numeric',
        month: 'long'
      });
      const dateParts = formatter.formatToParts(this);
      return dateParts.filter(element => element.type === 'weekday')[0].value.toLowerCase();
    }

    if (stringFormat === 'HHmm') {
      return `${String(this.getHours()).padStart(2, '0')}${String(this.getMinutes()).padStart(2, '0')}`;
    }

    return null;
  }

  addDays (days) {
    this.setDate(this.getDate() + days);
    return this;
  }

  isAfter (dateTime) {
    return this.getTime() > new Date(dateTime).getTime();
  }

  subtractHours (hours) {
    this.setHours(this.getHours() - hours);
    return this;
  }

  subtractDays (days) {
    this.setDate(this.getDate() - days);
    return this;
  }

  subtractYears (years) {
    this.setFullYear(this.getFullYear() - years);
    return this;
  }

  isoWeek () {
    const date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
  }

  weekOfMonth () {
    const date = this.getDate();
    return Math.ceil((date) / 7);
  }

  isDayOfMonth (dayString) {
    if (dayString === 'first') {
      return this.getDate() === 1;
    }
    if (dayString === 'second') {
      return this.getDate() === 2;
    }
    if (dayString === 'third') {
      return this.getDate() === 3;
    }
    if (dayString === 'last') {
      return this.getDate() === new Date(this.getFullYear(), this.getMonth() + 1, 0).getDate();
    }
    if (dayString === 'secondLast') {
      return this.getDate() === new Date(this.getFullYear(), this.getMonth() + 1, 0).getDate() - 1;
    }
    if (dayString === 'thirdLast') {
      return this.getDate() === new Date(this.getFullYear(), this.getMonth() + 1, 0).getDate() - 2;
    }
  }

  isValid () {
    return !isNaN(this.getTime());
  }

  minute (minute = 0) {
    const tempDate = new Date(this.getTime());
    tempDate.setMinutes(minute, 0, 0);
    return tempDate;
  }
}

// @ts-ignore
const jallaDate = (...args) => new JallaDate(...args);

module.exports = { jallaDate, JallaDate };
