[
  {
    "channel": "24c7d6cc-0d99-43a4-98b8-0cad34faef78",
    "uuid": "957C4D36-85EA-4A5D-ACA4-7A3A0B6CE35C",
    "channelTitle": "VN",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "24c7d6cc-0d99-43a4-98b8-0cad34faef78",
    "uuid": "6218C120-76F9-4C2C-956F-96B6804180F0",
    "channelTitle": "VN",
    "title": "Kultur & nöje"
  },
  {
    "channel": "24c7d6cc-0d99-43a4-98b8-0cad34faef78",
    "uuid": "BABF5D6E-E544-42BD-8507-20EC84C088CA",
    "channelTitle": "VN",
    "title": "Näringsliv"
  },
  {
    "channel": "24c7d6cc-0d99-43a4-98b8-0cad34faef78",
    "uuid": "0F2AA073-B669-4243-914E-79726E2FECF2",
    "channelTitle": "VN",
    "title": "Opinion"
  },
  {
    "channel": "24c7d6cc-0d99-43a4-98b8-0cad34faef78",
    "uuid": "B03F1831-1B0F-4303-A254-6DB0931427AC",
    "channelTitle": "VN",
    "title": "Trafik"
  },
  {
    "channel": "24c7d6cc-0d99-43a4-98b8-0cad34faef78",
    "uuid": "7B16815F-589D-4D1A-8E68-A38D320EBC8D",
    "channelTitle": "VN",
    "title": "Sport"
  },
  {
    "channel": "24c7d6cc-0d99-43a4-98b8-0cad34faef78",
    "uuid": "6AE8FF65-7753-49D4-A3BC-9E1C2B714EBC",
    "channelTitle": "VN",
    "title": "IFK Värnamo"
  },
  {
    "channel": "24c7d6cc-0d99-43a4-98b8-0cad34faef78",
    "uuid": "BFFF16D0-CFD5-4F1C-A2AB-F26BFD358279",
    "channelTitle": "VN",
    "title": "Gislaveds kommun"
  },
  {
    "channel": "24c7d6cc-0d99-43a4-98b8-0cad34faef78",
    "uuid": "254731B2-46C4-427E-A2BB-D18A323C5F21",
    "channelTitle": "VN",
    "title": "Gnosjö kommun"
  },
  {
    "channel": "24c7d6cc-0d99-43a4-98b8-0cad34faef78",
    "uuid": "F282FB0C-AC36-45B3-B4D6-5B842BB50438",
    "channelTitle": "VN",
    "title": "Vaggeryds kommun"
  },
  {
    "channel": "24c7d6cc-0d99-43a4-98b8-0cad34faef78",
    "uuid": "3E5B7B02-1A7C-4401-B84B-DC98885F5A13",
    "channelTitle": "VN",
    "title": "Värnamo kommun"
  }
]
