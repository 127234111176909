[
  {
    "channel": "9eac0e19-ad2d-443b-a5c0-e598f233fff1",
    "uuid": "E0E6AF52-040A-46F6-B2FA-C7F5065E2A99",
    "channelTitle": "Vaxjöbladet Kronobergaren",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "9eac0e19-ad2d-443b-a5c0-e598f233fff1",
    "uuid": "B868E5D1-EC93-42F6-939D-EF1931C99541",
    "channelTitle": "Vaxjöbladet Kronobergaren",
    "title": "Sport"
  }
]
