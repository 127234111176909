[
  {
    "channel": "36604dc8-3500-4ea7-9f50-80b9d8d83ae5",
    "uuid": "B275DD15-E2E7-4458-AD29-16D5D8AB47A2",
    "channelTitle": "ST",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "36604dc8-3500-4ea7-9f50-80b9d8d83ae5",
    "uuid": "BA3C530B-CD60-4D14-AA07-F926AA974F43",
    "channelTitle": "ST",
    "title": "Kultur & nöje"
  },
  {
    "channel": "36604dc8-3500-4ea7-9f50-80b9d8d83ae5",
    "uuid": "3C34A3ED-4682-439D-AF9B-CA32CBD78F2A",
    "channelTitle": "ST",
    "title": "Näringsliv"
  },
  {
    "channel": "36604dc8-3500-4ea7-9f50-80b9d8d83ae5",
    "uuid": "A0AA26DA-75EE-46B3-A785-135AC388CFD1",
    "channelTitle": "ST",
    "title": "Opinion"
  },
  {
    "channel": "36604dc8-3500-4ea7-9f50-80b9d8d83ae5",
    "uuid": "CB2798C6-A2DD-466D-8A0E-3F4ACD86A47A",
    "channelTitle": "ST",
    "title": "Trafik"
  },
  {
    "channel": "36604dc8-3500-4ea7-9f50-80b9d8d83ae5",
    "uuid": "CF7280AF-C663-4BAA-BF1D-3656CA645298",
    "channelTitle": "ST",
    "title": "Sport"
  },
  {
    "channel": "36604dc8-3500-4ea7-9f50-80b9d8d83ae5",
    "uuid": "8A712E66-1E7C-403F-88B9-7A676355DAB0",
    "channelTitle": "ST",
    "title": "GIF Sundsvall"
  },
  {
    "channel": "36604dc8-3500-4ea7-9f50-80b9d8d83ae5",
    "uuid": "EEDDE382-A897-4B9E-A9EE-423933BDC30A",
    "channelTitle": "ST",
    "title": "Timrå IK"
  },
  {
    "channel": "36604dc8-3500-4ea7-9f50-80b9d8d83ae5",
    "uuid": "F426DD95-EBA5-415A-A28B-2C1E2CBE9C19",
    "channelTitle": "ST",
    "title": "Sundsvall Hockey"
  },
  {
    "channel": "36604dc8-3500-4ea7-9f50-80b9d8d83ae5",
    "uuid": "128CB163-2A5E-4386-99DC-740713B86E9E",
    "channelTitle": "ST",
    "title": "Sundsvalls DFF"
  },
  {
    "channel": "36604dc8-3500-4ea7-9f50-80b9d8d83ae5",
    "uuid": "A3ADC60B-8A2A-453A-A583-BE15BE222EAA",
    "channelTitle": "ST",
    "title": "Sundsvalls kommun"
  },
  {
    "channel": "36604dc8-3500-4ea7-9f50-80b9d8d83ae5",
    "uuid": "E3D1C6C5-38A6-4F9A-87B8-6EE083D8815F",
    "channelTitle": "ST",
    "title": "Timrå kommun"
  },
  {
    "channel": "36604dc8-3500-4ea7-9f50-80b9d8d83ae5",
    "uuid": "3EF0B065-8E18-46B8-BA93-EFCD4797D8B1",
    "channelTitle": "ST",
    "title": "Ånge kommun"
  },
  {
    "channel": "36604dc8-3500-4ea7-9f50-80b9d8d83ae5",
    "uuid": "4FECBDD4-FBF2-4CDB-B81E-C6B145DA6F94",
    "channelTitle": "ST",
    "title": "Brott"
  },
  {
    "channel": "36604dc8-3500-4ea7-9f50-80b9d8d83ae5",
    "uuid": "DF5CE0C5-8594-4DE3-B42C-43E825592208",
    "channelTitle": "ST",
    "title": "Blåljus"
  }
]
