const Op = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>OP</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#3468A7" />
      <path
        d="M26.306 22.012a1.24 1.24 0 1 0 .002-2.482 1.24 1.24 0 0 0-.002 2.482"
        fill="#DB4739"
      />
      <path
        d="M10.197 12.578c3.18 0 5.76 2.092 5.76 4.672 0 2.58-2.58 4.672-5.76 4.672-3.18 0-5.76-2.092-5.76-4.672 0-2.58 2.58-4.672 5.76-4.672Zm13.621.026c2.02 0 3.744 1.463 3.744 3.47 0 2.245-2.43 3.26-3.697 3.26h-2.763v.914c0 .518.311 1.057.852 1.057v.56h-6.565v-.498s.935-.248.935-1.099V14.73c0-.041 0-.088.002-.139v-.164c-.004-.521-.096-1.268-.989-1.268v-.555Zm-13.621.783c-.713 0-.763.623-.764.779v6.108s.026.839.84.839c.713 0 .763-.623.764-.779v-6.159s-.026-.788-.84-.788Zm11.759-.203c-.543 0-.764.422-.764.813v3.565c0 .405.172.904.834.904.543 0 .764-.422.764-.814v-3.565c0-.404-.172-.903-.834-.903ZM8.409 10a1.241 1.241 0 1 1 .002 2.482A1.241 1.241 0 0 1 8.409 10Zm3.608 0a1.241 1.241 0 1 1 .002 2.482A1.241 1.241 0 0 1 12.017 10Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Op;
