[
  {
    "channel": "debfcdec-92f9-421d-a18e-bef30774b3c9",
    "uuid": "2A89143C-48C1-401E-9CA3-4038E3A1DCD5",
    "channelTitle": "Tranås Tidning",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "debfcdec-92f9-421d-a18e-bef30774b3c9",
    "uuid": "C3FBFBE4-2676-4C80-9BF5-0E2B41AD2C6A",
    "channelTitle": "Tranås Tidning",
    "title": "Kultur & nöje"
  },
  {
    "channel": "debfcdec-92f9-421d-a18e-bef30774b3c9",
    "uuid": "931D0352-5B57-453E-B12F-D2C1654916D0",
    "channelTitle": "Tranås Tidning",
    "title": "Näringsliv"
  },
  {
    "channel": "debfcdec-92f9-421d-a18e-bef30774b3c9",
    "uuid": "9400227D-F61F-4FF8-8BEE-4CC6EFF0AB1E",
    "channelTitle": "Tranås Tidning",
    "title": "Opinion"
  },
  {
    "channel": "debfcdec-92f9-421d-a18e-bef30774b3c9",
    "uuid": "E7E790ED-AC80-44FE-9BE3-6AD6ED98599D",
    "channelTitle": "Tranås Tidning",
    "title": "Sport"
  },
  {
    "channel": "debfcdec-92f9-421d-a18e-bef30774b3c9",
    "uuid": "A1ABB8BB-53C8-433C-B273-2A5DC78EA1DF",
    "channelTitle": "Tranås Tidning",
    "title": "Trafik"
  },
  {
    "channel": "debfcdec-92f9-421d-a18e-bef30774b3c9",
    "uuid": "3A9BE499-1959-4C96-A0F0-946A937912AE",
    "channelTitle": "Tranås Tidning",
    "title": "Aneby kommun"
  },
  {
    "channel": "debfcdec-92f9-421d-a18e-bef30774b3c9",
    "uuid": "89E96B95-329F-4506-91ED-068E2CE38C31",
    "channelTitle": "Tranås Tidning",
    "title": "Tranås kommun"
  },
  {
    "channel": "debfcdec-92f9-421d-a18e-bef30774b3c9",
    "uuid": "2CB92D0D-9CBD-4D66-8F72-3C9A9DB04587",
    "channelTitle": "Tranås Tidning",
    "title": "Ydre kommun"
  }
]
