[
  {
    "channel": "8487f84a-e231-4d9c-bd97-abc0bdec3487",
    "uuid": "E740C558-6284-49A1-9257-038B636F312F",
    "channelTitle": "Norrtelje Tidning",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "8487f84a-e231-4d9c-bd97-abc0bdec3487",
    "uuid": "F0F14E6C-86DE-4A93-AC99-64DED3304EE1",
    "channelTitle": "Norrtelje Tidning",
    "title": "Sport"
  },
  {
    "channel": "8487f84a-e231-4d9c-bd97-abc0bdec3487",
    "uuid": "65E3EA89-126F-433D-B5BB-4B0D5DC7DE85",
    "channelTitle": "Norrtelje Tidning",
    "title": "Rospiggarna"
  },
  {
    "channel": "8487f84a-e231-4d9c-bd97-abc0bdec3487",
    "uuid": "A320AAF9-BFD1-4C42-ABD7-D16A36E4BC9B",
    "channelTitle": "Norrtelje Tidning",
    "title": "Näringsliv"
  },
  {
    "channel": "8487f84a-e231-4d9c-bd97-abc0bdec3487",
    "uuid": "1DE0735C-D0FD-4C40-94F9-F389060575DF",
    "channelTitle": "Norrtelje Tidning",
    "title": "Opinion"
  },
  {
    "channel": "8487f84a-e231-4d9c-bd97-abc0bdec3487",
    "uuid": "B9770368-81F5-49AD-83B3-1F53768EBAA8",
    "channelTitle": "Norrtelje Tidning",
    "title": "Kultur & nöje"
  },
  {
    "channel": "8487f84a-e231-4d9c-bd97-abc0bdec3487",
    "uuid": "4C170133-79D2-46E5-AC77-3771170D9531",
    "channelTitle": "Norrtelje Tidning",
    "title": "Trafik"
  },
  {
    "channel": "8487f84a-e231-4d9c-bd97-abc0bdec3487",
    "uuid": "ADE1A3EA-324B-4435-B66D-E045A91AA5C5",
    "channelTitle": "Norrtelje Tidning",
    "title": "Norrtälje kommun"
  },
  {
    "channel": "8487f84a-e231-4d9c-bd97-abc0bdec3487",
    "uuid": "2F5C248D-FE5C-4451-8D16-085B5DBCF53C",
    "channelTitle": "Norrtelje Tidning",
    "title": "Norrtälje"
  },
  {
    "channel": "8487f84a-e231-4d9c-bd97-abc0bdec3487",
    "uuid": "75A8B3BC-EF7B-4ED5-A3DD-9CC743EF2A38",
    "channelTitle": "Norrtelje Tidning",
    "title": "Hallstavik"
  },
  {
    "channel": "8487f84a-e231-4d9c-bd97-abc0bdec3487",
    "uuid": "D0CE2992-CCA2-416C-BAB8-69E50D3F05A6",
    "channelTitle": "Norrtelje Tidning",
    "title": "Rimbo"
  }
]
