[
  {
    "channel": "99f13994-5a99-4e28-80e2-904664e238aa",
    "uuid": "FD1BDFDB-29D6-4D8C-A847-0E85ADCF9837",
    "channelTitle": "Norra Skåne",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "99f13994-5a99-4e28-80e2-904664e238aa",
    "uuid": "3B662904-73B6-4974-A3CA-BAC8F8421F5A",
    "channelTitle": "Norra Skåne",
    "title": "Sport"
  }
]
