[
  {
    "channel": "bea790c3-03a7-44a8-9ccf-693e8e1e72fe",
    "uuid": "08AB5297-F582-4C70-9ADB-C58C8097BCD4",
    "channelTitle": "SKLT",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "bea790c3-03a7-44a8-9ccf-693e8e1e72fe",
    "uuid": "6E7B9200-5120-43A6-AD33-B6CCE41B4E58",
    "channelTitle": "SKLT",
    "title": "Kultur & nöje"
  },
  {
    "channel": "bea790c3-03a7-44a8-9ccf-693e8e1e72fe",
    "uuid": "5500C995-741A-4012-AF22-B251A464FCE8",
    "channelTitle": "SKLT",
    "title": "Näringsliv"
  },
  {
    "channel": "bea790c3-03a7-44a8-9ccf-693e8e1e72fe",
    "uuid": "47174D8C-F1C4-4035-A0D4-6D8B3F80071B",
    "channelTitle": "SKLT",
    "title": "Opinion"
  },
  {
    "channel": "bea790c3-03a7-44a8-9ccf-693e8e1e72fe",
    "uuid": "8FC4622E-7AB6-424A-9BF4-8CF735F4370C",
    "channelTitle": "SKLT",
    "title": "Sport"
  },
  {
    "channel": "bea790c3-03a7-44a8-9ccf-693e8e1e72fe",
    "uuid": "f59f2b5b-00c2-4bfe-9407-9b61d87d1315",
    "channelTitle": "SKLT",
    "title": "Skara FC"
  },
  {
    "channel": "bea790c3-03a7-44a8-9ccf-693e8e1e72fe",
    "uuid": "85dd8988-adf3-456e-a74b-968c3aa2bc38",
    "channelTitle": "SKLT",
    "title": "Skara HF"
  },
  {
    "channel": "bea790c3-03a7-44a8-9ccf-693e8e1e72fe",
    "uuid": "E1B0AE4A-57E3-4D2C-B2E9-BCB7ED8B4FAC",
    "channelTitle": "SKLT",
    "title": "Trafik"
  },
  {
    "channel": "bea790c3-03a7-44a8-9ccf-693e8e1e72fe",
    "uuid": "9E0BDAA1-29A0-47E0-9E5C-A5EEE6F87601",
    "channelTitle": "SKLT",
    "title": "Götene kommun"
  },
  {
    "channel": "bea790c3-03a7-44a8-9ccf-693e8e1e72fe",
    "uuid": "BB007362-D97D-4756-BFE1-73437547FC94",
    "channelTitle": "SKLT",
    "title": "Skara kommun"
  },
  {
    "channel": "bea790c3-03a7-44a8-9ccf-693e8e1e72fe",
    "uuid": "8F77E80A-F57B-4DB3-8A4C-C33F7A859F52",
    "channelTitle": "SKLT",
    "title": "Vara kommun"
  }
]
