[
  {
    "channel": "57c58b41-38c9-4955-b3ef-5fa13fe2d559",
    "uuid": "AE3B7291-0DF2-4941-B47A-F5475141FF2B",
    "channelTitle": "Södra Dalarnes Tidning",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "57c58b41-38c9-4955-b3ef-5fa13fe2d559",
    "uuid": "1117FFBB-6157-4B79-9697-EE07B099AAC3",
    "channelTitle": "Södra Dalarnes Tidning",
    "title": "Kultur & nöje"
  },
  {
    "channel": "57c58b41-38c9-4955-b3ef-5fa13fe2d559",
    "uuid": "88B8B5E1-762D-4CEF-9090-52D4E5437D11",
    "channelTitle": "Södra Dalarnes Tidning",
    "title": "Näringsliv"
  },
  {
    "channel": "57c58b41-38c9-4955-b3ef-5fa13fe2d559",
    "uuid": "B224CD37-4322-4F33-88C6-2140146B2EF9",
    "channelTitle": "Södra Dalarnes Tidning",
    "title": "Krim"
  },
  {
    "channel": "57c58b41-38c9-4955-b3ef-5fa13fe2d559",
    "uuid": "65CBB33A-C51F-47C5-9612-871FECD4C086",
    "channelTitle": "Södra Dalarnes Tidning",
    "title": "Opinion"
  },
  {
    "channel": "57c58b41-38c9-4955-b3ef-5fa13fe2d559",
    "uuid": "D850D80D-666C-45C8-B970-77777B4FE144",
    "channelTitle": "Södra Dalarnes Tidning",
    "title": "Trafik"
  },
  {
    "channel": "57c58b41-38c9-4955-b3ef-5fa13fe2d559",
    "uuid": "BEC26263-4365-4ED7-9A83-6D1E9AB701EC",
    "channelTitle": "Södra Dalarnes Tidning",
    "title": "Sport"
  },
  {
    "channel": "57c58b41-38c9-4955-b3ef-5fa13fe2d559",
    "uuid": "77C3188A-4EE6-4BB5-822C-37F06B438C53",
    "channelTitle": "Södra Dalarnes Tidning",
    "title": "Innebandy"
  },
  {
    "channel": "57c58b41-38c9-4955-b3ef-5fa13fe2d559",
    "uuid": "577B2BE2-DED0-413B-93E6-FEF3BA864FF5",
    "channelTitle": "Södra Dalarnes Tidning",
    "title": "Ishockey"
  },
  {
    "channel": "57c58b41-38c9-4955-b3ef-5fa13fe2d559",
    "uuid": "633A7A62-A973-42A4-898C-D3836160E117",
    "channelTitle": "Södra Dalarnes Tidning",
    "title": "Fotboll"
  },
  {
    "channel": "57c58b41-38c9-4955-b3ef-5fa13fe2d559",
    "uuid": "0E094AFB-5F75-496E-95E0-BA9EB07ED5A8",
    "channelTitle": "Södra Dalarnes Tidning",
    "title": "Längdskidor"
  },
  {
    "channel": "57c58b41-38c9-4955-b3ef-5fa13fe2d559",
    "uuid": "FB6D9AA0-3E6E-4059-AEB6-78390BE4F3AE",
    "channelTitle": "Södra Dalarnes Tidning",
    "title": "Motorsport"
  },
  {
    "channel": "57c58b41-38c9-4955-b3ef-5fa13fe2d559",
    "uuid": "68117F32-54F0-4B21-9ADE-8523037A7DC1",
    "channelTitle": "Södra Dalarnes Tidning",
    "title": "IK Brage"
  },
  {
    "channel": "57c58b41-38c9-4955-b3ef-5fa13fe2d559",
    "uuid": "85A2C6AE-1346-4AE5-B271-E3899E53D916",
    "channelTitle": "Södra Dalarnes Tidning",
    "title": "Leksands IF Herr"
  },
  {
    "channel": "57c58b41-38c9-4955-b3ef-5fa13fe2d559",
    "uuid": "E097BC5A-76B6-459A-8658-E6BBDBA557DA",
    "channelTitle": "Södra Dalarnes Tidning",
    "title": "Hedemora kommun"
  },
  {
    "channel": "57c58b41-38c9-4955-b3ef-5fa13fe2d559",
    "uuid": "5F08790A-0325-4028-99D4-413C4B3EBFDE",
    "channelTitle": "Södra Dalarnes Tidning",
    "title": "Säters kommun"
  },
  {
    "channel": "57c58b41-38c9-4955-b3ef-5fa13fe2d559",
    "uuid": "8F75FA88-D06E-4AEB-B6E8-0255CD2E03C1",
    "channelTitle": "Södra Dalarnes Tidning",
    "title": "Dalarna"
  },
  {
    "channel": "57c58b41-38c9-4955-b3ef-5fa13fe2d559",
    "uuid": "BFF4DEE7-3447-4012-A642-98E60B95B58F",
    "channelTitle": "Södra Dalarnes Tidning",
    "title": "Gustafs"
  },
  {
    "channel": "57c58b41-38c9-4955-b3ef-5fa13fe2d559",
    "uuid": "A2DF9BAA-3F49-4E7E-9B99-79E1FF326B85",
    "channelTitle": "Södra Dalarnes Tidning",
    "title": "Stora Skedvi"
  },
  {
    "channel": "57c58b41-38c9-4955-b3ef-5fa13fe2d559",
    "uuid": "3B31082D-605A-4633-BCBE-81086663229F",
    "channelTitle": "Södra Dalarnes Tidning",
    "title": "Säter (stad)"
  },
  {
    "channel": "57c58b41-38c9-4955-b3ef-5fa13fe2d559",
    "uuid": "B316F382-C75B-41F0-A2D4-B794630E27E4",
    "channelTitle": "Södra Dalarnes Tidning",
    "title": "Hedemora (stad)"
  },
  {
    "channel": "57c58b41-38c9-4955-b3ef-5fa13fe2d559",
    "uuid": "A7FAB6AE-ACC4-4EE4-8ED2-991B76F66D01",
    "channelTitle": "Södra Dalarnes Tidning",
    "title": "Långshyttan"
  },
  {
    "channel": "57c58b41-38c9-4955-b3ef-5fa13fe2d559",
    "uuid": "D4B740F7-9CEC-486D-8D20-49341BDE65E2",
    "channelTitle": "Södra Dalarnes Tidning",
    "title": "Vikmanshyttan"
  },
  {
    "channel": "57c58b41-38c9-4955-b3ef-5fa13fe2d559",
    "uuid": "EEF45C85-CBE9-4659-95E2-AD4D3F2E5008",
    "channelTitle": "Södra Dalarnes Tidning",
    "title": "Dala Husby"
  },
  {
    "channel": "57c58b41-38c9-4955-b3ef-5fa13fe2d559",
    "uuid": "48B4AC7A-B4F4-484E-960A-D2751A3FCABF",
    "channelTitle": "Södra Dalarnes Tidning",
    "title": "Garpenberg"
  }
]
