[
  {
    "channel": "11de602f-230d-4821-8c80-9c605b54a7a3",
    "uuid": "9A32551F-3980-4B07-A6F8-66AC4660FD91",
    "channelTitle": "Bbl/AT",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "11de602f-230d-4821-8c80-9c605b54a7a3",
    "uuid": "75E1333C-D059-49CC-A022-94E2238A715C",
    "channelTitle": "Bbl/AT",
    "title": "Sport"
  },
  {
    "channel": "11de602f-230d-4821-8c80-9c605b54a7a3",
    "uuid": "AFAFD7D8-59AE-4340-B0CD-EBC2FBF7BD6B",
    "channelTitle": "Bbl/AT",
    "title": "Näringsliv"
  },
  {
    "channel": "11de602f-230d-4821-8c80-9c605b54a7a3",
    "uuid": "EC30D0B7-CC66-4ACE-8E9F-1D9199E8BC94",
    "channelTitle": "Bbl/AT",
    "title": "Trafik"
  },
  {
    "channel": "11de602f-230d-4821-8c80-9c605b54a7a3",
    "uuid": "C6737432-0020-433B-99AC-DCF39CDD3237",
    "channelTitle": "Bbl/AT",
    "title": "Kultur & nöje"
  },
  {
    "channel": "11de602f-230d-4821-8c80-9c605b54a7a3",
    "uuid": "BFDDB20D-D1E6-4248-9DAB-28D6EB084B39",
    "channelTitle": "Bbl/AT",
    "title": "Opinion"
  },
  {
    "channel": "11de602f-230d-4821-8c80-9c605b54a7a3",
    "uuid": "17010028-F644-4441-8A8C-BAAC9762D422",
    "channelTitle": "Bbl/AT",
    "title": "Blåljus"
  },
  {
    "channel": "11de602f-230d-4821-8c80-9c605b54a7a3",
    "uuid": "765136CA-AAE0-4F3F-9D1E-EE55A5F78D18",
    "channelTitle": "Bbl/AT",
    "title": "Köping Stars"
  },
  {
    "channel": "11de602f-230d-4821-8c80-9c605b54a7a3",
    "uuid": "8B3AEFC0-9D26-4A33-9E46-376CDE71A672",
    "channelTitle": "Bbl/AT",
    "title": "Arboga kommun"
  },
  {
    "channel": "11de602f-230d-4821-8c80-9c605b54a7a3",
    "uuid": "B05C638A-29C4-4D41-A0AF-A086B67DADF6",
    "channelTitle": "Bbl/AT",
    "title": "Kungsörs kommun"
  },
  {
    "channel": "11de602f-230d-4821-8c80-9c605b54a7a3",
    "uuid": "39621ABA-6296-41FC-9BDD-4C4BD27938F9",
    "channelTitle": "Bbl/AT",
    "title": "Köpings kommun"
  }
]
