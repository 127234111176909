const Jp = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>JP</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#0763AB" />
      <path
        d="M24.46 19.354c1.577 0 3.165.187 3.41.217l.032.004s.178 1.657-1.522 1.657c-.416 0-1.086-.006-1.674-.008h-.492c-.588.002-1.258.008-1.674.008-1.637 0-1.532-1.537-1.522-1.65v-.007l.032-.004a31.21 31.21 0 0 1 3.41-.217zM10.1 10.83v1.502h-.9v6.62c0 1.293-1.287 2.238-2.736 2.241-1.448.004-2.652-.949-2.652-2.241v-1.029h1.94v.878c0 .36.28.66.683.656.39-.005.632-.307.632-.656v-6.469h-.931V10.83h3.962zm4.46.012c1.37 0 2.486 1.027 2.486 2.287v2.047c0 1.26-1.115 2.284-2.485 2.284h-1.177v2.2h.912v1.507H10.33V19.66h.894v-7.318l-.895.002v-1.503h4.232zm4.471-1.967c.517.26.64.703.64.703s.045-.144.021-.267c-.024-.123-.177-.328-.177-.328s.569.015 1.143.49c.575.476.762.821.98 1.289.22.467.182 1.061.182 1.061s.133.04.233-.258-.013-1.1-.013-1.1.104.297.48 1.022c.374.726 1.104 1.468 1.375 1.898.27.429.265.678.307 1.137.042.459-.194 1.047-.194 1.047s.232-.165.31-.364c.079-.2.125-.696.125-.696s.15.285.226.725c.077.44-.346 1.287-.346 1.287l.176.814h.586l.002.015c.01.087.072.532.3.714.154.12.684.232 1.114.28 1.177.133 1.663.851 1.663.851h-.008l-.105-.017a26.052 26.052 0 0 0-3.352-.237h-.235a26.073 26.073 0 0 0-3.588.237l-.084.013-.029.004s.486-.718 1.663-.85c.43-.049.96-.16 1.113-.281.23-.182.29-.627.3-.714l.002-.015.427-.002c-.248-1.098-.434-1.415-.684-2.112-.415-1.158-1.393-2.457-2.903-4.044-1.509-1.588-2.276-1.644-2.276-1.644s.401.296 1.38 1.265l.296.296.328.334c1.435 1.476 2.437 2.934 2.777 3.772.294.724.432 1.163.466 1.274l.009.028s-.577-.028-.87-.033c-.488-.009-1.102.196-1.102.196s.078-.199.324-.416c.246-.216.754-.34.754-.34l-.028-.007c-.137-.035-.75-.221-1.348-.87-.677-.737-1.009-1.323-1.009-1.323s.205.145.497.183c.261.034.593-.018.593-.018s-.184-.06-.737-.334l-.283-.142-.162-.083c-.902-.468-1.027-1.002-1.027-1.002s.149.134.558.157c.41.023.56-.144.56-.144s-1.284-.129-1.791-.679c-.508-.55-.655-.812-.94-1.844-.27-.982.106-2.38.142-2.512.003-.01-.178.875 1.239 1.584zm-4.979 3.468h-.668v3.564h.668c.455 0 .825-.34.825-.759v-2.046c0-.418-.37-.76-.825-.76z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Jp;
