[
  {
    "channel": "f62b0e9a-96e3-4128-9899-6624e09c0f91",
    "uuid": "E26E1CCF-0EA5-4C76-9C12-7665673B949D",
    "channelTitle": "Ystads Allehanda",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "f62b0e9a-96e3-4128-9899-6624e09c0f91",
    "uuid": "FE12480F-4020-4D32-B248-FB24D57F54BF",
    "channelTitle": "Ystads Allehanda",
    "title": "Sport"
  }
]
