const Ut = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>UT</title>
    <g fill="none">
      <path fill="#005CA9" d="M32 0H0v32h32V0Z"/>
      <path fill="#fff" fill-rule="evenodd" d="M24.1 14h.86v-3.33l-.14-.1v3.3h-.85l.14.13Zm.34-.52v-3.3l-.14-.14v3.3h-.86l.14.14h.86Zm-.52-3.83h-8.06v3.31h.87l.35-2.44h1.78v.38h-1.45l-.35 2.44h-.8l.13.14h.79l.35-2.44h1.33v.38h-1l-.35 2.44h-.73l.14.14h.7l.36-2.44h.88v14.82l-1.02.2v.59h4.12V11.56h.89l.2 1.4h.87V9.65Zm-2.63 16.8-.38-.07V10.51h1.78l.06.38h-1.46v15.56Zm.53-15.03v15.14l-.39-.08V11.04h1.34l.06.38h-1.01Zm1.02 16.65h-4.08l.13.14h4.09v-.58l-.14-.13v.57Zm-.38-.96-.14-.13v.57h-4.09l.14.14h4.09v-.58Z" clip-rule="evenodd"/>
      <path fill="#fff" fill-rule="evenodd" d="M14.5 20.95c.56-.8.83-2 .83-3.67V5.08l.95-.24v-.46l-.14-.14v.49l-.95.23v12.32c0 3.27-1.04 4.73-3.37 4.73-.78 0-1.56-.3-1.65-.33.08.05.81.47 1.65.47 1.23 0 2.1-.4 2.68-1.2Zm.31-3.67V4.67l.95-.24v-.59h-3v.6l1.03.23v.3l-.6-.12.18.18.42.08v11.72c0 2.33-.16 3.78-1.68 3.78a1.8 1.8 0 0 1-.37-.03 9.25 9.25 0 0 1-.26-2.78V5.5h.01l1.02-.2v-.4l-.14-.13v.42h-.01l-1.02.2V17.8c0 1.05.02 2.05.25 2.75l-.15-.06-.07-.1c-.38-.63-.41-1.78-.41-3.11V5.06l1.02-.2v-.48l-.14-.14v.5l-1.02.2v12.34c0 1.28.03 2.4.38 3.08-.7-.51-.76-1.75-.76-3.53V4.63l1.02-.2v-.59h-4.1v.6l1.02.19v.34l-.6-.12.17.17.43.09v12.17c0 3.15 1 4.36 3.22 4.36 2.27 0 3.21-1.16 3.21-4.36ZM16.8 4.9l-.14-.14v.38l-.95.23V17.3c0 2.35-.62 5.15-3.54 5.15a5.06 5.06 0 0 1-1.37-.18c.04.02.53.32 1.36.32 3.05 0 3.69-2.88 3.7-5.3V5.49l.94-.24V4.9Z" clip-rule="evenodd"/>
    </g>
  </svg>
);

export default Ut;
