const At = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>AT</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#008DD0" />
      <path
        d="M28.156 11.648c.157 0 .344.021.344.23-.515 1.556-1.16 3.433-1.36 3.634-.042.077-.287.147-.414.02-.21-.211.017-.265-.826-1.33-1.014-1.183-1.32-1.087-1.596-.617-.25.37-1.286 2.88-2.326 5.525l-.25.636c-1.118 2.867-2.168 5.706-2.168 5.998-.07.638.283.63.449.796.13.13.079.277-.078.277h-4.737c-.156 0-.27 0-.27-.157.025-.16.492-.363.786-.657.479-.5.646-.778.952-1.364.053-.126.363-.897.796-1.978l.208-.52.95-2.374.252-.63c1.052-2.633 2.104-5.27 2.209-5.53.019-.052.088-.165-.044-.297-.17-.125-.388-.091-.755-.014-.27.075-.649.205-1.091.444-.687.385-1.128.651-1.807 1.198-.04.028-.08.061-.12.097l-.123.108c-.163.14-.322.245-.454.058-.108-.237.062-.519.268-.999.17-.409.954-2.39 1.1-2.537l.036-.017h10.07ZM18.064 5.156c.324 0 .362.082.386.187 0 .263-.175.228-.386.353-.168.093-.324.183-.45.349-.131.16-.183.405-.22.618-.04.33-.347 2.846-.667 5.402l-.08.64c-.32 2.548-.63 4.959-.675 5.088-.082.738-.12 1.027-.02 1.546.052.301.19.49.426.77.086.148-.003.216-.176.216h-3.869c-.125-.01-.243.033-.285-.156 0-.228.225-.182.517-.472a.667.667 0 0 0 .218-.316c.095-.26.774-3.372.596-3.517-.07-.144-.204-.093-.303-.11l-3.95-.007s-.128-.008-.19.014c-.064.071-.035.033-.064.071-.028.039-.858 1.193-1.066 1.47-.22.29-.991 1.467-1.06 1.871-.037.264-.021.292 0 .478.038.15.115.274.242.4.1.1.034.193-.22.193h-2.89c-.167 0-.315.002-.361-.095-.063-.134.047-.24.399-.43.35-.192.545-.484.928-.92.236-.234 7.31-10.739 7.844-11.536l.022-.033c.136-.197.178-.293.303-.5.09-.161.13-.237.189-.388.077-.229.077-.259-.027-.517 0 0-.061-.136-.15-.218l-.135-.121c-.25-.213-.024-.33.092-.33h5.082ZM14.492 7.62c-.199.063-1.259 1.627-1.95 2.669-.378.521-2.373 3.386-2.425 3.708-.021.197.121.2.394.2.353.006.627.009.85.01h.43c.123 0 .229-.002.331-.003l.202-.001h.328c.081 0 .17 0 .268.002.463 0 .743.056.794-.146.033-.226.234-1.532.434-2.906l.079-.55c.209-1.465.39-2.853.337-2.935-.03-.07-.072-.048-.072-.048Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default At;
