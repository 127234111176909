[
  {
    "channel": "111c947b-6244-4334-abe0-64b58733223c",
    "uuid": "E013F58E-B09C-47C9-8576-325EBAF2632B",
    "channelTitle": "Kalmar läns tidning",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "111c947b-6244-4334-abe0-64b58733223c",
    "uuid": "766F4325-C91B-43BF-984D-B5CA083DFC87",
    "channelTitle": "Kalmar läns tidning",
    "title": "Sport"
  }
]
