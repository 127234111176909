const Trt = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>TRT</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#0280C9" />
      <path
        d="m28.344 8.938-.01 3.586s-.064-.067-.177-.18l-.155-.156c-.558-.557-1.689-1.667-2.388-2.23-.309-.202-.682-.202-.81.098l-.029 11.648c.318.342.855.941 1.13 1.327-.323-.004-.72-.005-1.148-.006h-.438c-1.408.002-2.992.014-3.427.016h-.116c.385-.507.823-.878 1.165-1.351l.028-11.615c-.19-.279-.457-.213-.767-.094-.94.922-1.773 1.686-2.733 2.567l.009-3.586 9.866-.024Zm-10.08 4.61 2.287 1.953-1.49 1.624-.019-.008-.14-.055-.57-.228-.279-.11-.918-.366s-.767.635-1.25 1.17l-.015 4.188c.316.339.85.933 1.124 1.315a93.665 93.665 0 0 0-.672-.005h-1.094c-1.255.002-2.577.012-3.024.015h-.157c.382-.502.818-.87 1.158-1.339l.027-6.383-.09-.097c-.336-.367-.848-.95-1.088-1.284.248.003.509.004.773.005h.795c.917-.002 1.758-.011 2.107-.014l.17-.001-.008 1.885 2.373-2.265Zm-4.733-4.61-.009 3.586-.115-.118-.31-.31c-.584-.58-1.633-1.605-2.296-2.137-.308-.203-.681-.203-.81.097l-.028 11.648c.318.342.855.941 1.13 1.327-.324-.004-.72-.005-1.149-.006h-.437c-1.408.002-2.992.014-3.428.016h-.115c.384-.507.823-.878 1.164-1.351l.029-11.615c-.191-.279-.458-.213-.767-.094-.94.922-1.773 1.686-2.734 2.567l.01-3.586 9.865-.024Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Trt;
