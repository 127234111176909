[
  {
    "channel": "301bdc82-7041-4e98-a8ae-78904bdc1ae4",
    "uuid": "B0B27763-8A74-4108-A90E-EC35D6CED64C",
    "channelTitle": "Sala Allehanda",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "301bdc82-7041-4e98-a8ae-78904bdc1ae4",
    "uuid": "7A5453A0-33EB-40F5-8977-0F639A71466B",
    "channelTitle": "Sala Allehanda",
    "title": "Näringsliv"
  },
  {
    "channel": "301bdc82-7041-4e98-a8ae-78904bdc1ae4",
    "uuid": "24F03CAC-27F5-404A-853E-CA14F1ED23F0",
    "channelTitle": "Sala Allehanda",
    "title": "Kultur & nöje"
  },
  {
    "channel": "301bdc82-7041-4e98-a8ae-78904bdc1ae4",
    "uuid": "7891BC6C-F593-4C8B-B471-58D57E484469",
    "channelTitle": "Sala Allehanda",
    "title": "Opinion"
  },
  {
    "channel": "301bdc82-7041-4e98-a8ae-78904bdc1ae4",
    "uuid": "D4C44206-2EF5-4257-A77D-F8556735544B",
    "channelTitle": "Sala Allehanda",
    "title": "Sport"
  },
  {
    "channel": "301bdc82-7041-4e98-a8ae-78904bdc1ae4",
    "uuid": "66F2E57C-B67C-4539-BD81-E467251CB8DA",
    "channelTitle": "Sala Allehanda",
    "title": "Sala FF"
  },
  {
    "channel": "301bdc82-7041-4e98-a8ae-78904bdc1ae4",
    "uuid": "87ADBE3B-CE8F-4D62-96D7-135EC4ED5561",
    "channelTitle": "Sala Allehanda",
    "title": "Trafik"
  },
  {
    "channel": "301bdc82-7041-4e98-a8ae-78904bdc1ae4",
    "uuid": "C649CE0A-9451-4224-A012-371362545B4B",
    "channelTitle": "Sala Allehanda",
    "title": "Heby kommun"
  },
  {
    "channel": "301bdc82-7041-4e98-a8ae-78904bdc1ae4",
    "uuid": "33DD1580-09B1-4944-BCE8-F266345E380F",
    "channelTitle": "Sala Allehanda",
    "title": "Sala kommun"
  }
]
