[
  {
    "channel": "80d66df2-32f3-4233-8fdc-691b9c9589c8",
    "uuid": "27F8EB6B-C628-4E0C-B665-4B1E0A864DC1",
    "channelTitle": "Kristianstadsbladet",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "80d66df2-32f3-4233-8fdc-691b9c9589c8",
    "uuid": "8390D4E6-B3D6-4AAC-ABF4-0501AA5A91B7",
    "channelTitle": "Kristianstadsbladet",
    "title": "Sport"
  }
]
