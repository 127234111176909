[
  {
    "channel": "def82572-9912-4710-aab7-70fc13685821",
    "uuid": "BB055AD9-3E5B-4871-B56B-35517BC7923E",
    "channelTitle": "Västgöta-Bladet",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "def82572-9912-4710-aab7-70fc13685821",
    "uuid": "B46E8EC5-8451-4D57-AB8D-FC871B53B936",
    "channelTitle": "Västgöta-Bladet",
    "title": "Kultur & nöje"
  },
  {
    "channel": "def82572-9912-4710-aab7-70fc13685821",
    "uuid": "193BC3EA-937B-4266-BB59-EBF8E50FFDAF",
    "channelTitle": "Västgöta-Bladet",
    "title": "Näringsliv"
  },
  {
    "channel": "def82572-9912-4710-aab7-70fc13685821",
    "uuid": "D9644A46-6796-4762-9BE1-104D6D32020C",
    "channelTitle": "Västgöta-Bladet",
    "title": "Opinion"
  },
  {
    "channel": "def82572-9912-4710-aab7-70fc13685821",
    "uuid": "6DBC4FFC-A3B0-42E9-AC33-8E8C6C90A673",
    "channelTitle": "Västgöta-Bladet",
    "title": "Trafik"
  },
  {
    "channel": "def82572-9912-4710-aab7-70fc13685821",
    "uuid": "E82F9301-07CB-4C10-B677-69E33E9D6F7B",
    "channelTitle": "Västgöta-Bladet",
    "title": "Sport"
  },
  {
    "channel": "def82572-9912-4710-aab7-70fc13685821",
    "uuid": "C178E4C1-D598-48AD-B951-B01E9BB75C57",
    "channelTitle": "Västgöta-Bladet",
    "title": "Tidaholm GoIF"
  },
  {
    "channel": "def82572-9912-4710-aab7-70fc13685821",
    "uuid": "469890A1-73B6-4BB0-A42E-53E6F1E2EAFE",
    "channelTitle": "Västgöta-Bladet",
    "title": "IFK Tidaholm"
  },
  {
    "channel": "def82572-9912-4710-aab7-70fc13685821",
    "uuid": "F8D49CAE-864D-46DC-B32B-006FD93C6DD3",
    "channelTitle": "Västgöta-Bladet",
    "title": "Fröjered IF dam"
  },
  {
    "channel": "def82572-9912-4710-aab7-70fc13685821",
    "uuid": "D17F8F69-AE2F-4A8B-90AF-1B95B8F17DBD",
    "channelTitle": "Västgöta-Bladet",
    "title": "Tidaholms kommun"
  }
]
