[
  {
    "channel": "b8709456-a15e-46cb-b706-6eb10d5449c5",
    "uuid": "0C3408B5-E996-43BF-9714-625E22163DD0",
    "channelTitle": "Nacka Värmdö Posten",
    "title": "Stora nyheter"
  },
  {
    "channel": "b8709456-a15e-46cb-b706-6eb10d5449c5",
    "uuid": "F6054F99-12EB-4845-AF05-850503D286C1",
    "channelTitle": "Nacka Värmdö Posten",
    "title": "Näringsliv"
  },
  {
    "channel": "b8709456-a15e-46cb-b706-6eb10d5449c5",
    "uuid": "B127CD5F-8942-461F-9B2B-A97DE3E32EFD",
    "channelTitle": "Nacka Värmdö Posten",
    "title": "TV"
  },
  {
    "channel": "b8709456-a15e-46cb-b706-6eb10d5449c5",
    "uuid": "9C7AB71A-D918-42B7-9A89-E5ED87BE3FA7",
    "channelTitle": "Nacka Värmdö Posten",
    "title": "Sport"
  }
]
