[
  {
    "channel": "8772fd06-022d-4c77-92ad-82ab6a5a50b7",
    "uuid": "5A39A2AF-5CDD-47CE-BDFD-DA2DEB34CEC6",
    "channelTitle": "Allehanda",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "8772fd06-022d-4c77-92ad-82ab6a5a50b7",
    "uuid": "45480C09-D4ED-42B4-8FD8-BB9D7BEF0DEB",
    "channelTitle": "Allehanda",
    "title": "Näringsliv"
  },
  {
    "channel": "8772fd06-022d-4c77-92ad-82ab6a5a50b7",
    "uuid": "BAF4C73A-32CE-495A-9107-2C2170626FE1",
    "channelTitle": "Allehanda",
    "title": "Kultur & nöje"
  },
  {
    "channel": "8772fd06-022d-4c77-92ad-82ab6a5a50b7",
    "uuid": "C1102C6F-687B-4C9E-BB6F-6205EACE0766",
    "channelTitle": "Allehanda",
    "title": "Opinion"
  },
  {
    "channel": "8772fd06-022d-4c77-92ad-82ab6a5a50b7",
    "uuid": "22CC4527-672B-4AB1-AD40-30B3D21F40D0",
    "channelTitle": "Allehanda",
    "title": "Modo Hockey Herr"
  },
  {
    "channel": "8772fd06-022d-4c77-92ad-82ab6a5a50b7",
    "uuid": "AC0D7EF5-980F-4126-AA29-4582A4B1A8F6",
    "channelTitle": "Allehanda",
    "title": "Sport"
  },
  {
    "channel": "8772fd06-022d-4c77-92ad-82ab6a5a50b7",
    "uuid": "345ED075-37AC-45B4-83A1-FBA5AF7CC556",
    "channelTitle": "Allehanda",
    "title": "Trafik"
  },
  {
    "channel": "8772fd06-022d-4c77-92ad-82ab6a5a50b7",
    "uuid": "38E29239-79D9-44F8-9B96-BD7096F8D56E",
    "channelTitle": "Allehanda",
    "title": "Örnsköldsviks kommun"
  }
]
