import { getChannelIcon, getChannelConfig } from '~/lib/utils/channel-util';
function ChannelIcon ({ channelId, className }) {
  const Icon = getChannelIcon(channelId, className);
  const { appId } = getChannelConfig(channelId);
  if (!Icon) return null;

  return (
    <Icon
      key={channelId}
      className={className}
      data-name={appId}
      data-id={channelId}
    />
  );
}

export default ChannelIcon;
