[
  {
    "channel": "cf260058-2e86-4545-a38e-1d29bc63fb43",
    "uuid": "1108E1FA-B8BE-4ABA-9E74-E2D01D5AE53A",
    "channelTitle": "Hufvudstadsbladet",
    "title": "Nyheter"
  },
  {
    "channel": "cf260058-2e86-4545-a38e-1d29bc63fb43",
    "uuid": "4B6A4649-6D02-40FF-9C4C-91E5FBD0CFDA",
    "channelTitle": "Hufvudstadsbladet",
    "title": "Sport"
  },
  {
    "channel": "cf260058-2e86-4545-a38e-1d29bc63fb43",
    "uuid": "43328BBD-7C84-485C-A577-2CBDE9A11ED1",
    "channelTitle": "Hufvudstadsbladet",
    "title": "Kultur"
  },
  {
    "channel": "cf260058-2e86-4545-a38e-1d29bc63fb43",
    "uuid": "1A2CFFA3-BBC3-4763-8748-6461A92BB143",
    "channelTitle": "Hufvudstadsbladet",
    "title": "På stan"
  }
]
