const Gd = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>GD</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#265A91" />
      <path
        d="m14.614 10.375.003 4.179h-.427c-.805-2.363-1.796-3.537-3.297-3.537-1.892 0-2.884 1.888-2.884 4.906 0 2.956.992 5.107 2.62 5.107 1.008 0 1.66-.619 1.97-1.455v-1.294c0-.36-.142-1.072-1.613-1.072v-.344h4.028l.003 4.727-.208-.001-.985-.995c-.931.511-2.14.99-3.551.99-2.776 0-5.148-1.732-5.148-5.384 0-3.42 2.341-5.68 5.644-5.68 1.223 0 2.12.31 2.833.696l.752-.843h.26Zm6.385.41c2.217 0 5.876 1.114 5.876 5.355 0 3.606-3.054 5.215-6.17 5.215H15.68v-.357c.31-.015.915-.092 1.117-.17.356-.139.45-.417.45-.758v-7.877c0-.34-.094-.618-.45-.789-.357-.17-.791-.232-1.07-.263v-.356h5.272Zm-.278.495c-.28 0-.683.016-.962.031v8.558c0 .666.45.99 1.24.99 2.094 0 2.9-1.949 2.9-4.735 0-2.816-1.194-4.844-3.178-4.844Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Gd;
