[
  {
    "channel": "6a00ec61-3c7f-4427-8f90-1d2bd89e9a29",
    "uuid": "CF376654-1107-4A81-8837-7B9BA9212137",
    "channelTitle": "Smålands Dagblad",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "6a00ec61-3c7f-4427-8f90-1d2bd89e9a29",
    "uuid": "33CF15DA-957B-413E-A8AB-6EC112F2085B",
    "channelTitle": "Smålands Dagblad",
    "title": "Kultur & nöje"
  },
  {
    "channel": "6a00ec61-3c7f-4427-8f90-1d2bd89e9a29",
    "uuid": "4C412F62-E3C9-4F21-9449-5CEDDD6332A2",
    "channelTitle": "Smålands Dagblad",
    "title": "Näringsliv"
  },
  {
    "channel": "6a00ec61-3c7f-4427-8f90-1d2bd89e9a29",
    "uuid": "1969BFA7-B3C8-45B5-8F91-4DC42B4CC36F",
    "channelTitle": "Smålands Dagblad",
    "title": "Opinion"
  },
  {
    "channel": "6a00ec61-3c7f-4427-8f90-1d2bd89e9a29",
    "uuid": "D5B8C409-F749-47B1-9C59-8E93EC3BFDA4",
    "channelTitle": "Smålands Dagblad",
    "title": "Sport"
  },
  {
    "channel": "6a00ec61-3c7f-4427-8f90-1d2bd89e9a29",
    "uuid": "3EBB4732-0F1C-426A-9165-E620F867E791",
    "channelTitle": "Smålands Dagblad",
    "title": "Trafik"
  },
  {
    "channel": "6a00ec61-3c7f-4427-8f90-1d2bd89e9a29",
    "uuid": "E0BC2F80-8F34-4AA8-9793-B66D3DF23DC6",
    "channelTitle": "Smålands Dagblad",
    "title": "Nässjö kommun"
  },
  {
    "channel": "6a00ec61-3c7f-4427-8f90-1d2bd89e9a29",
    "uuid": "616BD7C3-B326-47EA-8DA3-39C257DC89D9",
    "channelTitle": "Smålands Dagblad",
    "title": "Sävsjö kommun"
  }
]
