const appConfig = require('./config/channel-config');
const channelIcons = require('./config/channel-icons');

const getChannelConfig = (channelId) => {
  return appConfig[channelId];
};

const getChannelIcon = (channelId) => {
  return channelIcons[channelId].icon;
};

const getChannelName = (channelId) => {
  return appConfig[channelId].name;
};

const getChannelSegments = (channelId) => {
  return appConfig[channelId].segments;
};

const getChannelAppId = (channelId) => {
  return appConfig[channelId].appId;
};

const getChannelDomain = (channelId) => {
  return appConfig[channelId].domain;
};

const getChannelId = (appId) => {
  return Object.keys(appConfig).find(key => appConfig[key].appId === appId);
};

const getAllConfig = () => {
  return Object.entries(appConfig);
};

const isChannelConfigured = (channelId) => {
  return channelId in appConfig;
};

const removeUnknownChannels = (channelIds) => {
  return channelIds.filter(isChannelConfigured);
};

const isAnyChannelUnknown = (channelIds) => {
  return channelIds.some(channelId => !isChannelConfigured(channelId));
};

module.exports = {
  getChannelConfig,
  getChannelIcon,
  getChannelName,
  getChannelSegments,
  getChannelAppId,
  getChannelDomain,
  getChannelId,
  getAllConfig,
  isChannelConfigured,
  isAnyChannelUnknown,
  removeUnknownChannels,
};
