[
  {
    "channel": "eb3cb54e-0661-47db-a723-2334af94beae",
    "uuid": "C69C0AF3-CCF0-4548-9AE0-CB897151F4B1",
    "channelTitle": "Smålänningen",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "eb3cb54e-0661-47db-a723-2334af94beae",
    "uuid": "A67B2E13-7E00-4A90-9072-A3B16AB2A7C9",
    "channelTitle": "Smålänningen",
    "title": "Kultur & nöje"
  },
  {
    "channel": "eb3cb54e-0661-47db-a723-2334af94beae",
    "uuid": "56FACC79-D27F-46FC-BE14-795CD97B1B66",
    "channelTitle": "Smålänningen",
    "title": "Näringsliv"
  },
  {
    "channel": "eb3cb54e-0661-47db-a723-2334af94beae",
    "uuid": "0964E90A-B519-4360-B9F1-CCD93A6EB94E",
    "channelTitle": "Smålänningen",
    "title": "Opinion"
  },
  {
    "channel": "eb3cb54e-0661-47db-a723-2334af94beae",
    "uuid": "D0E7ADAC-0072-4DEE-BD42-2225759AB9C7",
    "channelTitle": "Smålänningen",
    "title": "Sport"
  },
  {
    "channel": "eb3cb54e-0661-47db-a723-2334af94beae",
    "uuid": "EAAE41AE-7C4E-4A0C-862B-F6F79AC09618",
    "channelTitle": "Smålänningen",
    "title": "Troja-Ljungby"
  },
  {
    "channel": "eb3cb54e-0661-47db-a723-2334af94beae",
    "uuid": "3DFF9DFB-3A59-47DD-BB83-035DDCC7E3CD",
    "channelTitle": "Smålänningen",
    "title": "Trafik"
  },
  {
    "channel": "eb3cb54e-0661-47db-a723-2334af94beae",
    "uuid": "3C4E4E30-E1F1-4151-8298-E90AC8232CEB",
    "channelTitle": "Smålänningen",
    "title": "Ljungby kommun"
  },
  {
    "channel": "eb3cb54e-0661-47db-a723-2334af94beae",
    "uuid": "E13CE575-5EAF-4371-ACEC-F05C4EB7CF99",
    "channelTitle": "Smålänningen",
    "title": "Markaryds kommun"
  },
  {
    "channel": "eb3cb54e-0661-47db-a723-2334af94beae",
    "uuid": "B2B47819-4B57-4E6D-91DA-80ACE5121B3D",
    "channelTitle": "Smålänningen",
    "title": "Älmhult kommun"
  }
]
