[
  {
    "channel": "b9f133af-d585-4f82-b2cf-1b0f096be489",
    "uuid": "C98409D2-B6BE-4A08-9B6D-BD983BF636A5",
    "channelTitle": "Falköpings Tidning",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "b9f133af-d585-4f82-b2cf-1b0f096be489",
    "uuid": "00EC8BF8-5592-4CB1-B360-8EE4BC73EEEE",
    "channelTitle": "Falköpings Tidning",
    "title": "Kultur & nöje"
  },
  {
    "channel": "b9f133af-d585-4f82-b2cf-1b0f096be489",
    "uuid": "FB6D93A0-4401-4286-8119-87E8ACFA85EB",
    "channelTitle": "Falköpings Tidning",
    "title": "Näringsliv"
  },
  {
    "channel": "b9f133af-d585-4f82-b2cf-1b0f096be489",
    "uuid": "28E8CBF5-BB9C-4763-B5F8-51787E067AF5",
    "channelTitle": "Falköpings Tidning",
    "title": "Opinion"
  },
  {
    "channel": "b9f133af-d585-4f82-b2cf-1b0f096be489",
    "uuid": "89EA3A81-DAE3-4402-BFC5-F1D5B48E10B1",
    "channelTitle": "Falköpings Tidning",
    "title": "Sport"
  },
  {
    "channel": "b9f133af-d585-4f82-b2cf-1b0f096be489",
    "uuid": "41C83B05-0C43-40EC-A57E-F2158903E5F6",
    "channelTitle": "Falköpings Tidning",
    "title": "IFK Falköping"
  },
  {
    "channel": "b9f133af-d585-4f82-b2cf-1b0f096be489",
    "uuid": "E897F2B6-7D10-4D90-B704-9A53DED1F375",
    "channelTitle": "Falköpings Tidning",
    "title": "Falköping KIK"
  },
  {
    "channel": "b9f133af-d585-4f82-b2cf-1b0f096be489",
    "uuid": "815E1684-7C01-442A-A0B3-A7421E92E738",
    "channelTitle": "Falköpings Tidning",
    "title": "Floby VK"
  },
  {
    "channel": "b9f133af-d585-4f82-b2cf-1b0f096be489",
    "uuid": "33276147-B8A8-4233-A79F-1848F580F84F",
    "channelTitle": "Falköpings Tidning",
    "title": "Åsarp/Trädet FK"
  },
  {
    "channel": "b9f133af-d585-4f82-b2cf-1b0f096be489",
    "uuid": "1307F945-301C-401D-A306-BA6C75E315BF",
    "channelTitle": "Falköpings Tidning",
    "title": "Trafik"
  },
  {
    "channel": "b9f133af-d585-4f82-b2cf-1b0f096be489",
    "uuid": "FDDEFD9D-0C05-45EC-B2A6-E720CB33574B",
    "channelTitle": "Falköpings Tidning",
    "title": "Falköpings kommun"
  },
  {
    "channel": "b9f133af-d585-4f82-b2cf-1b0f096be489",
    "uuid": "4B659D37-4000-4B33-B6A2-3951301A4D70",
    "channelTitle": "Falköpings Tidning",
    "title": "Mullsjö kommun"
  },
  {
    "channel": "b9f133af-d585-4f82-b2cf-1b0f096be489",
    "uuid": "9BB208A4-C509-4E64-9288-1C2E328F9D98",
    "channelTitle": "Falköpings Tidning",
    "title": "Floby-Grolanda IF"
  }
]
