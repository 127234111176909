const Mt = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>MT</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#4F7F61" />
      <path
        d="M28.499 13.247c1.548 6.896-2.787 13.741-9.68 15.29-6.895 1.547-13.739-2.788-15.287-9.684-1.547-6.896 2.787-13.741 9.681-15.29 6.895-1.547 13.739 2.788 15.286 9.684Zm-.64.144c-1.466-6.53-7.97-10.649-14.502-9.187-3.163.71-5.86 2.61-7.595 5.35a12.065 12.065 0 0 0-1.59 9.156 12.062 12.062 0 0 0 5.35 7.597 12.058 12.058 0 0 0 9.153 1.59c3.163-.711 5.86-2.61 7.596-5.35a12.067 12.067 0 0 0 1.588-9.156ZM16.017 4.397c5.329 0 10.15 3.688 11.365 9.101.681 3.037.14 6.157-1.525 8.787a11.57 11.57 0 0 1-7.29 5.134 11.564 11.564 0 0 1-8.784-1.526 11.568 11.568 0 0 1-5.133-7.291 11.577 11.577 0 0 1 1.525-8.786 11.574 11.574 0 0 1 7.289-5.134c.856-.193 1.71-.285 2.553-.285Zm-.445 5.362-3.596.807.356 7.43-.13.03-2.81-6.88-3.596.808.062.275.862-.193 2.416 10.767c.137.608-.047 1.007-.618 1.3l-.139.065.062.276 2.541-.571-.062-.275c-.597.066-1.11-.107-1.302-.69l-.036-.132L7.32 12.697l.13-.029 4.48 10.992 1.076-.242-.604-11.861.107-.025 2.502 11.147-.861.193.061.276 4.2-.943-.063-.275-.84.188-2.671-11.905.797-.179-.062-.275Zm8.224-1.847L15.98 9.667l1.195 5.324.301-.068c-.098-3.663.241-5.28 1.024-5.563l.075-.022.043-.01 2.68 11.94-.905.202.062.276 4.457-1.001-.062-.275-.904.203-2.68-11.94.043-.01c.818-.165 1.797 1.045 3.243 4.272l.137.309.302-.068-1.195-5.324Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Mt;
