[
  {
    "channel": "d4c7174d-995e-4efa-a4e8-ff448cd52132",
    "uuid": "81BABDED-2003-42BC-AADA-706A6A3A119B",
    "channelTitle": "Nu i Österåker",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "d4c7174d-995e-4efa-a4e8-ff448cd52132",
    "uuid": "5D1F5EB6-B237-4A4C-86C3-14E803A6FC2A",
    "channelTitle": "Nu i Österåker",
    "title": "Sport"
  },
  {
    "channel": "d4c7174d-995e-4efa-a4e8-ff448cd52132",
    "uuid": "11EAE0D1-1DEF-4C8A-B329-CB6ECC3CEF69",
    "channelTitle": "Nu i Österåker",
    "title": "Tv"
  },
  {
    "channel": "d4c7174d-995e-4efa-a4e8-ff448cd52132",
    "title": "Skärgård",
    "uuid": "fe912fd3-a932-4a46-bcdb-9860609b20ff",
    "channelTitle": "Nu i Österåker"
  },
  {
    "channel": "d4c7174d-995e-4efa-a4e8-ff448cd52132",
    "title": "Blåljus",
    "uuid": "df44e7c8-46d1-42da-b83a-b7285526dbc6",
    "channelTitle": "Nu i Österåker"
  },
  {
    "channel": "d4c7174d-995e-4efa-a4e8-ff448cd52132",
    "title": "Krim",
    "uuid": "53be25d7-986d-4a99-a342-b83067c062e7",
    "channelTitle": "Nu i Österåker"
  },
  {
    "channel": "d4c7174d-995e-4efa-a4e8-ff448cd52132",
    "title": "Näringsliv",
    "uuid": "896a3917-3692-458c-ad65-3c12300ca611",
    "channelTitle": "Nu i Österåker"
  },
  {
    "channel": "d4c7174d-995e-4efa-a4e8-ff448cd52132",
    "title": "Politik",
    "uuid": "505516a4-a7ef-4e3d-87bc-48fff90bc57f",
    "channelTitle": "Nu i Österåker"
  },
  {
    "channel": "d4c7174d-995e-4efa-a4e8-ff448cd52132",
    "title": "Nöje",
    "uuid": "1da4b424-3cd0-4380-bf28-8d3881865ff5",
    "channelTitle": "Nu i Österåker"
  },
  {
    "channel": "d4c7174d-995e-4efa-a4e8-ff448cd52132",
    "title": "Österåker",
    "uuid": "0c1a2a2d-741f-47d2-8f3e-1396eb7f6f92",
    "channelTitle": "Nu i Österåker"
  }
]
