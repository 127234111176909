const Fp = (props) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    {...props}
  >
    <title>FP</title>
    <g clipPath="url(#a)">
      <g clipPath="url(#b)">
        <path d="M32 0H0v32h32V0Z" fill="#fff" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.548 11.297c1.086.249 1.801.962 2.174 1.428l.341.59c.808 1.582.789 2.569.342 4.406-.305 1.198-1.18 2.172-2.392 2.98-1.223.976-2.55 1.394-3.844 1.77l-.107.03-.159.046-.106.03-.21.062-.106.031a36.14 36.14 0 0 0-.157.047c.023.28.023.588.02.903v.076c-.01.925-.03 1.896.445 2.372l.497.403.062.218-.093.186h-4.38c-.41-.67.79-.707.84-1.552l.012-12.474c-.168-.776-1.255-.714-.51-1.552h7.33ZM7.07 6.407l9.987.024-.025 3.237-.003-.002c-.044-.02-.177-.024-.317-.025h-.035c-.224 0-.451.011-.365-.006C15.528 7.41 10.14 8.444 9.827 8.1c.004.29.007.56.008.828v.08l.001.16v.444l-.001 1.14v.245c0 .385.001.812.004 1.303 0 0 4.937.25 4.75-.595l.375-.282.313-.063v3.825c-.72 0-.563-.502-1.221-.878-1.291.006-2.632-.032-4.167 0 0 0-.01.907-.011 2.054v.249c0 1.542.022 3.414.112 4.118.042.056.089.108.137.157l.022.023c.286.286.598.482.304.986H6.694c-.439-.815.376-.376.596-1.505L7.283 7.986c-.15-.652-.432-.263-.683-.953l.063-.345.407-.282Zm12.284 7.063v6.92c.299 0 .758-.23 1.056-.28 2.497-1.241 4.119-2.743 3.175-5.176-.745-1.589-2.59-1.514-4.23-1.464Z"
          fill="#000"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
      <clipPath id="b">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default Fp;
