[
  {
    "channel": "3311201e-7d46-49c4-a30e-516d1fef58c8",
    "uuid": "0d0880a9-cfdd-43d4-8711-46bdbc16da3a",
    "channelTitle": "Tidningen Ångermanland",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "3311201e-7d46-49c4-a30e-516d1fef58c8",
    "uuid": "75b609da-0c7d-4c7a-b5a5-cef0e9474298",
    "channelTitle": "Tidningen Ångermanland",
    "title": "Härnösands kommun"
  },
  {
    "channel": "3311201e-7d46-49c4-a30e-516d1fef58c8",
    "uuid": "114ad0f2-889d-4f04-af40-00f859d90767",
    "channelTitle": "Tidningen Ångermanland",
    "title": "Kramfors kommun"
  },
  {
    "channel": "3311201e-7d46-49c4-a30e-516d1fef58c8",
    "uuid": "030387be-6ede-4761-9f5e-7529ac3becd9",
    "channelTitle": "Tidningen Ångermanland",
    "title": "Sollefteå kommun"
  },
  {
    "channel": "3311201e-7d46-49c4-a30e-516d1fef58c8",
    "uuid": "92f66ea9-289e-45fa-b6ab-061936bf9218",
    "channelTitle": "Tidningen Ångermanland",
    "title": "Sport"
  },
  {
    "channel": "3311201e-7d46-49c4-a30e-516d1fef58c8",
    "uuid": "81fe8239-d7fc-4c36-87ef-e6428d56609b",
    "channelTitle": "Tidningen Ångermanland",
    "title": "Kultur"
  },
  {
    "channel": "3311201e-7d46-49c4-a30e-516d1fef58c8",
    "uuid": "dbef7dba-e7de-401f-983c-3ecf56f36e39",
    "channelTitle": "Tidningen Ångermanland",
    "title": "Ledare (lib)"
  },
  {
    "channel": "3311201e-7d46-49c4-a30e-516d1fef58c8",
    "uuid": "5beaa997-c9db-44fd-85ff-8b56e4e84414",
    "channelTitle": "Tidningen Ångermanland",
    "title": "Ledare (S)"
  }
]
