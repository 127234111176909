[
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "988EC23C-E375-4BF6-A60F-8CDCD39D4D89",
    "channelTitle": "Bandypuls",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "CC293C5E-0881-46EA-AEEF-96EAFE716EBF",
    "channelTitle": "Bandypuls",
    "title": "Live"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "2CE3E94A-EE77-45E8-A9DC-BEA8E7596F52",
    "channelTitle": "Bandypuls",
    "title": "Elitserien Herr"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "29362F0E-1F02-489B-BDDD-446CD16AD6C4",
    "channelTitle": "Bandypuls",
    "title": "Elitserien Dam"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "63CA0704-FC7D-4C01-BD9D-D03B160BA60E",
    "channelTitle": "Bandypuls",
    "title": "AIK Bandy Herr"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "17305F6D-3113-4C8F-B744-C38DE61A7923",
    "channelTitle": "Bandypuls",
    "title": "AIK Bandy Dam"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "D4466D23-EC90-48DB-BF92-13A3C3817506",
    "channelTitle": "Bandypuls",
    "title": "Bollnäs GIF"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "43CF2F2F-26F9-4030-967C-946233EFBCA4",
    "channelTitle": "Bandypuls",
    "title": "Brobergs IF"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "92292F08-5274-440E-8363-A36FB022B165",
    "channelTitle": "Bandypuls",
    "title": "Edsbyns IF"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "4CCA9422-0305-41E5-84BC-A6D8B00EB607",
    "channelTitle": "Bandypuls",
    "title": "Frillesås BK"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "085789f1-2cd6-46e7-80c7-e68c100e9400",
    "channelTitle": "Bandypuls",
    "title": "Gripen Trollhättan"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "B9E25796-74F0-4FCA-BA9D-623C4A51500A",
    "channelTitle": "Bandypuls",
    "title": "Hammarby IF"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "9E599466-7827-46FA-A013-5943AFB3FCBF",
    "channelTitle": "Bandypuls",
    "title": "IFK Kungälv"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "B8FE3959-05AC-403A-8D0A-206E4FA2B547",
    "channelTitle": "Bandypuls",
    "title": "IFK Motala"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "8E3BD0A8-FC50-4021-8D5C-063A19963829",
    "channelTitle": "Bandypuls",
    "title": "IFK Rättvik Bandy Herr"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "67C5FDD7-64C5-4B02-89FD-CB5A5E2B255C",
    "channelTitle": "Bandypuls",
    "title": "IFK Rättvik Bandy Dam"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "5DC941A7-F6D6-403B-9878-6CC501345B90",
    "channelTitle": "Bandypuls",
    "title": "IFK Vänersborg"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "BC341CDD-A481-4766-AE3D-39A75D14AAC8",
    "channelTitle": "Bandypuls",
    "title": "IK Sirius"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "1AF64262-4FC3-43F9-83FE-934D71AE5D3A",
    "channelTitle": "Bandypuls",
    "title": "Ljusdals BK"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "D77026C4-5136-4C85-AD0B-88D4F2F720E0",
    "channelTitle": "Bandypuls",
    "title": "Mölndal bandy Dam"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "61F6AE54-29AE-4C62-A0A3-8072B4D38102",
    "channelTitle": "Bandypuls",
    "title": "Sandvikens AIK"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "AD7A80DE-F591-4399-8A12-612BC5888FC5",
    "channelTitle": "Bandypuls",
    "title": "Sandvikens AIK Dam"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "A152A74B-D267-4E33-ACC6-E22BB07F12E1",
    "channelTitle": "Bandypuls",
    "title": "Skirö AIK Dam"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "BF71B836-CC9C-4EC0-9393-F59F9213D04B",
    "channelTitle": "Bandypuls",
    "title": "Skutskärs IF Dam"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "85335EFA-083E-4B62-B9BE-69211B6388D4",
    "channelTitle": "Bandypuls",
    "title": "Spånga/Bromstens BK"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "db410ad8-e6d4-4dad-b9f5-d0e12c69a257",
    "channelTitle": "Bandypuls",
    "title": "Uppsala BoIS Dam"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "9A437EB0-608D-4DBC-9DF8-2FCAE7DD4423",
    "channelTitle": "Bandypuls",
    "title": "Vetlanda BK"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "ACD0CDB8-B02D-41DF-AD21-C89B87142BDB",
    "channelTitle": "Bandypuls",
    "title": "Villa Lidköping BK Herr"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "FC6A41AD-BF66-4F4D-89D1-07069FE9F48A",
    "channelTitle": "Bandypuls",
    "title": "Villa Lidköping BK Dam"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "530BA2C7-CFA0-4413-818E-62B22EEC6CB2",
    "channelTitle": "Bandypuls",
    "title": "Västerås SK Bandy Herr"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "1D703672-F23F-400F-A978-D980CF2CDAE6",
    "channelTitle": "Bandypuls",
    "title": "Västerås SK Bandy Dam"
  },
  {
    "channel": "357b1ad5-8bcb-4eff-bd11-d1b232f4858b",
    "uuid": "8838A31D-65EB-4F62-9120-A03737A52FE4",
    "channelTitle": "Bandypuls",
    "title": "Åby/Tjureda IF"
  }
]
