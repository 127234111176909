const Vp = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>VP</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#0280C9" />
      <path
        d="M24.69 13.468c-.02 2.23-.285 2.677-.606 2.954-.473.41-1.23.468-1.616.465h-.72a.167.167 0 0 1-.17-.166V9.635a.34.34 0 0 1 .343-.337h.687c.39-.024.942.01 1.393.343.704.523.709 1.631.689 3.827m.73-4.688c-.356-.222-1.2-.561-2.812-.561v.001H18.5v-.001h-4.889V9.53h1.02c.405 0 .7.377.597.762L12.375 21.7 9.46 10.296a.61.61 0 0 1 .596-.765h.726V8.22H4.687V9.53h.725a.95.95 0 0 1 .915.679l3.925 13.523h3.42L17.194 9.99a.614.614 0 0 1 .535-.453h.63c.343 0 .622.273.622.61V22.25c0 .338-.279.611-.622.611h-.916v.887h5.608v-.887h-.85a.616.616 0 0 1-.622-.61v-4.12c0-.09.074-.164.166-.165l.847-.016c.93-.01 1.632-.202 1.89-.297.421-.156 1.073-.396 1.663-1.014.573-.599.786-1.232.895-1.571.51-1.588.618-4.897-1.618-6.288"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Vp;
