[
  {
    "channel": "a2804487-7a24-4cff-98e2-6f70451ead76",
    "uuid": "E1501FB3-9520-4DBB-8F31-1D9FCF3B4CA4",
    "channelTitle": "Ulricehamns tidning",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "a2804487-7a24-4cff-98e2-6f70451ead76",
    "uuid": "1F272974-B26D-4094-8428-9BF9113EA2D2",
    "channelTitle": "Ulricehamns tidning",
    "title": "Sport"
  }
]
