const Ltz = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>LTZ</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#B5272C" />
      <path
        d="M19.554 9.781c1.489 0 3.976.213 5.776.213.788 0 1.367-.042 2.076-.132-.295 1.476-1.165 3.976-1.685 5.33-.782.138-2.003.096-2.786.064-.455 2.016-1.355 5.221-1.95 6.954-1.507-.158-4.493-.41-5.854 0 .955-2.384 1.886-4.864 2.485-7.027-1.116 0-2.585.105-3.64.336.683-1.827 1.427-4.37 1.572-5.399.93-.189 2.603-.339 4.006-.339Zm-9.504 0c1.086 0 3.018.228 3.747.275a67.964 67.964 0 0 1-1.903 6.75c1.283.104 2.726-.006 3.75-.164-.652 1.56-1.505 4.08-1.865 5.262a9.62 9.62 0 0 1-2.058.206c-1.199 0-3.334-.212-4.73-.212-.543 0-1.673.113-2.397.288 1.52-3.75 2.928-8.119 3.424-12.105a6.887 6.887 0 0 1 2.032-.3Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Ltz;
