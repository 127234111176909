[
  {
    "channel": "02569f44-187a-4171-860a-123edf2e5e5e",
    "uuid": "0D0112B5-2405-4EE4-A6BE-FC861D095FA7",
    "channelTitle": "NA",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "02569f44-187a-4171-860a-123edf2e5e5e",
    "uuid": "80FE3654-0540-41C3-8C6D-B4DB2C933491",
    "channelTitle": "NA",
    "title": "Sport"
  },
  {
    "channel": "02569f44-187a-4171-860a-123edf2e5e5e",
    "uuid": "955CD7F0-EABB-4535-8A1D-438EFBE6E7A0",
    "channelTitle": "NA",
    "title": "Näringsliv"
  },
  {
    "channel": "02569f44-187a-4171-860a-123edf2e5e5e",
    "uuid": "AB6D0BDE-D463-4433-9496-14CB0F93D6D3",
    "channelTitle": "NA",
    "title": "Opinion"
  },
  {
    "channel": "02569f44-187a-4171-860a-123edf2e5e5e",
    "uuid": "187747F2-CDD5-47C4-8EFF-D14F80D6703E",
    "channelTitle": "NA",
    "title": "Kultur & nöje"
  },
  {
    "channel": "02569f44-187a-4171-860a-123edf2e5e5e",
    "uuid": "C04B2F95-C263-4EC0-8E49-0843CA15CDC2",
    "channelTitle": "NA",
    "title": "Degerfors IF"
  },
  {
    "channel": "02569f44-187a-4171-860a-123edf2e5e5e",
    "uuid": "C0A2C26C-A8F4-47EF-A79A-9AE6203D5686",
    "channelTitle": "NA",
    "title": "Indianerna"
  },
  {
    "channel": "02569f44-187a-4171-860a-123edf2e5e5e",
    "uuid": "2C404318-8FEC-45A2-BFC9-2974583B5CB2",
    "channelTitle": "NA",
    "title": "Kif Örebro"
  },
  {
    "channel": "02569f44-187a-4171-860a-123edf2e5e5e",
    "uuid": "33F36676-AA8A-4FE5-B928-9CD8759FF0BD",
    "channelTitle": "NA",
    "title": "Marcus Ericsson"
  },
  {
    "channel": "02569f44-187a-4171-860a-123edf2e5e5e",
    "uuid": "67D9CCB2-54EC-4D26-AA11-6BA954829965",
    "channelTitle": "NA",
    "title": "Örebro Hockey"
  },
  {
    "channel": "02569f44-187a-4171-860a-123edf2e5e5e",
    "uuid": "E6AEDDE0-88BF-491A-8B70-5428197A8417",
    "channelTitle": "NA",
    "title": "Örebro SK"
  },
  {
    "channel": "02569f44-187a-4171-860a-123edf2e5e5e",
    "uuid": "A0AAB64D-AA7D-4FA3-BA36-FB6EE60A9B37",
    "channelTitle": "NA",
    "title": "Trafik"
  },
  {
    "channel": "02569f44-187a-4171-860a-123edf2e5e5e",
    "uuid": "59699B38-A21F-4BF0-A864-7F2C36A214A3",
    "channelTitle": "NA",
    "title": "Askersunds kommun"
  },
  {
    "channel": "02569f44-187a-4171-860a-123edf2e5e5e",
    "uuid": "C2DDDF11-700D-4FEF-A6DF-A4D9BC495048",
    "channelTitle": "NA",
    "title": "Degerfors kommun"
  },
  {
    "channel": "02569f44-187a-4171-860a-123edf2e5e5e",
    "uuid": "4B2ED868-D4D9-4F80-94CC-C01B97738461",
    "channelTitle": "NA",
    "title": "Hallsbergs kommun"
  },
  {
    "channel": "02569f44-187a-4171-860a-123edf2e5e5e",
    "uuid": "F57B953F-54ED-4690-8FCA-585EFB563621",
    "channelTitle": "NA",
    "title": "Hällefors kommun"
  },
  {
    "channel": "02569f44-187a-4171-860a-123edf2e5e5e",
    "uuid": "B7362434-7F56-4168-87B7-AB6E98FE9049",
    "channelTitle": "NA",
    "title": "Karlskoga kommun"
  },
  {
    "channel": "02569f44-187a-4171-860a-123edf2e5e5e",
    "uuid": "EE2B35A8-050B-4947-8831-0EFE0A6A935A",
    "channelTitle": "NA",
    "title": "Kumla kommun"
  },
  {
    "channel": "02569f44-187a-4171-860a-123edf2e5e5e",
    "uuid": "5A09D6E6-205D-446F-89C9-8717BFFEA13F",
    "channelTitle": "NA",
    "title": "Laxå kommun"
  },
  {
    "channel": "02569f44-187a-4171-860a-123edf2e5e5e",
    "uuid": "F4EE9A52-2963-4DE7-852F-2CE98D03BE3D",
    "channelTitle": "NA",
    "title": "Lekebergs kommun"
  },
  {
    "channel": "02569f44-187a-4171-860a-123edf2e5e5e",
    "uuid": "14DA98D3-CD11-48C8-9244-CEA107C1DF0A",
    "channelTitle": "NA",
    "title": "Lindesbergs kommun"
  },
  {
    "channel": "02569f44-187a-4171-860a-123edf2e5e5e",
    "uuid": "1AC69C31-FD48-4E09-9143-BF57489B47C9",
    "channelTitle": "NA",
    "title": "Ljusnarsbergs kommun"
  },
  {
    "channel": "02569f44-187a-4171-860a-123edf2e5e5e",
    "uuid": "A96D36FC-4C23-440A-9E5B-FF44B3DE37C8",
    "channelTitle": "NA",
    "title": "Nora kommun"
  },
  {
    "channel": "02569f44-187a-4171-860a-123edf2e5e5e",
    "uuid": "C35DDD6C-422F-4217-803A-B78E0C2D9AAC",
    "channelTitle": "NA",
    "title": "Örebro kommun"
  },
  {
    "channel": "02569f44-187a-4171-860a-123edf2e5e5e",
    "uuid": "c22ba205-e43e-46a6-b461-e4211ccb66db",
    "channelTitle": "NA",
    "title": "Örebro"
  }
]
