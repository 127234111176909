[
  {
    "channel": "67b7c678-4b80-43ce-a931-2a65e32c6276",
    "uuid": "D6B47691-64CA-41AF-99CA-D772B21A8402",
    "channelTitle": "Borlänge Tidning",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "67b7c678-4b80-43ce-a931-2a65e32c6276",
    "uuid": "5A55848B-9A76-4895-9BBE-EF6623FFAEC0",
    "channelTitle": "Borlänge Tidning",
    "title": "Kultur & nöje"
  },
  {
    "channel": "67b7c678-4b80-43ce-a931-2a65e32c6276",
    "uuid": "C78B27E2-6089-4070-991F-DD9DE9AADC84",
    "channelTitle": "Borlänge Tidning",
    "title": "Näringsliv"
  },
  {
    "channel": "67b7c678-4b80-43ce-a931-2a65e32c6276",
    "uuid": "7D1E4932-AD35-4AC6-A5FA-27EC2F0516BA",
    "channelTitle": "Borlänge Tidning",
    "title": "Opinion"
  },
  {
    "channel": "67b7c678-4b80-43ce-a931-2a65e32c6276",
    "uuid": "B0CEA67B-D923-4055-A6B6-C7E6EBF79B01",
    "channelTitle": "Borlänge Tidning",
    "title": "Trafik"
  },
  {
    "channel": "67b7c678-4b80-43ce-a931-2a65e32c6276",
    "uuid": "9F432331-E93E-487C-A208-4C37E1E1CE15",
    "channelTitle": "Borlänge Tidning",
    "title": "Sport"
  },
  {
    "channel": "67b7c678-4b80-43ce-a931-2a65e32c6276",
    "uuid": "62C71F13-C350-4A1A-B3E6-B68FB45BD142",
    "channelTitle": "Borlänge Tidning",
    "title": "IK Brage"
  },
  {
    "channel": "67b7c678-4b80-43ce-a931-2a65e32c6276",
    "uuid": "9847FF3B-0AFE-4982-9501-826B4EFDAE58",
    "channelTitle": "Borlänge Tidning",
    "title": "Kvarnsvedens IK"
  },
  {
    "channel": "67b7c678-4b80-43ce-a931-2a65e32c6276",
    "uuid": "432AA506-63EE-413B-AF17-0651AF3BDFC4",
    "channelTitle": "Borlänge Tidning",
    "title": "Borlänge kommun"
  },
  {
    "channel": "67b7c678-4b80-43ce-a931-2a65e32c6276",
    "uuid": "C9B00BAD-4D46-4615-9394-1D27404F2379",
    "channelTitle": "Borlänge Tidning",
    "title": "Leksands IF Herr"
  },
  {
    "channel": "67b7c678-4b80-43ce-a931-2a65e32c6276",
    "uuid": "6310B414-6622-473A-B101-9F274C56DB93",
    "channelTitle": "Borlänge Tidning",
    "title": "Väder"
  },
  {
    "channel": "67b7c678-4b80-43ce-a931-2a65e32c6276",
    "uuid": "C8F7E245-3140-4EFE-AB3A-EA8109853FD9",
    "channelTitle": "Borlänge Tidning",
    "title": "Krim"
  },
  {
    "channel": "67b7c678-4b80-43ce-a931-2a65e32c6276",
    "uuid": "ADDBCEBE-6E05-4463-9F6E-59B63C54FD9E",
    "channelTitle": "Borlänge Tidning",
    "title": "Blåljus"
  }
]
