const St = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>ST</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#2981C1" />
      <path
        d="M9.894 6.638c1.74-.067 2.277.127 3.037.7.217-.275.457-.51.457-.51h1.18v5.015l-2.197.005v-1.277c0-1.108-.715-1.31-1.265-1.31-.697 0-1.119.177-1.123 1.273.008 3.473 5.006 5.435 5.006 9.927v1.027c0 3.526-1.946 3.872-2.923 3.886-1.867.02-2.16-.27-2.921-.844a9.205 9.205 0 0 1-.458.536l-1.563.004v-5.744l2.456-.006-.002 2.016c0 1.108.702 1.31 1.25 1.31.692 0 1.207-.316 1.223-1.38.02-3.647-4.935-5.08-4.95-9.918v-.888c0-3.525 1.817-3.784 2.793-3.822Zm15.02.191-.006 5.011h-1.793a228.053 228.053 0 0 1 0-2.518h-1.58l-.006 13.122c.362 0 .5-.006.855 0v2.57H17.82v-2.57h.836l.004-13.113-1.609-.001.006 1.288v1.219H16.028l-.138.001h-.247l-.106.001h-.165l-.056.001h-.047l.002-5.011h9.644Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default St;
