const Ba = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>BA</title>
    <g fill="none">
      <path fill="#0A3752" d="M32 0H0v32h32V0Z"/>
      <path fill="#fff" d="M15.59 9.4c-.43-2.06-1.77-3.15-3.84-3.15-2.2 0-3.31 1.35-3.31 2.72l.23.01c0-1.15.85-2.08 1.64-2.08.67 0 1.12.38 1.12 1.18v16.1c0 .18 0 .4-.03.58-.1.47-.35.84-.97.84-.5 0-1.04-.44-1.04-.94 0-.48.14-.81.62-1.02.43-.2.79-.55.79-1.25a1.2 1.2 0 0 0-1.16-1.22c-.88 0-1.32.73-1.32 1.68 0 1.77.92 3.07 2.96 3.07 2.33 0 3.96-1.33 4.3-3.59.1-.53.15-1.2.15-1.86v-4.01l.92-.57a2.1 2.1 0 0 1 1.05-.33c.9 0 1.42.56 1.42 1.62v5.7c0 .7 0 1.3-.06 1.54-.12.44-.5.64-.88.64-.83 0-1.63-1.02-1.63-2.9 0-1.29.48-2.31 1.46-3.1l-.14-.19a4 4 0 0 0-1.57 3.3c0 2.3 1.21 3.74 3.15 3.74 2.23 0 4.23-1.56 4.23-5.47 0-4.37-2.63-5.77-5.11-5.49l-.03-.07c1.94-.94 4.54-2.31 4.54-4.88 0-2.06-.86-3.13-2.3-3.92h-.14l-5.06 3.33Zm.04.26.86-.57c1.53-.9 2.75 1.68 2.75 2.84 0 1.23 0 2.1-1.57 3.07l-1.94 1.19v-5.24a7.87 7.87 0 0 0-.1-1.3Z"/>
    </g>
  </svg>
);

export default Ba;
