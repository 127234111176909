const Sa = (props) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    {...props}
  >
    <title>SA</title>
    <g clipPath="url(#a)">
      <path d="M32 0H0v32h32V0Z" fill="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.576 12.978c.043.469-.724.299-.682.852a157.81 157.81 0 0 0 2.898 11.764c.043.298.341.554.64.639.128 0 .213.085.34.043v.255h-4.219c-.128-.383.426-.128.597-.383.41-.328.33-.777.208-1.215l-.012-.04a9.734 9.734 0 0 0-.023-.082l-.012-.041a2.753 2.753 0 0 1-.119-.54l-.51-1.833h-3.027l-.81 3.325c0 .51.64.51.725.81h-3.623c-.128-.47.469-.17.64-.384.57-.429.797-1.03.988-1.65l.02-.065.078-.262.02-.065a7.87 7.87 0 0 1 .172-.516l2.557-9.76c-.085-.255-.298-.553-.596-.597h-.171v-.255h3.921Zm-2.344 3.495-1.193 4.432h2.258l-1.065-4.432Zm-4.78-9.996c.451.129.537-.04.629-.259l.008-.02.018-.043.009-.02c.015-.036.032-.072.052-.108l.08-.318.18.01-.022 4.15c-.106.026-.127.084-.212 0-.143-1.75-.954-2.2-2.252-2.65-.715-.133-2.04-.239-2.596.557-.45.53-.424 1.298.159 1.775l1.96 1.404c1.177.777 2.772 1.616 3.26 4.048.084 1.399-.133 2.708-1.193 3.555-1.086.98-2.252 1.272-3.604 1.06-1.668-.37-3.046-2.12-3.337-.503-.08.291-.053.397-.106.582l-.239.011v-4.965c.042-.085.154-.111.239-.026.232 1.68.82 2.252 2.33 2.967.849.318 2.105.164 2.783-.344.53-.477.795-.896.795-1.617-.027-.768-.398-1.218-1.103-1.78-1.737-1.738-5.425-2.925-4.45-6.061.339-1.145 1.526-2.162 2.713-2.29 1.833-.333 2.416.302 3.9.885Z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default Sa;
