[
  {
    "channel": "86ffbff5-7296-485f-95cf-256ce05c15a5",
    "uuid": "AF7409A4-5539-4DAC-B37A-2C93A6F9DC69",
    "channelTitle": "Sydöstran",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "86ffbff5-7296-485f-95cf-256ce05c15a5",
    "uuid": "7EE5380E-0065-4001-836A-063B8FE50725",
    "channelTitle": "Sydöstran",
    "title": "Sport"
  }
]
