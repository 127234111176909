const Dd = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>DD</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#D92B2B" />
      <path
        d="M22.622 11.072s2.397 0 2.65 2.23l-2.314 11.442s-.003.024-.014.066l-.021.077c-.129.402-.695 1.513-3.035 1.582l-6.94-.01.253-1.21h1.135l2.944-12.915h-1.093l.252-1.262Zm-1.583 1.219c-.247 0-.436.043-.436.043L17.827 25.25c.075.003.146.004.215.004 1.72 0 1.72-.972 1.72-.972l2.397-10.853c.055-.972-.641-1.137-1.12-1.137ZM16.42 5.53c.272.01 2.385.144 2.621 2.23l-.619 3.061h-2.188l-.352 1.762h1.085l-1.85 8.118a5.311 5.311 0 0 1-1.459.226l-6.94-.01.253-1.21h1.135L11.05 6.793H9.957l.252-1.262Zm-.491 2.356c.084-1.472-1.556-1.094-1.556-1.094l-2.776 12.915c1.935.084 1.935-.968 1.935-.968Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Dd;
