const Np = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>NP</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#018DA6" />
      <path
        d="M22.166 4.825c3.325-1.177 4.896.94 4.896.94s-1.18-.626-3.725.006c-3.163.786-3.963 3.014-3.963 3.014s-3.423-.87-5.7.134c-.997.44-1.66.97-2.095 1.434l.093-.096c.68 2.87 2.597 10.95 2.642 11.089.031.093.152.13.249.13l.054-.003V9.926c-.016-.181-.303-.33-.53-.427-.208-.09-.17-.21-.158-.235l.002-.005h1.747s.078.138-.157.24c-.206.088-.455.22-.514.378l-.013.07-.002 14.146h-3.557S9.36 15.495 8.65 12.613l-.084-.337a18.584 18.584 0 0 0-.15-.59c-.032-.082-.105-.272-.234-.325l-.07-.014v12.114c.008.185.262.336.492.435.206.089.172.17.16.19l-.003.004H7.075s-.078-.19.157-.292c.206-.088.4-.144.446-.284l.01-.066V9.958c-.02-.174-.231-.317-.451-.412-.235-.1-.157-.291-.157-.291l1.151.004h3.204l.11.459a5.299 5.299 0 0 1 2.715-2.166c2.742-.998 4.707.78 5.016 1.084l.038.038c.004.005 0-.036-.002-.11v-.103c.008-.671.254-2.715 2.854-3.636Zm.27 4.43v.001c1.529 0 2.765 1.713 2.765 3.827 0 2.044-1.155 3.714-2.61 3.823l-.151.005h-.004v.001H20.19v6.482c.002.189.172.342.406.447.178.08.165.206.158.236l-.001.006h-4.55s-.043-.154.157-.243c.2-.09.353-.214.393-.369l.01-.079V9.968c-.001-.19-.17-.344-.402-.446-.178-.077-.165-.223-.16-.258l.003-.008h1.154l-.001.003h1.746v-.004h3.333Zm-1.337.353h-.91v6.875h.91c.134 0 .246-.137.272-.318l.005-.08v-6.078c0-.22-.124-.399-.277-.399Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Np;
