const Nsk = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>NSK</title>
    <g fill="none">
      <path fill="#EF7D00" d="M32 0H0v32h32V0Z"/>
      <path fill="#fff" d="M17.14 10.46c-.23.13-.46.38-.46.6v.79s.83-.58 1.9-.56c1.09.03 1.1.42 1.1.42l.02-.86c0-.28-.13-.58-.67-.55 0 0-.16.4-.53.32-.5-.1-.97-.38-1.36-.16ZM12.98 20.7c.33-.07.58-.43.55-.8-.04-.38-.33-.64-.66-.57-.33.07-.59.43-.55.8.02.38.32.63.66.57Zm2.96-10.98h1.82l.18-.84-1.1-.05c-.57.26-.9.9-.9.9Z"/>
      <path fill="#fff" d="M13.31 8.95c-.32-.36-.55-.78-.52-1.18.05-.51.23-.88.58-1.1.51-.36 1.29-.32 1.65-.2.48.13 1.16.58 1.8 1a7.81 7.81 0 0 0 2.4 1.25l.03.2-.67-.03c-.1-.01-.17.12-.12.2l.37.64h.3c.06-.15.15-.3.23-.43.9-.13 1.65-.44 1.69-.46a.47.47 0 0 0 .3-.53.46.46 0 0 0-.55-.37c-.33.07-.63.07-1.06-.05a3.8 3.8 0 0 0-.22-1.08 2.26 2.26 0 0 0-2.01-1.36c-.24-.02-.5 0-.75.04l.46.59h.18c.78.1 1.19.58 1.3.98.05.15.1.35.13.53l-.45-.25c-.33-.18-.68-.42-1.04-.65a9.1 9.1 0 0 0-2.05-1.12 3.13 3.13 0 0 0-2.44.32c-.4.26-.9.8-.98 1.8-.07.67.23 1.35.87 2.02l.57-.76Zm4.5 5.1c1.49-.1 1.92-.73 1.92-.73l-.57-1.65-.26 1.03c-.3.1-.41.14-.88.16-.67.05-1.33-.12-1.68-.56a2.3 2.3 0 0 1-.3-.62c-.25.43-.52.95-.67 1.28.17.28.9 1.2 2.44 1.1Z"/>
      <path fill="#fff" d="m15.96 8.05.05-.01-.03-.01a2.11 2.11 0 0 0-.72-.15.94.94 0 0 0-.56.17c-.32.23-.54.66-.8 1.13a15.6 15.6 0 0 1-.7 1.3s1.02.77 1.82.86v-.81c-.4-.16-.64-.37-.64-.37.24-.62.87-2 1.58-2.11Zm8.5-.4c-.37-.2-.78.01-.87.18-.02 0-.67 1.25-1.62 3l-.61 1.07c-.29-.02-.54 0-.72.12-.15.13-.5.88-.5.88s.14-.08.91-.14c.77-.05 1.28.49 1.28.49s.1.27.1.85v1.58c.56-.08.77-1.14.84-1.5.16-.89.13-1.48.13-1.48s-.38-.39-.95-.6l.42-.7a56.53 56.53 0 0 1 1.87-2.9.61.61 0 0 0-.27-.86Z"/>
      <path fill="#fff" d="M24.4 16.18c.07-.13.14-.3.18-.49l-1.05-.28a1.2 1.2 0 0 1-.04.22c-.1.28-.31.52-.8.68a1.6 1.6 0 0 1-1.2-.03 1.2 1.2 0 0 1-.4-.39l-.48.87c.1.1.65.6 1.46.6.4 0 .83-.09.94-.12l.03-.01.5 2.24c-.1.18-.35.52-.91.75-.25.1-.55.12-.88.07l-1.1-1.42-.58 3.62.76 2.99h-1.5c.23-.49.21-1.08-.08-1.59-.13-.2-.33-.39-.54-.57-.36-.31-.7-.62-.7-1.04l.01-.24.01-.1 1.58-.83.25-2.43-.75-.8 1.66-2.91a.4.4 0 0 0 .04-.33.53.53 0 0 0-.95-.1S18 17.72 17.6 18.39l-.18.32c0 .05 0 .1.03.14a.53.53 0 0 0 .33.32c.19.08.42.1.62 0l.27-.5c.19.2.32.42.32.42l-.23 1.52-1.34.66-1.95-.12a1.69 1.69 0 0 1-.39-1.3c.07-.43.3-.8.63-1.03.23-.16.56-.22.87-.2l.15-.92c-.22 0-.77.05-1.24.27a7.1 7.1 0 0 0-.84-.18 6.09 6.09 0 0 0-.17-.68 4.55 4.55 0 0 0-2.68-2.83 7.5 7.5 0 0 0-.86-.3c.12-.25 1.35-.96 2.45-1.33a7.01 7.01 0 0 0 4.05 3.76l.54.14.22-.34c.13-.25.39-.74.6-1.22.04-.07.15-.36.24-.59a4.16 4.16 0 0 1-.9.28c-.16.35-.33.75-.5.94a6.1 6.1 0 0 1-2.24-1.44 5.82 5.82 0 0 1-1.28-2l-.05-.16-.08-.35c-1.11.27-1.85.5-2.52.84a8.31 8.31 0 0 0-1.97 1.4l-.43.43.71.85-.14.23a16.4 16.4 0 0 0-1.2 2.5c-.7 1.87-.98 3.6-1 3.67l-.07.47.47.09c.08 0 1.77.32 3.02.33l-1.42 3.99 12.68-.04-1.08-4.23.1-1.09.01.02.19.05c.26.06.5.1.75.1.32 0 .63-.06.9-.17a2.56 2.56 0 0 0 1.54-1.47l.05-.13-.74-2.71a1.85 1.85 0 0 0 .56-.61ZM8.47 21.27c.13-.63.4-1.8.85-3a16.63 16.63 0 0 1 1.64-3.18.23.23 0 0 0 .05-.07c.92.32 2.09 1 2.5 2.37l.08.24c-.6-.06-1.02-.07-1.02-.07l-.9.71c1.28.05 2.44.25 2.98.4a2.38 2.38 0 0 0-.4 1.03c-.07.43-.01.9.14 1.36-.36.13-1.44.44-3.51.44-.79 0-1.81-.14-2.4-.23Zm5.98 1.86c-.07-.13.04-.37.04-.37l-.38-.15s-.12.08-.2.21c-.16.25-.26.67.2 1.02.17.12.36.17.54.2a5.58 5.58 0 0 0-.24 1.43h-3.6l1.08-3.02a9.13 9.13 0 0 0 3-.54l.89.02-.77 1.28c-.26.1-.47.05-.56-.08Zm2.6-.73c-.06.44-.15.92-.17 1.14a6.74 6.74 0 0 0 .06 1.94h-1.63c0-.15 0-.74.22-1.53.13-.43.53-1.25.93-2l.64.01a5 5 0 0 0-.06.44Zm1.43 3.02-.06.06h-.46a4.3 4.3 0 0 1-.11-1.82c0-.05 0-.11.02-.16l.35.36c.17.14.33.28.4.4.2.35.2.87-.14 1.16Z"/>
    </g>
  </svg>
);

export default Nsk;
