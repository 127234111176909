const Lt = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>LT</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#0071A5" />
      <path
        d="M24.906 10.563c-.25 1.407-.52 2.793-.804 4.166h-1.138c.284-1.487.04-3.187-1.443-3.25a561.378 561.378 0 0 0-1.442 7.388c-.103.55-.35 1.301.138 1.556.234.121.613.071.888.083l-.116.606a8.08 8.08 0 0 0-.05.31H15.86c.06-.3.129-.593.167-.916.404.015.915.016 1.165-.167.425-.31.489-1.464.61-2.083.44-2.23.9-4.667 1.304-6.777-1.784.214-2.373 1.624-2.746 3.25h-1.166c.296-1.37.526-2.807.833-4.166h8.878Zm-10.682 0c-.066.294-.121.6-.166.916-.572-.027-1.103.006-1.36.417-.083.134-.13.34-.167.561l-.054.334-.028.16c-.482 2.431-.993 5.185-1.443 7.527 2.156.14 3.227-1.046 3.634-2.75h1.138c-.232 1.24-.482 2.462-.721 3.695H7.094c.053-.308.124-.598.166-.917 1.282.234 1.519-.658 1.678-1.644l.04-.258.02-.13.043-.258.023-.126c.305-1.602.664-3.373.97-5 .118-.62.352-1.38-.249-1.583-.2-.068-.621-.007-.777-.028.057-.267.094-.554.139-.833-.003-.04-.005-.079.028-.083Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Lt;
