const Ob = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>OB</title>
    <g fill="none">
      <path fill="#005CA9" d="M32 0H0v32h32V0Z"/>
      <path fill="#fff" d="M10.05 9.3H8V7.41h2.05V9.3Zm3.17 0h-2.05V7.41h2.05V9.3Zm-2.62 1.02c1.55 0 5.61.67 5.61 6.15s-4.06 6.14-5.6 6.14c-1.55 0-5.62-.66-5.62-6.14 0-5.48 4.07-6.15 5.61-6.15Zm0 10.23c1.32 0 3.17-.82 3.17-4.08 0-3.27-1.85-4.09-3.17-4.09-1.31 0-3.17.82-3.17 4.09 0 3.26 1.86 4.08 3.17 4.08Zm6.76-9.92h5.67c2.87 0 3.6 1.79 3.6 3 0 1.67-.94 2.14-1.43 2.38 1.43.54 1.8 1.68 1.8 2.83 0 .93-.39 1.8-1 2.4-.67.66-1.3 1.07-3.7 1.07h-4.94V10.63Zm2.34 9.66h3c1.18 0 1.86-.52 1.86-1.71 0-1.02-.87-1.41-1.75-1.41h-3.1v3.12Zm0-5.1h2.86c.96 0 1.73-.36 1.73-1.3 0-.95-.69-1.25-1.8-1.25H19.7v2.56Z"/>
    </g>
  </svg>
);

export default Ob;
