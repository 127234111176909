const Smt = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>SMT</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#0280C9" />
      <path
        d="M6.665 10.125c.8 0 1.487.174 1.981.732v-.46h1.49v2.862h-1.49c0-.828-.244-1.843-1.47-1.843-1.075 0-1.339.665-1.339 1.167 0 .894.904 1.558 2.13 2.348l.264.167c1.153.716 2.389 1.405 2.389 3.524 0 2.091-1.482 3.258-3.163 3.258-1.078 0-1.66-.457-1.95-.81v.57h-1.49v-3.363h1.49v.616c0 1.26 1.107 1.589 1.55 1.589.493 0 1.432-.323 1.526-1.394.11-1.233-1.144-1.888-2.007-2.461-.862-.575-2.763-1.558-2.763-3.736 0-2.294 2.148-2.766 2.852-2.766Zm12.392 2.95c.953 0 2.01.432 2.01 2.582l-.01 4.497c0 .276.22.5.49.5v.995h-2.982v-.997c.27 0 .49-.224.49-.5l-.001-4.723c0-.731-.18-1.092-.686-1.092-.682 0-.996.506-1.143.731l.005 5.086c0 .276.22.5.49.5v.995h-2.983v-.997c.271 0 .49-.224.49-.5V15.43c0-.731-.18-1.092-.686-1.092-.677 0-.974.499-1.123.726l-.002 5.09c0 .277.22.5.49.5v.996h-2.982v-.997c.27 0 .491-.224.491-.5l.001-5.4c0-.275-.22-.499-.49-.499l-.002-.997h2.494v.633c.545-.526 1.056-.815 1.812-.815.67 0 1.39.215 1.759 1.088.6-.7 1.19-1.088 2.068-1.088Zm9.13-2.683v2.864h-1.112c.02-1.218.056-1.918-1.54-1.817l.003 8.625c0 .32.207.578.52.578l.14.008v.999h-3.543v-1l.14-.007c.313 0 .521-.259.521-.578l.002-8.625c-1.359-.086-1.535.409-1.545 1.31v.245l.004.262h-1.111v-2.864h7.521Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Smt;
