const Lj = (props) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>LJU</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" fill="#006A91" />
      <path
        d="m16.578 21.61 1.347-2.672.49-1.006c.098-.343.098-.687-.171-1.005-.417-.515-1.274.245-.735.907.122.196 0 .54-.172.858-.049.075-.073.172-.122.246-.123.195-.27.367-.539.367-.294 0-3.503-.073-3.993-.122-.196 0-.392-.025-.465-.245 0 0 0-.025-.025-.025V7.83c.22-.49.588-.417 1.225-.516.368-.049.27-.563 0-.563-1.739.122-3.282.098-4.948 0-.318 0-.367.466-.049.563.441.123.98.025 1.127.467v11.157c-.196.588-.613.515-.98.613-.514.171-.539.785.22.785h7.227c.612 0 .098.808 0 1.2-.074.295.367.369.563.075ZM18.49 6.752c-.417 0-.368.49-.05.563.613.148 1.03.074 1.201.442V22.64c0 .196-.073.49-.22.736-.196.343-.564.637-1.103.883-.832.293-1.861.441-2.572-.197-.367-.417.318-.613.368-1.004.073-.442-.172-.761-.54-.761-.49 0-.783.54-.71 1.177.05.662.49 1.055 1.03 1.373 1.248.638 2.816.54 4.04-.073a4.407 4.407 0 0 0 2.353-3.973V7.78c.244-.442.783-.295 1.298-.467.416-.146.196-.588-.025-.563-1.74.122-3.331.147-5.07 0Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default Lj;
