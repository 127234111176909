[
  {
    "channel": "32f2acc2-a70f-47c6-87ab-7bcb780e1ea8",
    "uuid": "4E3D7AA3-D4D0-4ADC-8044-231766828AF4",
    "channelTitle": "Skövde Nyheter",
    "title": "Stora nyhetshändelser"
  },
  {
    "channel": "32f2acc2-a70f-47c6-87ab-7bcb780e1ea8",
    "uuid": "502DC386-331E-47A0-BC84-0B3B635516B9",
    "channelTitle": "Skövde Nyheter",
    "title": "Kultur & nöje"
  },
  {
    "channel": "32f2acc2-a70f-47c6-87ab-7bcb780e1ea8",
    "uuid": "5A8F3588-BAD7-448F-BE77-0DCAF4DCA4AD",
    "channelTitle": "Skövde Nyheter",
    "title": "Näringsliv"
  },
  {
    "channel": "32f2acc2-a70f-47c6-87ab-7bcb780e1ea8",
    "uuid": "48069668-6C82-42B6-BBDF-AB5FF750DC6A",
    "channelTitle": "Skövde Nyheter",
    "title": "Opinion"
  },
  {
    "channel": "32f2acc2-a70f-47c6-87ab-7bcb780e1ea8",
    "uuid": "243D3664-ED2B-40DA-806E-9C8D094DAAE2",
    "channelTitle": "Skövde Nyheter",
    "title": "Sport"
  },
  {
    "channel": "32f2acc2-a70f-47c6-87ab-7bcb780e1ea8",
    "uuid": "baad7ba1-6897-4b39-8ca0-3eb827c99654",
    "channelTitle": "Skövde Nyheter",
    "title": "Skövde AIK"
  },
  {
    "channel": "32f2acc2-a70f-47c6-87ab-7bcb780e1ea8",
    "uuid": "9de373f4-44fe-4a63-b1a3-aa9483f3d417",
    "channelTitle": "Skövde Nyheter",
    "title": "IFK Skövde FK"
  },
  {
    "channel": "32f2acc2-a70f-47c6-87ab-7bcb780e1ea8",
    "uuid": "8e927340-1e26-4bf9-bdf1-1f49d1c48311",
    "channelTitle": "Skövde Nyheter",
    "title": "IFK Skövde HK"
  },
  {
    "channel": "32f2acc2-a70f-47c6-87ab-7bcb780e1ea8",
    "uuid": "3dc6c387-1d8c-4822-a299-549f8945367d",
    "channelTitle": "Skövde Nyheter",
    "title": "Skövde HF"
  },
  {
    "channel": "32f2acc2-a70f-47c6-87ab-7bcb780e1ea8",
    "uuid": "8be0b1f1-8363-47e1-bdde-b0df15de69ba",
    "channelTitle": "Skövde Nyheter",
    "title": "Skövde HC"
  },
  {
    "channel": "32f2acc2-a70f-47c6-87ab-7bcb780e1ea8",
    "uuid": "116252CD-8A94-4087-AAB6-293BC0107647",
    "channelTitle": "Skövde Nyheter",
    "title": "Trafik"
  },
  {
    "channel": "32f2acc2-a70f-47c6-87ab-7bcb780e1ea8",
    "uuid": "311EBF93-1A74-496D-89CC-800D0D99DCF1",
    "channelTitle": "Skövde Nyheter",
    "title": "Skövde kommun"
  }
]
